import PropTypes from 'prop-types';
import { createRef } from 'react';
import { BsX } from 'react-icons/bs';

import CartItem from 'Component/CartItem';
import CmsBlock from 'Component/CmsBlock';
import { CART_OVERLAY } from 'Component/Header/Header.config';
import Link from 'Component/Link';
import Overlay from 'Component/Overlay';
import { OVERLAY_PLACEHOLDER } from 'Component/PopupSuspense/PopupSuspense.config';
import { CartOverlay as SourceCartOverlay } from 'SourceComponent/CartOverlay/CartOverlay.component';
import Loader from 'SourceComponent/Loader';

/** @namespace ZnetPwa/Component/CartOverlay/Component/CartOverlayComponent */
export class CartOverlayComponent extends SourceCartOverlay {
    static propTypes = {
        ...this.propTypes,
        toggleIsCartItemLoading: PropTypes.func.isRequired,
        isCartItemLoading: PropTypes.bool.isRequired
    };

    cartItemRef = createRef();

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { showOverlay, activeOverlay } = this.props;

        if (activeOverlay === OVERLAY_PLACEHOLDER) {
            showOverlay(CART_OVERLAY);
        }
    }

    renderPromo() {
        const { minicart_content: { minicart_cms } = {} } = window.contentConfiguration;

        if (minicart_cms) {
            return <CmsBlock identifier={ minicart_cms } />;
        }

        return null;
    }

    renderCartTitleAndClose() {
        const { hidePopUp } = this.props;
        return (
            <div
              block="CartOverlay"
              elem="TitleAndCloseWrapper"
            >
                <span
                  aria-label="CartOverlay"
                  block="CartOverlay"
                  elem="Title"
                >
                    { __('Shopping Cart') }
                </span>
                <BsX
                  block="CartOverlay"
                  elem="CloseBtn"
                  size="26px"
                  aria-label={ __('Close') }
                  onClick={ hidePopUp }
                />
            </div>
        );
    }

    renderNoCartItems() {
        return (
            <div
              block="CartOverlay"
              elem="EmptyWrapper"
            >
                <p block="CartOverlay" elem="Empty">
                    { __('You have no items in your shopping cart.') }
                </p>
            </div>
        );
    }

    renderSecureCheckoutButton() {
        const { handleCheckoutClick, hasOutOfStockProductsInCart } = this.props;

        const options = hasOutOfStockProductsInCart
            ? {
                onClick: (e) => e.preventDefault(),
                disabled: true
            }
            : {};

        return (
            <button
              block="CartOverlay"
              elem="CheckoutButton"
              mix={ { block: 'Button' } }
              onClick={ handleCheckoutClick }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...options }
            >
                { __('Proceed to checkout') }
            </button>
        );
    }

    renderActions() {
        const { hidePopUp } = this.props;

        return (
            <div block="CartOverlay" elem="Actions">
                <Link
                  block="CartOverlay"
                  elem="CartButton"
                  to="/cart"
                  onClick={ hidePopUp }
                >
                    { __('View Cart') }
                </Link>
                { this.renderSecureCheckoutButton() }
            </div>
        );
    }

    renderSubTotals() {
        const { totals: { subtotal = 0 } } = this.props;

        return (
            <div
              block="CartOverlay"
              elem="SubTotalWrapper"
            >
                <p>{ __('Total: ') }</p>
                <div
                  block="CartOverlay"
                  elem="SubTotal"
                >
                    { this.renderPriceLine(subtotal) }
                </div>
            </div>
        );
    }

    renderCartAdditional() {
        const { totals: { items } } = this.props;

        if (!items || items.length < 1) {
            return null;
        }

        return (
            <div block="CartOverlay" elem="Additional">
                { this.renderSubTotals() }
                { this.renderOutOfStockProductsWarning() }
                { this.renderActions() }
            </div>
        );
    }

    renderCartItems() {
        const {
            toggleIsCartItemLoading,
            totals: {
                items,
                quote_currency_code
            }
        } = this.props;

        if (!items || items.length < 1) {
            return this.renderNoCartItems();
        }

        return (
            <div block="CartOverlay" elem="Items" aria-label="List of items in cart" ref={ this.cartItemRef }>
                { items.map((item) => (
                    <CartItem
                      key={ item.item_id }
                      item={ item }
                      toggleIsCartItemLoading={ toggleIsCartItemLoading }
                      currency_code={ quote_currency_code }
                      isEditing
                    />
                )) }
            </div>
        );
    }

    render() {
        const {
            isCartItemLoading,
            changeHeaderState
        } = this.props;

        return (
            <Overlay
              id={ CART_OVERLAY }
              onVisible={ changeHeaderState }
              mix={ { block: 'CartOverlay' } }
            >
                <Loader isLoading={ isCartItemLoading } />
                { this.renderCartTitleAndClose() }
                { this.renderCartItems() }
                { this.renderCartAdditional() }
            </Overlay>
        );
    }
}

export default CartOverlayComponent;
