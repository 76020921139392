import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/** @namespace ZnetPwa/Component/FieldSelect/Container/FieldSelectContainer */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    handleSelectListOptionClick(option) {
        if (option) {
            const { value } = option;
            const { formRef, onChange } = this.props;

            if (typeof formRef !== 'function') {
                formRef.current.value = value;

                // TODO: investigate why this is required
                const event = new Event('change', { bubbles: true });
                formRef.current.dispatchEvent(event);
            } else {
                onChange(value);
            }
        }
    }

    sortSelectOptions() {
        const { selectOptions, noSortingOrder = false } = this.props;

        if (noSortingOrder) {
            return selectOptions;
        }

        /**
         * Trim all null label values, sort alphabetically
         */
        const sortedOptions = selectOptions.reduce(
            (acc, a) => (a.label ? [...acc, a] : acc), []
        ).sort((a, b) => {
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();
            // eslint-disable-next-line no-nested-ternary
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        return sortedOptions;
    }
}

export default FieldSelectContainer;
