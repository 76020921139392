import PropTypes from 'prop-types';

import Captcha from 'Component/Captcha';
import { CAPTCHA_REGISTER_ID } from 'Component/Captcha/Captcha.config';
import {
    MyAccountCreateAccount as SourceMyAccountCreateAccount
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';

import './MyAccountCreateAccount.override.style';

/** @namespace ZnetPwa/Component/MyAccountCreateAccount/Component/MyAccountCreateAccountComponent */
export class MyAccountCreateAccountComponent extends SourceMyAccountCreateAccount {
    static propTypes = {
        ...super.propTypes,
        isFormSubmittedUnsuccessfully: PropTypes.bool.isRequired,
        setToken: PropTypes.func.isRequired
    };

    renderSubmitButton() {
        const {
            isFormSubmittedUnsuccessfully,
            setToken
        } = this.props;

        return (
            <>
                <Captcha
                  isFormSubmittedUnsuccessfully={ isFormSubmittedUnsuccessfully }
                  captchaId={ CAPTCHA_REGISTER_ID }
                  setToken={ setToken }
                />
                <div block="MyAccountOverlay" elem="Buttons">
                    <button
                      block="Button"
                      mix={ { block: 'Button', elem: 'Secondary' } }
                      type="submit"
                    >
                        { __('Sign up') }
                    </button>
                </div>
            </>
        );
    }
}

export default MyAccountCreateAccountComponent;
