import PropTypes from 'prop-types';

import Captcha from 'Component/Captcha';
import { CAPTCHA_LOGIN_ID } from 'Component/Captcha/Captcha.config';
import Field from 'Component/Field';
import Form from 'Component/Form';
import {
    MyAccountSignIn as SourceMyAccountSignIn
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';

import './MyAccountSignIn.override.style';

/** @namespace ZnetPwa/Component/MyAccountSignIn/Component/MyAccountSignInComponent */
export class MyAccountSignInComponent extends SourceMyAccountSignIn {
    static propTypes = {
        ...super.propTypes,
        isFormSubmittedUnsuccessfully: PropTypes.bool.isRequired,
        setToken: PropTypes.func.isRequired,
        showError: PropTypes.bool.isRequired,
        hideError: PropTypes.bool.isRequired,
        handleSignIn: PropTypes.func.isRequired
    };

    renderSignInForm() {
        const {
            setToken,
            onSignInAttempt,
            onSignInSuccess,
            onFormError,
            isFormSubmittedUnsuccessfully,
            handleForgotPassword,
            emailValue,
            handleEmailInput,
            handleSignIn,
            showError,
            hideError,
            isCheckout
        } = this.props;

        return (
            <>
            <Form
              key="sign-in"
              onSubmit={ onSignInAttempt }
              onSubmitSuccess={ onSignInSuccess }
              onSubmitError={ onFormError }
            >
                <Field
                  type="text"
                  label={ __('Email') }
                  id="email"
                  name="email"
                  value={ emailValue }
                  autocomplete={ isCheckout ? 'off' : 'email' }
                  validation={ ['notEmpty', 'email'] }
                  onChange={ handleEmailInput }
                />
                <Field
                  type="password"
                  label={ __('Password') }
                  id="password"
                  name="password"
                  autocomplete="current-password"
                  validation={ ['notEmpty', 'password'] }
                  mix={ { block: 'MyAccountSignIn', elem: 'Password', mods: { showError, hideError } } }
                />
                <Captcha
                  isFormSubmittedUnsuccessfully={ isFormSubmittedUnsuccessfully }
                  setToken={ setToken }
                  captchaId={ CAPTCHA_LOGIN_ID }
                />
                <div
                  block="MyAccountOverlay"
                  elem="Buttons"
                  onClick={ handleSignIn }
                  onKeyDown={ handleSignIn }
                  role="button"
                  tabIndex={ -1 }
                >
                    <button block="Button">{ __('Sign in') }</button>
                </div>
            </Form>
            <button
              block="Button"
              mods={ { likeLink: true } }
              onClick={ handleForgotPassword }
            >
                { __('Forgot password?') }
            </button>
            </>
        );
    }
}

export default MyAccountSignInComponent;
