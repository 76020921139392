/* eslint-disable react/boolean-prop-naming */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { isCrawler } from 'SourceUtil/Browser';

/** @namespace DatadogSpwaCustomization/Route/Datadog/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isDatadogEnabled: state.ConfigReducer.datadog_enabled,
    applicationId: state.ConfigReducer.datadog_application_id,
    clientToken: state.ConfigReducer.datadog_client_token,
    site: state.ConfigReducer.datadog_site,
    service: state.ConfigReducer.datadog_service,
    env: state.ConfigReducer.datadog_env,
    version: state.ConfigReducer.datadog_version,
    sessionSampleRate: state.ConfigReducer.datadog_session_sample_rate,
    sessionReplaySampleRate: state.ConfigReducer.datadog_session_replay_sample_rate,
    trackUserInteractions: state.ConfigReducer.datadog_track_user_interactions,
    trackResources: state.ConfigReducer.datadog_track_resources,
    trackLongTasks: state.ConfigReducer.datadog_track_long_tasks,
    forwardErrorsToLogs: state.ConfigReducer.datadog_forward_errors_to_logs,
    defaultPrivacyLevel: state.ConfigReducer.datadog_default_privacy_level,
    allowedTracingUrls: state.ConfigReducer.datadog_allowed_tracing_urls,
    isLoading: state.ConfigReducer.isLoading
});

/** @namespace DatadogSpwaCustomization/Route/Datadog/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace DatadogSpwaCustomization/Route/Datadog/Container/DatadogContainer */
export class DatadogContainer extends PureComponent {
    static propTypes = {
        isDatadogEnabled: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        applicationId: PropTypes.string.isRequired,
        clientToken: PropTypes.string.isRequired,
        site: PropTypes.string.isRequired,
        service: PropTypes.string.isRequired,
        env: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired,
        sessionSampleRate: PropTypes.string.isRequired,
        sessionReplaySampleRate: PropTypes.string.isRequired,
        trackUserInteractions: PropTypes.bool.isRequired,
        trackResources: PropTypes.bool.isRequired,
        trackLongTasks: PropTypes.bool.isRequired,
        forwardErrorsToLogs: PropTypes.bool.isRequired,
        defaultPrivacyLevel: PropTypes.string.isRequired,
        allowedTracingUrls: PropTypes.string.isRequired
    };

    state = {
        isDatadogInjected: false
    };

    componentDidMount() {
        this.initDatadog();
    }

    componentDidUpdate(prevProps) {
        const {
            isDatadogEnabled,
            isLoading
        } = this.props;
        const {
            isDatadogEnabled: prevIsDatadogEnabled
        } = prevProps;
        const {
            isDatadogInjected
        } = this.state;

        if (!isLoading && (isDatadogEnabled !== prevIsDatadogEnabled) && isDatadogEnabled && !isDatadogInjected) {
            this.initDatadog();
        }
    }

    initDatadog() {
        const {
            isDatadogEnabled,
            applicationId,
            clientToken,
            site,
            service,
            env,
            version,
            sessionSampleRate,
            sessionReplaySampleRate,
            trackUserInteractions,
            trackResources,
            trackLongTasks,
            forwardErrorsToLogs,
            defaultPrivacyLevel,
            allowedTracingUrls = ''
        } = this.props;
        const {
            isDatadogInjected
        } = this.state;
        const conditionalSessionSampleRate = isCrawler() ? 0 : sessionSampleRate;
        const conditionalAllowedTracingUrls = allowedTracingUrls.length > 0 ? allowedTracingUrls.split(',') : [''];

        if (isDatadogEnabled && !isDatadogInjected && applicationId && clientToken) {
            datadogRum.init({
                applicationId,
                clientToken,
                site,
                service,
                env,
                // Specify a version number to identify the deployed version of your application in Datadog
                version,
                sessionSampleRate: parseInt(conditionalSessionSampleRate, 10),
                sessionReplaySampleRate: parseInt(sessionReplaySampleRate, 10),
                trackUserInteractions,
                trackResources,
                trackLongTasks,
                defaultPrivacyLevel,
                allowedTracingUrls: conditionalAllowedTracingUrls
            });

            datadogLogs.init({
                clientToken,
                site,
                forwardErrorsToLogs,
                sessionSampleRate: parseInt(conditionalSessionSampleRate, 10)
            });
            this.setState({ isDatadogInjected: true });
        }
    }

    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatadogContainer));
