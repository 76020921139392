import {
    CategoryPage,
    CmsPage,
    ProductPage,
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import './UrlRewrites.override.style';

export {
    ProductPage,
    CategoryPage,
    CmsPage
};

/** @namespace ZnetPwa/Route/UrlRewrites/Component/UrlRewritesComponent */
export class UrlRewritesComponent extends SourceUrlRewrites {
    renderDefaultPage() {
        return (
            <main block="DefaultPage" />
        );
    }
}

export default UrlRewritesComponent;
