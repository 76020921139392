/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_META } from 'SourceStore/Meta/Meta.action';
import { getInitialState, updateEveryTime } from 'SourceStore/Meta/Meta.reducer';

/** @namespace ZnetPwa/Store/Meta/Reducer/filterData */
export const filterData = (state, data, updateOtherEntries = true) => {
    if (updateOtherEntries) {
        const updated = updateEveryTime.reduce((acc, key) => {
            if ((data[key] !== '' && data[key] !== undefined) || state[key] === undefined) {
                acc[key] = data[key];
            } else {
                acc[key] = state[key];
            }

            return acc;
        }, {});

        return { ...data, ...updated };
    }

    return { ...data };
};

/** @namespace ZnetPwa/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (
    state = getInitialState(),
    action
) => {
    const { payload = {}, type, updateOtherEntries = true } = action;

    switch (type) {
    case UPDATE_META:
        const filteredData = filterData(state, payload, updateOtherEntries);

        return {
            ...state,
            ...filteredData
        };

    default:
        return state;
    }
};

export default MetaReducer;
