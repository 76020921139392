import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace GclidSpwa/Route/Gclid/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isGclidEnabled: state.ConfigReducer.znetSyncGclidServiceStatus,
    gclidLifetime: state.ConfigReducer.znetSyncGclidLifetime
});

/** @namespace GclidSpwa/Route/Gclid/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace GclidSpwa/Route/Gclid/Container/GclidContainer */
export class GclidContainer extends PureComponent {
    static propTypes = {
        isGclidEnabled: PropTypes.bool.isRequired,
        gclidLifetime: PropTypes.string.isRequired
    };

    componentDidMount() {
        const {
            isGclidEnabled
        } = this.props;

        if (isGclidEnabled) {
            this._checkForGclid();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            isGclidEnabled
        } = this.props;
        const {
            isGclidEnabled: prevIsGclidEnabled
        } = prevProps;

        if (isGclidEnabled !== prevIsGclidEnabled) {
            this._checkForGclid();
        }
    }

    _getParam(p) {
        const match = RegExp(`[?&]${ p }=([^&]*)`).exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

    _checkForGclid() {
        const {
            gclidLifetime
        } = this.props;
        const gclidParam = this._getParam('gclid');

        if (gclidParam) {
            BrowserDatabase.setItem(gclidParam, 'gclid', parseInt(gclidLifetime, 10));
        }
    }

    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GclidContainer));
