import {
    UPDATE_RECENTLY_VIEWED_PRODUCTS,
    updateRecentlyViewedProducts
} from 'SourceStore/RecentlyViewedProducts/RecentlyViewedProducts.action';

export {
    UPDATE_RECENTLY_VIEWED_PRODUCTS,
    updateRecentlyViewedProducts
};

export const ADD_RECENTLY_VIEWED_PRODUCT = 'ADD_RECENTLY_VIEWED_PRODUCT';

/**
 * Add RecentlyViewed product into list.
 * @param  {Object} product Product returned from fetch
 * @param  {String} store code
 * @return {void}
 * @namespace ZnetPwa/Store/RecentlyViewedProducts/Action/addRecentlyViewedProduct */
export const addRecentlyViewedProduct = (product, store) => ({
    type: ADD_RECENTLY_VIEWED_PRODUCT,
    product,
    store
});
