import PropTypes from 'prop-types';

import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

/** @namespace ZnetPwa/Component/Loader/Component/LoaderComponent */
export class LoaderComponent extends SourceLoader {
    static propTypes = {
        loaderText: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        loaderRefOriginal: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired
    };

    renderMain() {
        const { loaderRefOriginal } = this.props;

        return (
            <div block="Loader" elem="Main" ref={ loaderRefOriginal }>
                <span />
            </div>
        );
    }

    renderLoaderText() {
        const {
            loaderText
        } = this.props;

        if (loaderText && loaderText !== '') {
            return (
                <p block="Loader" elem="Text">
                    { __(loaderText) }
                </p>
            );
        }

        return null;
    }

    render() {
        const { isLoading } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader">
                <div block="Loader" elem="Scale">
                    { this.renderMain() }
                </div>
                { this.renderLoaderText() }
            </div>
        );
    }
}

export default LoaderComponent;
