import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

export const requestUrlRewrite = (args, callback, instance) => {
    const email = BrowserDatabase.getItem(CUSTOMER)?.email;

    if (email) {
        window._dcq?.push(['identify', {
            email,
            drip_unknown_status: true
        }]);
    }

    return (callback.apply(instance, args));
};

export default {
    'Route/UrlRewrites/Container': {
        'member-function': {
            requestUrlRewrite
        }
    }
};
