import { AdBlockDetectedWrapper } from 'adblock-detect-react';
import PropTypes from 'prop-types';

import { ADVANCED_QUOTE_FORM } from 'Component/AdvancedQuoteForm/AdvancedQuoteForm.config';
import { PRICE_FORM, QUOTE_FORM } from 'Component/DripForms/DripForms.config';
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import ProductPreviewAndQuantity from 'Component/ProductPreviewAndQuantity';
import NoMatch from 'Route/NoMatch';
import { CmsPage as SourceCmsPage } from 'SourceRoute/CmsPage/CmsPage.component';

import { HOMEPAGE_ID } from './CmsPage.config';

/** @namespace ZnetPwa/Route/CmsPage/Component/CmsPageComponent */
export class CmsPageComponent extends SourceCmsPage {
    static propTypes = {
        ...this.propTypes,
        formId: PropTypes.string.isRequired,
        isFormSubmitting: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        qtyInputRef: PropTypes.object,
        quantity: PropTypes.number,
        imageUrl: PropTypes.string,
        collection_color: PropTypes.string,
        collection_name: PropTypes.string,
        brandPlankWidth: PropTypes.string,
        coverage_area_type: PropTypes.string,
        minQuantity: PropTypes.number,
        maxQuantity: PropTypes.number,
        setQuantity: PropTypes.func,
        totalArea: PropTypes.string,
        productType: PropTypes.string,
        cmsPageHeightRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    };

    renderProductQtyReview() {
        const {
            formId,
            qtyInputRef,
            quantity,
            imageUrl,
            collection_color,
            collection_name,
            brandPlankWidth,
            coverage_area_type,
            minQuantity,
            maxQuantity,
            setQuantity,
            totalArea,
            productType
        } = this.props;

        if (formId !== QUOTE_FORM && formId !== ADVANCED_QUOTE_FORM && formId !== PRICE_FORM) {
            return null;
        }

        return (
            <ProductPreviewAndQuantity
              qtyInputRef={ qtyInputRef }
              quantity={ quantity }
              imageUrl={ imageUrl }
              collectionColor={ collection_color }
              collectionName={ collection_name }
              brandPlankWidth={ brandPlankWidth }
              coverageAreaType={ coverage_area_type }
              minQuantity={ minQuantity }
              maxQuantity={ maxQuantity }
              setQuantity={ setQuantity }
              totalArea={ totalArea }
              productType={ productType }
            />
        );
    }

    renderAdblockWarning() {
        const {
            formId
        } = this.props;

        if (formId === '') {
            return null;
        }

        return (
            <AdBlockDetectedWrapper>
                <div
                  block="CmsPage"
                  elem="AdblockWarning"
                >
                    { __(`To submit the form, please disable any ad blocking and content blocking
                    extensions and reload the page!`) }
                </div>
            </AdBlockDetectedWrapper>
        );
    }

    renderContent() {
        const {
            isLoading,
            pageIdentifiers = '',
            page: { content }
        } = this.props;
        const isHomePage = pageIdentifiers === HOMEPAGE_ID;

        if (isHomePage && isLoading) {
            return null;
        }

        if (isLoading) {
            return (
                <div block="CmsPage" elem="SectionPlaceholder" />
            );
        }

        if (!isLoading && !content) {
            return null;
        }

        return <Html content={ content } />;
    }

    render() {
        const {
            page,
            pageIdentifiers = '',
            isBreadcrumbsActive,
            isLoading,
            isFormSubmitting,
            isPageLoaded,
            cmsPageHeightRef
        } = this.props;
        const { page_width } = page;
        const isHomePage = pageIdentifiers === HOMEPAGE_ID;

        if (!isLoading && !isPageLoaded) {
            return <NoMatch />;
        }

        return (
            <main
              id="CmsPage"
              block="CmsPage"
              mods={ { isBreadcrumbsHidden: !isBreadcrumbsActive } }
              ref={ cmsPageHeightRef }
            >
                <div block="CmsPage" elem="Wrapper" mods={ { page_width } }>
                    <Loader isLoading={ isFormSubmitting } />
                    <div block="CmsPage" elem="Content" mods={ { isHomePage } }>
                        { this.renderProductQtyReview() }
                        { this.renderAdblockWarning() }
                        { this.renderContent() }
                    </div>
                </div>
            </main>
        );
    }
}

export default CmsPageComponent;
