import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

export class MyAccountDispatcherPlugin {
    signIn = async (args, callback) => {
        const email = args[0]?.email;
        if (email) {
            window?._dcq?.push(['identify', {
                email
            }]);
        }

        return callback(...args);
    };

    handleCustomerDataOnInit = (args, callback, instance) => {
        const email = BrowserDatabase.getItem(CUSTOMER)?.email;
        if (email) {
            window?._dcq?.push(['identify', {
                email
            }]);
        }

        callback.apply(instance, args);
    };
}

const { signIn, handleCustomerDataOnInit } = new MyAccountDispatcherPlugin();

export const config = {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn: [
                {
                    position: 100,
                    implementation: signIn
                }
            ],
            handleCustomerDataOnInit
        }
    }
};

export default config;
