/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

/** @namespace GoogleEnhancedConversion/Plugin/CheckoutPaymentsContainer/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [
        {
            CheckoutReducer: {
                email: customerEmail
            },
            ConfigReducer: {
                countries
            }
        }
    ] = args;

    return {
        ...callback(...args),
        customerEmail,
        countries
    };
};

/** @namespace GoogleEnhancedConversion/Plugin/CheckoutBillingContainer/componentDidMount */
export const componentDidMount = (args, callback, instance) => {
    callback.apply(instance, args);

    // Setting global JS variables for Enhanced Google Conversions - Code implementation
    // https://support.google.com/google-ads/answer/10172785?hl=en#zippy=%2Cidentify-and-define-your-enhanced-conversions-variables%2Cenable-enhanced-conversions-in-google-tag-manager-and-create-custom-javascript-variable%2Cmultiple-tags-are-detected

    const {
        countries,
        customerEmail,
        shippingAddress: {
            firstname,
            lastname,
            city,
            postcode,
            country_id,
            telephone
        } = {}
    } = instance.props;

    const country = countries.find(({ id }) => id === country_id)?.label;

    window.enhancedConversionCustomerEmail = customerEmail;
    window.enhancedConversionFirstname = firstname;
    window.enhancedConversionLastname = lastname;
    window.enhancedConversionCountry = country;
    window.enhancedConversionCity = city;
    window.enhancedConversionPostcode = postcode;
    window.enhancedConversionPhone = telephone;
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
