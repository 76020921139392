import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { isCrawler } from 'SourceUtil/Browser';

import { DRIP_HEADER_SCRIPT } from './Drip.config';

export const DripDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Drip/Drip.dispatcher'
);

/** @namespace Drip/Component/Drip/Container/mapStateToProps */

export const mapStateToProps = (state) => ({
    drip: state.ConfigReducer.drip
});

/** @namespace Drip/Component/Drip/Container/mapDispatchToProps */

export const mapDispatchToProps = (dispatch) => ({
    updateDrip: (status) => DripDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update(status, dispatch)
    )
});

/** @namespace Drip/Component/Drip/Container/DripContainer */

export class DripContainer extends PureComponent {
    static propTypes = {
        drip: PropTypes.shape({
            enabled: PropTypes.bool,
            drip_id: PropTypes.string
        }),
        updateDrip: PropTypes.func.isRequired
    };

    static defaultProps = {
        drip: {
            enabled: false,
            drip_id: ''
        }
    };

    componentDidMount() {
        this.initialize();
        setTimeout(() => {
            this.updateContactUsText();
        // eslint-disable-next-line no-magic-numbers
        }, 800);
    }

    componentDidUpdate() {
        this.initialize();
        setTimeout(() => {
            this.updateContactUsText();
        // eslint-disable-next-line no-magic-numbers
        }, 800);
    }

    updateContactUsText() {
        if (window.location.pathname === '/contact-us') {
            const labels = document.querySelectorAll('.dfwid-label.dfwid-label--radio');
            labels.forEach((label) => {
                if (label.textContent.includes('Opt in to receive text messages')) {
                    const updatedText = `Opt in to receive text messages to the number provided?
                    Standard message and data rates apply. By selecting Yes, you agree to our
                    <a href="/terms-of-use" target="_blank">Terms and Conditions</a> and
                    <a href="/privacy-policy" target="_blank">Privacy Policy</a>`;

                    // eslint-disable-next-line no-param-reassign
                    label.innerHTML = updatedText;
                }
            });
        }
    }

    /**
     * Initialize Drip
     */
    initialize() {
        const { drip: { enabled } } = this.props;
        if (isCrawler() === true) {
            return;
        }

        if (enabled) {
            this.injectDripScriptHeader();
        }
    }

    injectDripScriptHeader() {
        const { drip: { drip_id }, updateDrip } = this.props;
        const existingScripts = document.getElementById(DRIP_HEADER_SCRIPT);

        if (!existingScripts) {
            const script = document.createElement('script');

            script.id = DRIP_HEADER_SCRIPT;

            script.src = `//tag.getdrip.com/${ drip_id }.js`;

            script.async = true;

            script.onload = () => updateDrip({ dripLoaded: true });

            document.head.insertBefore(script, document.head.childNodes[0]);
        }
    }

    /**
     * Skip render
     *
     * @return {null}
     */

    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DripContainer));
