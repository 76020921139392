/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import Datadog from '../route/Datadog';

export const addDatadog = (originalMember) => [
    ...originalMember,
    {
        component: <Datadog />,
        position: 101
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addDatadog
        }
    }
};
