import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import HtmlComponent from './Html.component';

/** @namespace ZnetPwa/Component/Html/Container/HtmlContainer */
export class HtmlContainer extends PureComponent {
    static propTypes = {
        isBlog: PropTypes.bool,
        content: PropTypes.string.isRequired
    };

    static defaultProps = {
        isBlog: false
    };

    containerProps = () => {
        const { content, isBlog } = this.props;

        return {
            isBlog,
            content,
            fixImageSrc: this.fixImageSrc
        };
    };

    /**
     * If image for blog is uploaded locally, it has strange url passed in the object, making the image not found.
     * This functions fixes the issue
     * Ref: https://sepoy.atlassian.net/browse/ZFR-1033
     * @param {Object} attributes
     * @returns {Object}
     */
    fixImageSrc = (attributes) => {
        const {
            src: oldSrc,
            ...attribs
        } = attributes;
        let src = oldSrc;
        const regex = /"([^"]+)"/; // This regex captures content between double quotes

        if (oldSrc.includes('{{')) {
            const match = oldSrc.match(regex);
            if (match) {
                src = `/media/${ match[1] }`;
            }
        }

        return {
            src,
            ...attribs
        };
    };

    render() {
        return (
            <HtmlComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default HtmlContainer;
