import { FaSearch } from 'react-icons/fa';

import MobileMenu from 'Component/MobileMenu';
import { SearchField as SourceSearchField } from 'SourceComponent/SearchField/SearchField.component';

/** @namespace ZnetPwa/Component/SearchField/Component/SearchFieldComponent */
export class SearchFieldComponent extends SourceSearchField {
    renderActions(searchCriteria) {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'SearchField', elem: 'Button' } }
              aria-label={ __('Submit') }
              disabled={ !searchCriteria }
              onClick={ this.onSearchButtonClick }
            >
                <FaSearch size="20px" className="Icon" />
            </button>
        );
    }

    onSearchButtonClick = (e) => {
        // eslint-disable-next-line no-param-reassign
        e.key = 'Enter';
        this.onSearchEnterPress(e);
    };

    renderMenu() {
        const { device: { isMobile } } = this.props;

        if (!isMobile) {
            return null;
        }

        return <MobileMenu />;
    }

    render() {
        const {
            isVisible,
            isActive,
            searchCriteria,
            onSearchBarFocus
        } = this.props;

        return (
            <>
            { this.renderMenu() }
            <div block="SearchField">
                <div block="SearchField" elem="Fieldset">
                    <div block="SearchField" mods={ { isVisible, isActive } } elem="Fields">
                        <div block="Field">
                            <input
                              id="search-field"
                              ref={ this.searchBarRef }
                              block="SearchField"
                              elem="Input"
                              onFocus={ onSearchBarFocus }
                              onChange={ this.handleChange }
                              onKeyDown={ this.onSearchEnterPress }
                              value={ searchCriteria }
                              mods={ { isActive } }
                              autoComplete="off"
                              placeholder={ __('Know what you want, Enter it here') }
                            />
                        </div>
                    </div>
                    { this.renderActions(searchCriteria) }
                </div>
            </div>
            </>
        );
    }
}

export default SearchFieldComponent;
