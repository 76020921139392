import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

/** @namespace ZnetPwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    'og:title': state.MetaReducer['og:title'],
    'og:description': state.MetaReducer['og:description'],
    'og:image': state.MetaReducer['og:image'],
    'og:type': state.MetaReducer['og:type']
});

/** @namespace ZnetPwa/Component/Meta/Container/MetaContainer */
export class MetaContainer extends SourceMetaContainer {
    _getMetadata() {
        const {
            'og:title': og_title,
            'og:description': og_description,
            'og:image': og_image,
            'og:type': og_type
        } = this.props;

        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
            'og:title': og_title,
            'og:description': og_description,
            'og:image': og_image,
            'og:type': og_type
        };

        return this._generateMetaFromMetadata(meta);
    }
}

export default connect(mapStateToProps, sourceMapDispatchToProps)(MetaContainer);
