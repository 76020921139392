/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ACCESSORY_QUICK_VIEW_POPUP } from 'Component/AccessoryQuickViewPopUp/AccessoryQuickViewPopUp.config';
import Loader from 'Component/Loader';
import { ATTRIBUTE_SET_ACCESSORIES } from 'Component/ProductActions/ProductActions.config';
import { UPSELL } from 'Component/ProductCard/ProductCard.config';
import { CONFIGURABLE } from 'Component/ProductSwatchPopup/ProductSwatchPopup.config';
import { DISCONTINUED_PRODUCT_LABELS } from 'Component/RequestQuoteButtons/RequestQuoteButtons.config';
import ProductListQuery from 'Query/ProductList.query';
import ContentWrapper from 'SourceComponent/ContentWrapper';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer
} from 'SourceComponent/ProductLinks/ProductLinks.container';
import { isCrawler } from 'SourceUtil/Browser';
import { fetchQuery } from 'SourceUtil/Request';
import { CROSS_SELL, RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { showPopup } from 'Store/Popup/Popup.action';
import { DeviceType } from 'Type/Device';
import { ProductType } from 'Type/ProductList';

import ProductLinksComponent from './ProductLinks.component';
import {
    FOUR,
    NUMBER_OF_PRODUCTS_DESKTOP,
    NUMBER_OF_PRODUCTS_MOBILE,
    OUT_OF_STOCK,
    SCROLL_TIMEOUT_300,
    TOOLTIP
} from './ProductLinks.config';

/** @namespace ZnetPwa/Component/ProductLinks/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isLinkedProductsLoading: state.LinkedProductsReducer.loadingValue,
    device: state.ConfigReducer.device
});

/** @namespace ZnetPwa/Component/ProductLinks/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (id, payload = {}) => dispatch(showPopup(id, payload))
});

/** @namespace ZnetPwa/Component/ProductLinks/Container/ProductLinksContainer */
export class ProductLinksContainer extends SourceProductLinksContainer {
    static propTypes = {
        ...this.propTypes,
        requiredAccessories: PropTypes.arrayOf(PropTypes.string).isRequired,
        device: DeviceType.isRequired,
        currentProduct: ProductType,
        configurableVariantIndex: PropTypes.number,
        isLinkedProductsLoading: PropTypes.bool.isRequired
    };

    state = {
        ...this.state,
        isScroll: false,
        activeAccessory: 0,
        showMore: false,
        filterOptions: [],
        activeFilter: -1,
        accessoryFilterSelected: false,
        configurableVariantIndex: -1
    };

    static defaultProps = {
        numberOfProductsToDisplayDesktop: NUMBER_OF_PRODUCTS_DESKTOP,
        numberOfProductsToDisplayMobile: NUMBER_OF_PRODUCTS_MOBILE,
        currentProduct: {}
    };

    containerFunctions = {
        onQuickViewClick: this.onQuickViewClick.bind(this),
        onActiveAccessoryChange: this.onActiveAccessoryChange.bind(this),
        onShowMoreClick: this.onShowMoreClick.bind(this),
        setAccessoriesFilter: this.setAccessoriesFilter.bind(this),
        checkScroll: this.checkScroll.bind(this),
        filterProducts: this.filterProducts.bind(this)
    };

    componentDidMount() {
        fetchQuery(ProductListQuery.getAccessoryTypeOptions()).then(
            /** @namespace ZnetPwa/Component/ProductLinks/Container/fetchQuery/then */
            ({ customAttributeMetadata: { items } }) => {
                this.setState({ filterOptions: items[0].attribute_options });

                /** Used to make accessories tab available to google bots.
                 * Make all accessories visible without filter selected
                */
                if (isCrawler()) {
                    this.setState({ activeFilter: 0 });
                }
            },
            /** @namespace ZnetPwa/Component/ProductLinks/Container/fetchQuery/then */
            () => {
                throw new Error('Error fetching accessories filter options.');
            }
        );
    }

    componentDidUpdate(prevProps) {
        const {
            isCartPopup = false,
            configurableVariantIndex = -1,
            currentProduct: {
                dynamicAttributes: {
                    attribute_set_name = ''
                } = {}
            } = {}
        } = this.props;
        const {
            configurableVariantIndex: prevConfigurableVariantIndex
        } = prevProps;
        const { filterOptions, accessoryFilterSelected } = this.state;

        if (attribute_set_name === ATTRIBUTE_SET_ACCESSORIES) {
            if (filterOptions[0] && accessoryFilterSelected === false) {
                this.setAccessoriesFilter(filterOptions[0].value);
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    accessoryFilterSelected: true
                });
            }
        }

        // If it is configurable, we want to have "suggested" as selected filter
        // when selected configuration changes
        if (!isCartPopup && configurableVariantIndex !== prevConfigurableVariantIndex) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ activeFilter: -1 });
        }
    }

    onActiveAccessoryChange(activeAccessory) {
        this.setState({
            activeAccessory
        });

        this.toggleIsScroll();
    }

    onQuickViewClick(product) {
        const { showPopup } = this.props;
        showPopup(ACCESSORY_QUICK_VIEW_POPUP, product);
    }

    onShowMoreClick() {
        const { showMore } = this.state;
        this.setState({
            showMore: !showMore
        });
    }

    containerProps = () => {
        const {
            requiredAccessories,
            isCartPopup,
            configurableVariantIndex,
            linkedProducts
        } = this.props;

        const {
            activeAccessory,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            showMore,
            filterOptions,
            activeFilter,
            isScroll
        } = this.state;

        return {
            isScroll,
            configurableVariantIndex,
            suggested_accessories_skus: this._getSuggestedAccessoriesSkus(),
            requiredAccessories,
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true })
            },
            productCardProps: {
                activeAccessory,
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            configurableInitialLinkedProducts: this._getConfigurableInitialLinkedProducts(),
            linkedProducts: this.sortRequiredAccessories(linkedProducts),
            showMore,
            filterOptions,
            activeFilter,
            isCartPopup
        };
    };

    /**
     * Used as scroll event is triggered for both - tooltip scroll and Accessories section scroll.
     * We only want to close tooltip if it is Accessory section scroll
     * @param {Event} e
     */
    checkScroll(e) {
        if (!e.target?.className?.includes(TOOLTIP)) {
            this.toggleIsScroll();
        }
    }

    // setTimeout as there is no way to know when scroll stopped.
    // Used to hide tooltip in Accessories tab on scroll
    toggleIsScroll() {
        this.setState({ isScroll: true },
            () => setTimeout(() => this.setState({ isScroll: false }), SCROLL_TIMEOUT_300));
    }

    setAccessoriesFilter(value) {
        const { activeFilter } = this.state;

        if (activeFilter === value) {
            this.setState({
                activeFilter: 0
            });
        } else {
            this.setState({
                activeFilter: value
            });
        }
    }

    /**
     * ZFR-1503 changed implementation to query all linked products for configurable instead of only configurable's
     * linked products as it was by default.
     * Some functionality requires only configurable product's linked products.
     * This function helps to obtain them
     */
    _getConfigurableInitialLinkedProducts() {
        const {
            linkedProducts,
            currentProduct: {
                product_links = []
            } = {}
        } = this.props;
        const configurableInitialLinkedProducts = {
            crosssell: {
                items: [],
                count: 0
            },
            related: {
                items: [],
                count: 0
            },
            upsell: {
                items: [],
                count: 0
            }
        };

        product_links.forEach(({ link_type, linked_product_sku }) => {
            const linkedProduct = linkedProducts[link_type]?.items?.find((prod) => prod.sku === linked_product_sku);

            if (linkedProduct) {
                configurableInitialLinkedProducts[link_type]?.items?.push(linkedProduct);
                configurableInitialLinkedProducts[link_type].count += 1;
            }
        });

        return configurableInitialLinkedProducts;
    }

    _getSuggestedAccessoriesSkus() {
        const {
            configurableVariantIndex,
            currentProduct,
            currentProduct: {
                attributes: {
                    suggested_accessories_skus: {
                        attribute_value: suggested_accessories_skus = ''
                    } = {}
                } = {},
                type_id
            },
            linkType
        } = this.props;

        if (linkType === CROSS_SELL) {
            if (type_id === CONFIGURABLE && configurableVariantIndex !== -1) {
                const {
                    attributes: {
                        suggested_accessories_skus: {
                            attribute_value: suggested_accessories_skus = ''
                        } = {}
                    } = {}
                } = currentProduct?.variants?.[configurableVariantIndex];

                return suggested_accessories_skus;
            }

            return suggested_accessories_skus;
        }

        return '';
    }

    filterProducts(items, maxcount = -1) {
        return items.filter(function (product) {
            if ((maxcount === -1 || this.count < maxcount) && product.stock_status !== OUT_OF_STOCK) {
                if (product?.dynamicAttributes?.discontinued_product
                    && DISCONTINUED_PRODUCT_LABELS.includes(product.dynamicAttributes.discontinued_product)) {
                    return false;
                }
                this.count++;

                return true;
            }

            return false;
        // eslint-disable-next-line array-func/no-unnecessary-this-arg
        }, { count: 0 });
    }

    createImageObject(relatedProducts) {
        const productsWithImages = relatedProducts;
        productsWithImages.forEach((product) => {
            // eslint-disable-next-line no-param-reassign
            product.image = { url: `/media/catalog/product${product.swatchImages?.image}` };
            // eslint-disable-next-line no-param-reassign
            product.small_image = { url: product.swatchImages?.small_image };
            // eslint-disable-next-line no-param-reassign
            product.thumbnail = { url: `/media/catalog/product${product.swatchImages?.image}` };
        });

        return productsWithImages;
    }

    // Sorting accessories so that required accessories come first in the list
    sortRequiredAccessories(linkedProducts) {
        const {
            linkType,
            configurableVariantIndex,
            currentProduct,
            currentProduct: {
                type_id,
                required_accessories: parentRequiredAccessories = [],
                product_links: parentProductLinks = []
            }
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let {
            required_accessories = [],
            product_links
        } = type_id === CONFIGURABLE && configurableVariantIndex !== -1
            ? currentProduct?.variants?.[configurableVariantIndex]
            : currentProduct;

        const enhancedLinkedProducts = JSON.parse(JSON.stringify(linkedProducts));

        // If it's configurable, show variant's products or if there are none, show parent's linked products
        // Ref: https://sepoy.atlassian.net/browse/ZFR-1503
        if (linkType === CROSS_SELL) {
            if (enhancedLinkedProducts[CROSS_SELL]?.items) {
                // eslint-disable-next-line fp/no-let
                let crosssellProducts = product_links?.filter((prod) => prod.link_type === CROSS_SELL);

                // If child doesn't have any crosssel (accessories) items, use parent
                if (!crosssellProducts || crosssellProducts?.length === 0) {
                    required_accessories = parentRequiredAccessories;
                    product_links = parentProductLinks;

                    crosssellProducts = product_links?.filter((prod) => prod.link_type === CROSS_SELL);
                }

                const linkedProductSKUs = crosssellProducts?.map((prod) => prod.linked_product_sku);
                enhancedLinkedProducts[CROSS_SELL].items = enhancedLinkedProducts[CROSS_SELL]?.items?.filter(
                    (prod) => linkedProductSKUs.includes(prod.sku)
                );
                enhancedLinkedProducts[CROSS_SELL].total_count = enhancedLinkedProducts[CROSS_SELL]?.items?.length;
            } else {
                enhancedLinkedProducts[CROSS_SELL].items = [];
                enhancedLinkedProducts[CROSS_SELL].total_count = 0;
            }
        }

        if (enhancedLinkedProducts[CROSS_SELL]?.items && required_accessories?.length) {
            const originalItems = linkedProducts[CROSS_SELL]?.items;
            const reorderedRequiredAccessories = originalItems ? originalItems.slice() : [];
            reorderedRequiredAccessories
                ?.sort((accessoryOne, accessoryTwo) => {
                    if (required_accessories.includes(accessoryOne?.sku)) {
                        return -1;
                    }

                    if (required_accessories.includes(accessoryTwo?.sku)) {
                        return 1;
                    }

                    return 0;
                });

            enhancedLinkedProducts[CROSS_SELL].items = reorderedRequiredAccessories;
        }

        return enhancedLinkedProducts;
    }

    addCurrentProductAsLinked() {
        const {
            currentProduct,
            linkedProducts,
            linkedProducts: {
                [RELATED]: {
                    items
                }
            }
        } = this.props;
        const productsWithImages = this.createImageObject(items);

        if (currentProduct) {
            const newItemIndex = items.length + 1;
            const linkedAndCurrentItems = [currentProduct, ...productsWithImages];

            const linkedAndCurrentProduct = {
                ...linkedProducts,
                related: {
                    total_count: newItemIndex,
                    items: linkedAndCurrentItems
                }
            };

            return linkedAndCurrentProduct;
        }

        return linkedProducts;
    }

    render() {
        const {
            linkType,
            isCrossSellSection,
            isLinkedProductsLoading,
            relatedProducts = [],
            linkedProducts,
            currentProduct: {
                type_id = ''
            } = {},
            linkedProducts: {
                [RELATED]: {
                    items: relatedItems = []
                },
                [linkType]: {
                    items = []
                } = {}
            }
        } = this.props;
        let usableRelatedItems = relatedItems;
        let usableItems = items;

        /**
         * ZFR-1503 changed implementation to query all linked products for configurable instead of only configurable's
         * linked products as it was by default.
         * For UPSELL we are interested only in configurable's linked products to know if there is something to show in
         * related products section.
         */
        if (type_id === CONFIGURABLE) {
            const {
                [RELATED]: {
                    items: configRelatedItems = []
                },
                [linkType]: {
                    configItems = []
                } = {}
            } = this._getConfigurableInitialLinkedProducts();

            usableRelatedItems = configRelatedItems;
            usableItems = configItems;
        }

        if (isLinkedProductsLoading && isCrossSellSection) {
            return (
                <ContentWrapper
                  mix={ { block: 'ProductLinks', mods: { isCrossSellSectionLoading: true } } }
                  wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
                  label={ __('Linked products') }
                >
                    <Loader isLoading={ isLinkedProductsLoading } />
                </ContentWrapper>
            );
        }

        if ((linkType === UPSELL
            && this.filterProducts(usableItems, FOUR).length === 0
            && this.filterProducts(usableRelatedItems, FOUR).length === 0)
            || (linkType !== RELATED
                && linkType !== UPSELL
                // If there are no accessories for current configurable, don't show tab
                && this.sortRequiredAccessories(linkedProducts)?.crosssell?.items?.length === 0)
            || (linkType === RELATED && relatedProducts.length === 0)
        ) {
            return null;
        }

        return (
            <ProductLinksComponent
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer);
