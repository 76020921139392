import { updatePrerender } from 'packages/prerender/src/store/Prerender/Prerender.action';

import {
    checkForErrors,
    formatURI,
    getFetch,
    getGraphqlEndpoint,
    getWindowId,
    HTTP_201_CREATED,
    HTTP_410_GONE,
    parseResponse,
    putPersistedQuery
} from 'SourceUtil/Request/Request';
import { isBroadcastChannelSupported } from 'Util/BroadcastChannel/BroadcastChannel';
import getStore from 'Util/Store';

export * from 'SourceUtil/Request/Request';

/**
 * Make GET request to endpoint (via ServiceWorker)
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @param  {Number} cacheTTL Cache TTL (in seconds) for ServiceWorker to cache responses
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace ZnetPwa/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL, isPrerenderFlagUpdated = true) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());
    const store = getStore();
    const { dispatch } = store;

    dispatch(updatePrerender({ executeGet: false }));

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace ZnetPwa/Util/Request/getFetch/then */
            (res) => {
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace ZnetPwa/Util/Request/putPersistedQuery/then */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace ZnetPwa/Util/Request/getFetch/then */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else {
                    resolve(res);

                    // Inspiration page's load takes time and this update
                    // would set flag before all products are loaded
                    // TODO: Improve Inspiration page's load time
                    if (isPrerenderFlagUpdated) {
                        dispatch(updatePrerender({ executeGet: true }));
                    }
                }
            }
        );
    }));
};

/**
 * Keep track of created channels
 * Used to solve https://sepoy.atlassian.net/browse/ZFR-1352
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const createdChannels = {};

/**
 * Listen to the BroadCast connection
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @return {Promise<any>} Broadcast message promise
 * @namespace ZnetPwa/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) => new Promise((resolve) => {
    const { BroadcastChannel } = window;
    const windowId = getWindowId();

    if (BroadcastChannel) {
        const channelName = `${name}_${windowId}`;

        // Check if the channel has already been created
        // Used to solve https://sepoy.atlassian.net/browse/ZFR-1352
        if (!createdChannels[channelName]) {
            createdChannels[channelName] = true; // Track the creation

            if (isBroadcastChannelSupported()) {
                const bc = new BroadcastChannel(channelName);
                bc.onmessage = (update) => {
                    const { data: { payload: body } } = update;
                    resolve(checkForErrors(body));
                };
            }
        } else {
            console.warn(`Channel ${channelName} already exists!`);
        }
    }
});
