import MyAccountQuery from 'Query/MyAccount.query';
import {
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { getAuthorizationToken } from 'SourceUtil/Auth/Token';
import {
    updateCustomerDetails,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import {
    deleteAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS
};

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace ZnetPwa/Store/MyAccount/Dispatcher/MyAccountDispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    createAccount(options = {}, dispatch) {
        const { customer: { email, captcha_token = null }, password } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace ZnetPwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));
                    return 2;
                }

                return this.signIn({ email, password, captcha_token }, dispatch);
            },

            /** @namespace ZnetPwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (error) => {
                if (window.grecaptcha) {
                    window.grecaptcha.reset();
                }

                dispatch(showNotification('error', error[0].message));
                Promise.reject();
                dispatch(updateIsLoading(false));

                return false;
            }
        );
    }

    logout(authTokenExpired = false, dispatch) {
        const _isSignedIn = !!getAuthorizationToken();
        const store = getStore();
        const {
            MyAccountReducer: {
                isSignedIn: isCustomerSignedIn
            } = {}
        } = store.getState();

        if (authTokenExpired) {
            dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            this.handleForceRedirectToLoginPage();

        /** Used to re-check if logout was not due to session timeout
         *  It causes revoking token which returns an error as it is already revoked
         *  Ref.: https://sepoy.atlassian.net/browse/ZFR-753
         * */
        } else if (!(!_isSignedIn && isCustomerSignedIn)) {
            const mutation = MyAccountQuery.getRevokeAccountToken();
            fetchMutation(mutation);

            deleteAuthorizationToken();
            dispatch(showNotification('success', __('You are successfully logged out!')));
        }

        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.resetGuestCart(dispatch);
                dispatcher.createGuestEmptyCart(dispatch);
            }
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetWishlist(dispatch)
        );
        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetComparedProducts(dispatch)
        );
    }
}

export default new MyAccountDispatcher();
