import PropTypes from 'prop-types';
import Loader from 'Component/Loader';
import Toggle from '../Toggle';
import './MyAccountMySocialNetworks.style';

import SocialAuthentication from '../SocialAuthentication';

/** @namespace ScandiPWA/SocialLoginGraphql/Component/MyAccountMySocialNetworks/Component */
export class MyAccountMySocialNetworks extends PureComponent {
    static propTypes = {
        getAccountsData: PropTypes.func.isRequired,
        unlinkAccount: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    componentDidMount() {
        window.addEventListener('message', this.accountLinkHandler);
        this.requestAccountsData();
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.accountLinkHandler);
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            socialAccountsData: [],
            isLoading: true
        };

        this.accountLinkHandler = this.accountLinkHandler.bind(this);
    }

    /**
     * Request social network accounts data from BE
     */
    requestAccountsData() {
        const { getAccountsData } = this.props;

        getAccountsData().then(
            /** @namespace ScandiPWA/SocialLoginGraphql/Component/MyAccountMySocialNetworks/Component/getAccountsDataThen */
            ({ socialLogin_accountData: socialAccountsData }) => {
                this.setState({ socialAccountsData, isLoading: false });
            }
        );
    }

    /**
     * Unlink social account by type
     *
     * @param type
     */
    unlinkSocialAccount(type) {
        const { unlinkAccount, showNotification } = this.props;
        this.setState({ isLoading: true });

        unlinkAccount(type).then(
            /** @namespace ScandiPWA/SocialLoginGraphql/Component/MyAccountMySocialNetworks/Component/unlinkAccountThen */
            ({ socialLogin_unlinkAccount: socialAccountsData }) => {
                this.setState({ socialAccountsData, isLoading: false });
            },
            /** @namespace ScandiPWA/SocialLoginGraphql/Component/MyAccountMySocialNetworks/Component/unlinkAccountThen */
            () => {
                showNotification('error', __('Unable to unlink this account.'));
                this.setState({ isLoading: false });
            }
        );
    }

    accountLinkHandler(event) {
        if (event?.data?.result && event?.data?.type) {
            this.setState({ isLoading: true });
            this.requestAccountsData();
        }
    }

    /**
     * Render linked accounts
     *
     * @returns {*}
     */
    renderLinked() {
        const { socialAccountsData: { linked } } = this.state;

        // No linked accounts to display
        if (!linked?.length) {
            return null;
        }

        return (
            linked.map(item => (
                <div block="MyAccountMySocialNetworks" elem="Item" key={ item.type }>
                    <button
                      block="Button"
                      elem="Social"
                      mods={ { type: item.type } }
                      name={ item.label }
                      onClick={ () => this.unlinkSocialAccount(item.type) }
                    />
                    <div
                      block="MyAccountMySocialNetworks"
                      elem="ToggleWrapper"
                      onClick={ () => this.unlinkSocialAccount(item.type) }
                      onKeyPress={ () => {} }
                      role="button"
                      tabIndex="0"
                    >
                        <Toggle
                          id={ item.type }
                          isChecked
                          label={ __('Connected') }
                        />
                    </div>
                </div>
            ))
        );
    }

    /**
     * Render unlinked accounts
     *
     * @returns {*}
     */
    renderUnlinked() {
        const {
            socialAccountsData: {
                buttons
            }
        } = this.state;

        // No social media login buttons to display
        if (!buttons?.length) {
            return null;
        }

        return (
            <SocialAuthentication
              buttons={ buttons }
              isMyAccount
            />
        );
    }

    render() {
        const { isLoading } = this.state;

        return (
            <div block="MyAccountMySocialNetworks">
                <div block="MyAccountMySocialNetworks" elem="Title">
                    <span>{ __('Connect Social Accounts') }</span>
                </div>
                <div block="MyAccountMySocialNetworks" elem="Content">
                    <Loader isLoading={ isLoading } />
                    { this.renderLinked() }
                    { this.renderUnlinked() }
                </div>
            </div>
        );
    }
}

export default MyAccountMySocialNetworks;
