import { UPDATE_DRIP_STATUS } from './Drip.action';

/** @namespace Drip/Store/Drip/Reducer/getInitialState */
export const getInitialState = () => ({
    dripLoaded: false
});

/** @namespace Drip/Store/Drip/Reducer/DripReducer */
export const DripReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_DRIP_STATUS:
        const { payload: { dripLoaded } } = action;

        return {
            ...state,
            dripLoaded
        };

    default:
        return state;
    }
};

export default DripReducer;
