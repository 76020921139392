import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { fetchQuery } from 'Util/Request';

import SocialNetworksQuery from '../../query/SocialNetworks.query';
import SocialAuthentication from './SocialAuthentication.component';
import { CHECKOUT_CART } from './SocialAuthentication.config';

/** @namespace ZnetPwa/Packages/SocialLogin/Src/Component/SocialAuthentication/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isDatadogEnabled: state.ConfigReducer.datadog_enabled,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    amastySocialLoginPosition: state.ConfigReducer.amastySocialLoginPosition
});

/** @namespace ZnetPwa/Packages/SocialLogin/Src/Component/SocialAuthentication/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace ZnetPwa/Packages/SocialLogin/Src/Component/SocialAuthentication/Container/SocialAuthenticationContainer */
export class SocialAuthenticationContainer extends PureComponent {
    static propTypes = {
        isDatadogEnabled: PropTypes.bool.isRequired,
        buttons: PropTypes.arrayOf(PropTypes.shape),
        amastySocialLoginPosition: PropTypes.string.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isCartPage: PropTypes.bool,
        isMyAccount: PropTypes.bool
    };

    static defaultProps = {
        buttons: null,
        isCartPage: false,
        isMyAccount: false
    };

    componentDidMount() {
        this._isMounted = true;
        this.requestButtons();
    }

    componentDidUpdate(prevProps) {
        const { buttons: prevButtons } = prevProps;
        const { buttons } = this.props;

        if (prevButtons !== buttons) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ buttonsList: buttons });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __construct(props) {
        super.__construct(props);

        const { buttons } = this.props;

        this.state = {
            buttonsList: buttons
        };

        this._isMounted = false;
    }

    /**
     * Request enabled social buttons in they was not provided in props
     *
     * @returns {*}
     */
    requestButtons() {
        const { isDatadogEnabled } = this.props;
        const { buttonsList } = this.state;

        if (!buttonsList || !buttonsList.length) {
            const query = SocialNetworksQuery.getSocialButtonsQuery();

            return fetchQuery(query).then(
                /** @namespace ZnetPwa/Packages/SocialLogin/Src/Component/SocialAuthentication/Container/fetchQuery/then */
                ({ socialLogin_buttons: socialButtons }) => {
                    if (this._isMounted) {
                        this.setState({ buttonsList: socialButtons });
                    }
                },
                /** @namespace ZnetPwa/Packages/SocialLogin/Src/Component/SocialAuthentication/Container/fetchQuery/then */
                (err) => {
                    if (isDatadogEnabled) {
                        const message = err?.[0]?.debugMessage
                        || err?.[0]?.message
                        || 'Error querying social buttons';
                        const errorMessage = new Error(message);
                        datadogRum.addError(errorMessage);
                    }
                }
            );
        }

        return null;
    }

    render() {
        const {
            isMyAccount,
            isCartPage,
            isSignedIn,
            amastySocialLoginPosition
        } = this.props;
        const { buttonsList } = this.state;

        if (isSignedIn) {
            return null;
        }

        // If this option is not in config don't show social login there
        if (isCartPage && !amastySocialLoginPosition?.includes(CHECKOUT_CART)) {
            return null;
        }

        return (
            <SocialAuthentication
              isCartPage={ isCartPage }
              isMyAccount={ isMyAccount }
              buttonsList={ buttonsList }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    SocialAuthenticationContainer,
);
