/**
 * @description Multi-manufacturer cart utility
 * @author      C. M. de Picciotto <cmdepicciotto@sepoy.io>
 * @todo        This is a temporary utility implemented in order to solve multi-manufacturer cart requirement.
 *              It encapsulates logic related to multi-manufacturer cart validations, but these validations
 *              must be removed in the final implementation of this multi-manufacturer cart feature, because
 *              every cart configuration should be allowed.
 *              There will be validations but they will be handled in the backend
 * {@link       https://sepoy.atlassian.net/browse/ZFR-1523}
 */
export * from './MultiManufacturerCartHelper';
