import PropTypes from 'prop-types';
import { createRef } from 'react';

import TooltipContainer from 'Component/Tooltip/Tooltip.container';
import CSS from 'Util/CSS';
import { isMobile } from 'Util/Mobile';

import AccessoriesTooltipComponent from './AccessoriesTooltip.component';
import { TOOLTIP_ICON_HEIGHT } from './AccessoriesTooltip.config';

/** @namespace ZnetPwa/Component/AccessoriesTooltip/Container/AccessoriesTooltipContainer */
export class AccessoriesTooltipContainer extends TooltipContainer {
    static propTypes = {
        ...this.propTypes,
        blockRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        isScroll: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        blockRef: () => {}
    };

    state = {
        ...this.state,
        isClick: false,
        isHover: false
    };

    tooltipWrapperRef = createRef();

    tooltipRef = createRef();

    containerFunctions = {
        ...this.containerFunctions,
        addTooltipOffset: this.addTooltipOffset.bind(this),
        onMouseEnter: this.onMouseEnter.bind(this),
        onMouseLeave: this.onMouseLeave.bind(this)
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScrollTrigger.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollTrigger);
    }

    componentDidUpdate(prevProps) {
        const {
            blockRef,
            isScroll
        } = this.props;
        const {
            isShowing
        } = this.state;
        const {
            isScroll: prevIsScroll
        } = prevProps;

        if (this.tooltipRef?.current && this.tooltipWrapperRef?.current && blockRef?.current && isShowing) {
            const block = blockRef?.current?.getBoundingClientRect();
            const tooltip = this.tooltipRef?.current?.getBoundingClientRect();

            this.addTooltipOffset(tooltip, block);
        }

        if (isScroll && isScroll !== prevIsScroll) {
            this.onClickOutside();
        }
    }

    containerProps = () => {
        const {
            tooltipText,
            render,
            title,
            mix,
            children,
            blockRef
        } = this.props;

        const { isShowing } = this.state;

        return {
            children,
            tooltipText,
            render,
            title,
            mix,
            isShowing,
            blockRef,
            tooltipWrapperRef: this.tooltipWrapperRef,
            tooltipRef: this.tooltipRef
        };
    };

    onClick(e) {
        const { isShowing, isClick, isHover } = this.state;

        if (isHover) {
            this.setState({ isClick: true });
        } else {
            this.setState({
                isShowing: !isShowing,
                isClick: !isClick
            });
        }

        this.preventClick(e);
    }

    onMouseEnter() {
        const { isClick } = this.state;

        if (!isClick && !isMobile.any()) {
            this.setState({
                isShowing: true,
                isHover: true
            });
        }
    }

    onMouseLeave() {
        const { isClick } = this.state;

        if (!isClick && !isMobile.any()) {
            this.setState({
                isShowing: false,
                isHover: false
            });
        }
    }

    onScrollTrigger() {
        const {
            isShowing,
            isClick,
            isHover
        } = this.state;

        if (isShowing || isClick || isHover) {
            this.onClickOutside();
        }
    }

    onClickOutside() {
        this.setState({
            isShowing: false,
            isClick: false,
            isHover: false
        });
    }

    addTooltipOffset(tooltip, block) {
        const offsetTop = `${
            Math.abs(block.top - tooltip.top)
            - this.tooltipWrapperRef.current.clientHeight
            - TOOLTIP_ICON_HEIGHT
        }px`;
        const offsetLeft = `${
            Math.abs(block.left - tooltip.left)
        }px`;

        CSS.setVariable(this.tooltipWrapperRef, 'tooltip-offset-top', offsetTop);
        CSS.setVariable(this.tooltipWrapperRef, 'tooltip-offset-left', offsetLeft);
    }

    render() {
        return (
            <AccessoriesTooltipComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default AccessoriesTooltipContainer;
