import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';

/** @namespace ZnetPwa/Component/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends SourceProductPrice {
    /** We never send special price in schema */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getCurrentPriceSchema() {
        const { isSchemaRequired, price } = this.props;

        return isSchemaRequired ? { itemProp: 'price', content: price.minimum_price.regular_price.value } : {};
    }

    renderOldPrice() {
        const {
            isSample,
            roundedRegularPrice,
            discountPercentage
        } = this.props;

        if (discountPercentage === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
            >
                { isSample && __('$') }
                { roundedRegularPrice }
            </del>
        );
    }
}

export default ProductPriceComponent;
