/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

import Loader from 'Component/Loader';
import { Link as SourceLinkComponent } from 'SourceComponent/Link/Link.component';

import './Link.style';

/** @namespace ZnetPwa/Component/Link/Component/LinkComponent */
export class LinkComponent extends SourceLinkComponent {
    static propTypes = {
        ...this.propTypes,
        isLoaderActive: PropTypes.bool.isRequired,
        handleLinkClick: PropTypes.func.isRequired,
        showLoader: PropTypes.bool.isRequired
    };

    render() {
        const {
            showLoader,
            className,
            bemProps,
            children,
            to,
            ...props
        } = this.props;

        if (!to) {
            return (
                <div { ...props } { ...bemProps }>
                    { children }
                </div>
            );
        }

        if (/^#/.test(to)) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a
                  { ...props }
                  { ...bemProps }
                  onClick={ this.scrollToElement }
                  href={ to }
                >
                    { children }
                </a>
            );
        }

        const classNameConverted = `${ className } ${ stringify(bemProps)}`;

        if (/^https?:\/\//.test(to)) {
            return (
                <a
                  { ...props }
                  href={ to }
                  // eslint-disable-next-line react/forbid-dom-props
                  className={ classNameConverted }
                >
                    { children }
                </a>
            );
        }

        if (showLoader) {
            const {
                handleLinkClick,
                isLoaderActive
            } = this.props;

            return (
                <>
                    <Loader isLoading={ isLoaderActive } />
                    <RouterLink
                      { ...props }
                      to={ to }
                      onClick={ handleLinkClick }
                      // eslint-disable-next-line react/forbid-component-props
                      className={ classNameConverted }
                    >
                        { children }
                    </RouterLink>
                </>
            );
        }

        return (
            <RouterLink
              { ...props }
              to={ to }
              // eslint-disable-next-line react/forbid-component-props
              className={ classNameConverted }
            >
                { children }
            </RouterLink>
        );
    }
}

export default LinkComponent;
