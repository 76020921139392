/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

export const AuthorsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "AuthorsPage" */ '../route/AuthorsPage'));
export const PostsDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "PostsDetails" */ '../route/PostsDetails'));
export const PostsListing = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "PostsListing" */ '../route/PostsListing'));

export class RouterPlugin {
    /**
     * Adds blog url handles to the router SWITCH_ITEMS_TYPE list
     * @param originalMember
     * @returns {*[]}
     */
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path="/blog/authors/:author" component={ AuthorsPage } />,
            position: 98
        },
        {
            component: <Route path="/blog/:handle" component={ PostsDetails } />,
            position: 100
        },
        {
            component: <Route path="/blog/" component={ PostsListing } />,
            position: 110
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};

export default config;
