import { Overlay as SourceOverlay } from 'SourceComponent/Overlay/Overlay.component';
import { toggleScroll } from 'Util/Browser';
import { isMobile } from 'Util/Mobile';

/** @namespace ZnetPwa/Component/Overlay/Component/OverlayComponent */
export class OverlayComponent extends SourceOverlay {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    freezeScroll() {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
        document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;

        if (isMobile.any()) {
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    unfreezeScroll() {
        toggleScroll(true);
        document.body.style.marginTop = 0;
        if (isMobile.any()) {
            document.body.style.overflow = 'auto';
            document.body.style.position = 'relative';
        }
        window.scrollTo(0, this.YoffsetWhenScrollDisabled);
    }
}

export default OverlayComponent;
