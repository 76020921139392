/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { VALUE_TYPE } from 'Component/SearchSpringListFacet/SearchSpringListFacet.config';
import { hideActiveOverlay, toggleOverlayByKey } from 'SourceStore/Overlay/Overlay.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { customerType } from 'Type/Account';
import { DeviceType } from 'Type/Device';
import isMobile from 'Util/Mobile';
import {
    processCheckBoxClick,
    searchStateToUrl,
    urlToSearchState
} from 'Util/SearchSpring';

import { SEARCH_MIN_CHARS } from '../SearchSpringField/SearchSpringField.config';
import SearchSpringOverlay from './SearchSpringOverlay.component';
import {
    ACCESSORIES_ATTRIBUTE_ID,
    BRAND_FIELD,
    CATEGORY_HIERARCHY,
    CATEGORY_LABEL,
    FLOORING_ATTRIBUTE_ID,
    PRICE_FIELD,
    RUG_ATTRIBUTE_ID,
    SEARCH,
    SEARCHSPRING_SEARCH_OVERLAY,
    SIX_PRODUCTS_IN_SEARCH
} from './SearchSpringOverlay.config';

/** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    device: state.ConfigReducer.device,
    customer: state.MyAccountReducer,
    isSearchSpringEnabled: state.ConfigReducer.searchspring_enabled,
    searchSpringSiteId: state.ConfigReducer.searchspring_siteid,
    isBgFiltersEnabled: state.ConfigReducer.searchspring_bg_filters_enabled,
    isOverlaySuggestedQueryEnabled: state.ConfigReducer.searchspring_suggested_overlay_enabled,
    suggestionCount: state.ConfigReducer.searchspring_alternative_results,
    productCount: state.ConfigReducer.searchspring_product_count,
    // Only updated if there is a correction or suggestion
    queryInState: state.SearchBarReducer.query
});

/** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/SearchSpringOverlayContainer */
export class SearchSpringOverlayContainer extends PureComponent {
    static propTypes = {
        customer: customerType.isRequired,
        showNotification: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        onAutosuggestOptionClick: PropTypes.func.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        match: PropTypes.object.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        location: PropTypes.object.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        history: PropTypes.object.isRequired,
        search: PropTypes.string.isRequired,
        device: DeviceType.isRequired,
        hideMobileSearch: PropTypes.func.isRequired,
        isSearchSpringEnabled: PropTypes.bool.isRequired,
        searchSpringSiteId: PropTypes.string.isRequired,
        isBgFiltersEnabled: PropTypes.bool.isRequired,
        isOverlaySuggestedQueryEnabled: PropTypes.bool.isRequired,
        suggestionCount: PropTypes.string.isRequired,
        productCount: PropTypes.string.isRequired,
        queryInState: PropTypes.string.isRequired
    };

    containerFunctions = {
        createCategorySearchLink: this.createCategorySearchLink.bind(this),
        onSuggestionClick: this.onSuggestionClick.bind(this),
        toggleOverlayOnClick: this.toggleOverlayOnClick.bind(this),
        onFilterOptionClick: this.onFilterOptionClick.bind(this)
    };

    state = {
        priceFacets: [],
        brandFacets: [],
        totalResults: 0,
        didYouMean: '',
        isSuggestionClick: false,
        ssPageLoadId: uuidv4(),
        categorySuggestions: [],
        productSearch: [],
        alternativeQueries: [],
        isLoading: false,
        isCorrectedQueryPresent: false
    };

    componentDidMount() {
        const {
            search,
            isSearchSpringEnabled,
            searchSpringSiteId,
            queryInState,
            onAutosuggestOptionClick
        } = this.props;

        if (isMobile.iOS() && window?.visualViewport) {
            window.visualViewport.addEventListener('resize', this.setIosViewportHeight);
        }

        if (isMobile.any() && search?.length > SEARCH_MIN_CHARS && isSearchSpringEnabled && searchSpringSiteId) {
            this.setState({ alternativeQueries: [] }, () => this.executeSuggestionSearch());
        }

        if (isMobile.any()) {
            const queryToUse = (queryInState !== '' && search !== queryInState) ? queryInState : search;
            onAutosuggestOptionClick(queryToUse);
        }

        // Executing search for overlay if the page is loaded with the search in URL, otherwise there will be no results
        if (isSearchSpringEnabled && searchSpringSiteId && search !== '' && search.length >= SEARCH_MIN_CHARS) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                alternativeQueries: [],
                didYouMean: ''
            }, () => this.executeSuggestionSearch());
        }
    }

    componentDidUpdate(prevProps) {
        const {
            search,
            location: { pathname },
            device,
            activeOverlay,
            hideActiveOverlay,
            toggleOverlayByKey,
            isSearchSpringEnabled,
            searchSpringSiteId,
            queryInState,
            onAutosuggestOptionClick
        } = this.props;
        const {
            search: prevSearch,
            location: { pathname: prevPath },
            queryInState: prevQueryInState
        } = prevProps;
        const { isSuggestionClick } = this.state;

        if (search.length < SEARCH_MIN_CHARS) {
            return;
        }

        if (search !== prevSearch) {
            if (device.isMobile) {
                if (activeOverlay !== SEARCHSPRING_SEARCH_OVERLAY) {
                    toggleOverlayByKey(SEARCHSPRING_SEARCH_OVERLAY);
                }

                if (prevSearch.length > SEARCH_MIN_CHARS
                    && search.length < SEARCH_MIN_CHARS
                    && activeOverlay === SEARCHSPRING_SEARCH_OVERLAY) {
                    hideActiveOverlay();
                }
            }

            if (isSearchSpringEnabled && searchSpringSiteId) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    isCorrectedQueryPresent: false,
                    alternativeQueries: [],
                    didYouMean: ''
                }, () => this.executeSuggestionSearch());
            }
        }

        /** Used to update the query in search bar if suggestion was applied on search page */
        if (queryInState !== prevQueryInState) {
            onAutosuggestOptionClick(queryInState);
        }

        // Reset suggestion click flag
        if (isSuggestionClick) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isSuggestionClick: false, alternativeQueries: [] });
        }

        // Generate new ID for SearchSpring on every page load
        if (pathname !== prevPath) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ ssPageLoadId: uuidv4() });
        }

        if (isMobile.iOS() && window?.visualViewport) {
            window.visualViewport.addEventListener('resize', this.setIosViewportHeight);
        }
    }

    componentWillUnmount() {
        if (isMobile.iOS() && window?.visualViewport) {
            window.visualViewport.removeEventListener('resize', this.setIosViewportHeight);
        }
    }

    containerProps = () => {
        const {
            search,
            device,
            customer: {
                customer: {
                    customerGroupName = ''
                } = {}
            } = {}
        } = this.props;
        const {
            priceFacets,
            brandFacets,
            totalResults,
            didYouMean,
            categorySuggestions,
            productSearch,
            alternativeQueries,
            isLoading,
            isCorrectedQueryPresent
        } = this.state;
        const searchState = urlToSearchState(window.location);
        const updatedSearchState = {
            ...searchState,
            query: search
        };

        return {
            customerGroupName,
            search,
            isLoading,
            isCorrectedQueryPresent,
            alternativeQueries,
            priceFacets,
            brandFacets,
            device,
            searchState: updatedSearchState,
            didYouMean,
            totalResults,
            categorySuggestions,
            productSearch
        };
    };

    getAlternatives(alternatives, correctedQuery) {
        const { search } = this.props;
        const alternativeQueries = alternatives?.length ? alternatives.map((obj) => obj.text) : [];
        if (correctedQuery && correctedQuery !== search) {
            alternativeQueries.unshift(correctedQuery);
            this.setState({ isCorrectedQueryPresent: true });
        }

        this.setState({ alternativeQueries });
    }

    // Obtaining suggestions for the query and passing first suggestion (or original query if null) to the search API
    // Docs: https://searchspring.zendesk.com/hc/en-us/articles/360038485532-AutoComplete-API-Integrations
    executeSuggestionSearch() {
        const {
            search,
            showNotification,
            searchSpringSiteId,
            isOverlaySuggestedQueryEnabled,
            suggestionCount,
            productCount
        } = this.props;

        this.setState({ isLoading: true });

        let suggestionsToQuery = 0;
        // Not related to number of products returned. Variable used by SS to determine how many products to scan
        let productsToQuery = 0;

        if (suggestionCount && suggestionCount !== '') {
            // +1 because API counts suggested query as well, but we are using it right away when no corrected-query
            // and we are not showing it to user
            suggestionsToQuery = parseInt(suggestionCount, 10) + 1;
        }

        if (productCount && productCount !== '') {
            productsToQuery = productCount;
        }

        fetch(`https://${ searchSpringSiteId }.a.searchspring.io/api/suggest/query?integratedSpellCorrection=true&siteId=${ searchSpringSiteId }&suggestionCount=${ suggestionsToQuery }&productCount=${ productsToQuery }&query=${ search }`, {
            method: 'get'
        })
            .then(
                /** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/fetch/then */
                (response) => response.json()
            ).then(
                /** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/fetch/then/then */
                (data) => {
                    if (
                        isOverlaySuggestedQueryEnabled
                        && data?.suggested?.text
                        && data?.suggested?.text !== search
                    ) {
                        this.executeSearch(data?.suggested?.text);
                    } else {
                        this.executeSearch(search);
                    }

                    if (data?.alternatives || data?.['corrected-query']) {
                        this.getAlternatives(data?.alternatives, data?.['corrected-query']);
                    }
                },
                /** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/fetch/then/then */
                () => {
                    showNotification('error', __('Sorry, there was an error while searching for your query.'));
                    this.setState({ isLoading: false });
                }
            );
    }

    // API call for search
    executeSearch(search) {
        const {
            showNotification, searchSpringSiteId, isBgFiltersEnabled
        } = this.props;
        const { ssPageLoadId } = this.state;
        let bgFilters = '';

        // Obtain previously generated cookies
        const value = `; ${document.cookie}`;
        const userIdParts = value.split('; ssUserId=');
        const sessionIdParts = value.split('; ssSessionIdNamespace=');
        let ssUserId = null;
        let ssSessionIdNamespace = null;

        this.setState({ isLoading: true });

        if (userIdParts.length === 2) {
            ssUserId = userIdParts.pop().split(';').shift();
        }

        if (sessionIdParts.length === 2) {
            ssSessionIdNamespace = sessionIdParts.pop().split(';').shift();
        }

        if (isBgFiltersEnabled) {
            // eslint-disable-next-line max-len
            bgFilters = `&bgfilter.attribute_set_id=${ FLOORING_ATTRIBUTE_ID }&bgfilter.attribute_set_id=${ RUG_ATTRIBUTE_ID }`;
        }

        // Including Flooring and Rugs in the results
        fetch(`https://${ searchSpringSiteId }.a.searchspring.io/api/search/autocomplete.json?q=${ search }${ bgFilters }&resultsFormat=native&siteId=${ searchSpringSiteId }&resultsPerPage=${ SIX_PRODUCTS_IN_SEARCH }&bgfilter.visibility=${ SEARCH }`, {
            method: 'get',
            headers: {
                'searchspring-session-id': ssSessionIdNamespace,
                'searchspring-user-id': ssUserId,
                'searchspring-page-load-id': ssPageLoadId
            }
        })
            .then(
                /** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/fetch/then */
                (response) => response.json()
            ).then(
                /** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/fetch/then/then */
                (data) => {
                    this.processSearch(data);
                    this.setState({ isLoading: false });
                },
                /** @namespace ZnetPwa/Component/SearchSpringOverlay/Container/fetch/then/then */
                () => {
                    showNotification('error', __('Sorry, there was an error while searching for your query.'));
                    this.setState({ isLoading: false });
                }
            );
    }

    processSearch(data) {
        const categorySuggestions = [];
        const productSearch = [];

        if (data) {
            if (data.facets) {
                const categories = data?.facets.filter((facet) => facet.field === CATEGORY_HIERARCHY);
                const brandFacets = data?.facets.filter((facet) => facet.field === BRAND_FIELD);
                const priceFacets = data?.facets.filter((facet) => facet.field === PRICE_FIELD);

                if (categories.length) {
                    categories[0]?.values.forEach((category) => categorySuggestions.push(category.label));

                    this.setState({ categorySuggestions });
                } else {
                    this.setState({ categorySuggestions: [] });
                }

                if (brandFacets.length) {
                    this.setState({ brandFacets });
                }

                if (priceFacets.length) {
                    this.setState({ priceFacets });
                }
            }

            if (data.results) {
                data.results.forEach((product) => {
                    let color = product.collection_color;
                    let image = product.thumbnailImageUrl;
                    let configurableMinPrice;
                    let configurableMaxPrice;
                    const { pathname, search, hash } = new URL(product.url);
                    const url = `${pathname}${search}${hash}`;

                    if (product.attribute_set_id === RUG_ATTRIBUTE_ID.toString()) {
                        if (product.swatch_configurable_product_json_config) {
                            const unescapedString = new DOMParser().parseFromString(
                                product.swatch_configurable_product_json_config, 'text/html'
                            );

                            const configurableProductsSwatches = JSON.parse(unescapedString.documentElement.textContent)
                                || {};

                            image = configurableProductsSwatches?.products[0]?.swatchImageUrl;
                            configurableMinPrice = configurableProductsSwatches.minPrice;
                            configurableMaxPrice = configurableProductsSwatches.maxPrice;
                        }

                        color = product.name?.replace(/&quot;/g, '"');
                    }

                    if (product.attribute_set_id === ACCESSORIES_ATTRIBUTE_ID.toString()) {
                        productSearch.push({
                            image,
                            name: product.name,
                            brand: product.brand,
                            color,
                            collection_name: product.collection_name,
                            width: product.plank_width,
                            url,
                            show_price: product.show_price,
                            request_quote_enabled: product.request_quote_enabled,
                            final_price: product.final_price,
                            special_price: product.special_price,
                            max_price: product.price,
                            unit_price: product.price,
                            attribute_set_id: parseInt(product.attribute_set_id, 10),
                            coverage_area_type: product.coverage_area_type
                        });
                    } else {
                        productSearch.push({
                            image,
                            name: product.name,
                            brand: product.brand,
                            color,
                            collection_name: product.collection_name?.replace('&quot;', '"'),
                            width: product.plank_width,
                            url,
                            show_price: product.show_price,
                            request_quote_enabled: product.request_quote_enabled,
                            final_price: product.final_price,
                            special_price: product.special_price,
                            max_price: product.max_price,
                            unit_price: product.unit_price,
                            attribute_set_id: parseInt(product.attribute_set_id, 10),
                            coverage_area_type: product.coverage_area_type,
                            configurableMinPrice,
                            configurableMaxPrice,
                            type: product.type_id,
                            regular_price: product.regular_price
                        });
                    }
                });

                this.setState({ productSearch });
            }

            if (!data.results?.length && data?.didYouMean?.query) {
                this.setState({ didYouMean: data?.didYouMean?.query });
            }

            if (data.pagination) {
                this.setState({ totalResults: data.pagination.totalResults });
            }
        }
    }

    createCategorySearchLink(value) {
        const {
            location,
            search
        } = this.props;
        let searchState = urlToSearchState(location);

        searchState = {
            query: search,
            filter: [{
                type: VALUE_TYPE,
                field: CATEGORY_HIERARCHY,
                label: value,
                value,
                categoryLabel: CATEGORY_LABEL
            }]
        };

        const searchUrl = searchStateToUrl(searchState);

        return searchUrl.replace('/search?', `/search/${ search }?`);
    }

    // Used to trigger search field value change on autosuggest or did you mean click
    onSuggestionClick(suggestion) {
        const {
            onAutosuggestOptionClick
        } = this.props;

        onAutosuggestOptionClick(suggestion);
    }

    toggleOverlayOnClick() {
        const { toggleOverlayByKey, hideMobileSearch, device } = this.props;

        if (device.isMobile) {
            hideMobileSearch();
        }
        toggleOverlayByKey(SEARCHSPRING_SEARCH_OVERLAY);
    }

    onFilterOptionClick(fieldName, selected, filterArray, searchState, history) {
        const { toggleOverlayByKey } = this.props;

        processCheckBoxClick(fieldName, selected, filterArray, searchState, history, false);
        toggleOverlayByKey(SEARCHSPRING_SEARCH_OVERLAY);
    }

    render() {
        const { search } = this.props;

        if (search.length < SEARCH_MIN_CHARS) {
            return null;
        }

        return (
            <SearchSpringOverlay
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchSpringOverlayContainer));
