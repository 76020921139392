import { BrowserDatabase as SourceBrowserDatabase } from 'SourceUtil/BrowserDatabase/BrowserDatabase';
import { LINKED_PRODUCTS } from 'Store/LinkedProducts/LinkedProducts.dispatcher';

/** @namespace ZnetPwa/Util/BrowserDatabase/BrowserDatabase */
export class BrowserDatabase extends SourceBrowserDatabase {
    /**
     * Save data to local storage
     * @param {Any} data The value to save to local storage
     * @param {String} location Name of the local storage
     * @param {Number} expiration Time to store entry (in seconds)
     * @return {Void}
     * @memberof BrowserDatabase
     */
    setItem(data, location, expiration) {
        // Temporary logic to clean up LINKED_PRODUCTS
        // as it takes all localStorage for some users.
        const linkedProducts = {
            upsell: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] }
        };

        localStorage.setItem(LINKED_PRODUCTS, JSON.stringify({
            data: linkedProducts,
            expiration,
            createdAt: Date.now()
        }));
        localStorage.setItem(location, JSON.stringify({
            data,
            expiration,
            createdAt: Date.now()
        }));
    }
}

export default new BrowserDatabase();
