/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import Gclid from '../route/Gclid';

export const addGclid = (originalMember) => [
    ...originalMember,
    {
        component: <Gclid />,
        position: 103
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addGclid
        }
    }
};
