/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SendGclid from '../util/SendGclid/SendGclid';

export const GTM_CART_PAGE_STEP = 1;
export const GTM_SHIPPING_STEP = 2;
export const GTM_BILLING_STEP = 3;

export const componentDidMount = (args, callback, instance) => {
    SendGclid.sendGclid(instance);

    return callback(...args);
};

// eslint-disable-next-line arrow-body-style
export const componentDidUpdate = (args, callback, instance) => {
    SendGclid.sendGclid(instance);

    return callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
