/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

import Breadcrumbs from 'Component/Breadcrumbs';
import CheckoutProgressBar from 'Component/CheckoutProgressBar';
import CookiePopup from 'Component/CookiePopup';
import DemoNotice from 'Component/DemoNotice';
import Footer from 'Component/Footer';
import Header from 'Component/Header';
import NavigationTabs from 'Component/NavigationTabs';
import NewVersionPopup from 'Component/NewVersionPopup';
import NotificationList from 'Component/NotificationList';
import UrlRewrites from 'Route/UrlRewrites';
import {
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CreateAccountPage,
    ForgotPasswordPage,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';

import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    SWITCH_ITEMS_TYPE
} from './Router.config';

export {
    CartPage,
    Checkout,
    CmsPage,
    HomePage,
    MyAccount,
    PasswordChangePage,
    ConfirmAccountPage,
    MenuPage,
    WishlistShared,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    withStoreRegex
};

export const BrandReviews = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "brand" */ 'Route/BrandReviews'));
export const FAQPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "faq" */ 'Route/FAQPage'));
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/SearchSpringSearchPage'));
export const PremiumCustomerPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/PremiumCustomer'));

/** @namespace ZnetPwa/Component/Router/Component/RouterComponent */
export class RouterComponent extends SourceRouter {
    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10
        }, {
            component: <CookiePopup />,
            position: 20
        }
    ];

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10
        },
        {
            component: <DemoNotice />,
            position: 15
        },
        {
            component: <Header />,
            position: 20
        },
        {
            component: <NavigationTabs />,
            position: 25
        },
        {
            component: <Breadcrumbs />,
            position: 30
        },
        {
            component: <Route render={ (props) => <CheckoutProgressBar { ...props } /> } />,
            position: 35
        },
        {
            component: <NewVersionPopup />,
            position: 40
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25
        },
        /** Used for powering SS landing page
         * Ref: https://searchspring.zendesk.com/hc/en-us/articles/115001067186-Landing-Pages
         */
        {
            component: <Route path={ withStoreRegex('/search') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 30
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55
        },
        {
            component: <Route path={ withStoreRegex('/:account*/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60
        },
        {
            component: <Route path={ withStoreRegex('/:account*/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61
        },
        {
            component: <Route path={ withStoreRegex('/:account*/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62
        },
        {
            component: <Route path={ withStoreRegex('/:account*/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63
        },
        {
            component: <Route path={ withStoreRegex('/:account*/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65
        },
        {
            component: <Route path={ withStoreRegex('/my-account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 70
        },
        {
            component: <Route path={ withStoreRegex('/forgot-password') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 71
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
            position: 82
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ (props) => <ProductComparePage { ...props } /> } />,
            position: 83
        },
        {
            component: <Route path={ withStoreRegex('/faq') } render={ (props) => <FAQPage { ...props } /> } />,
            position: 84
        },
        {
            component: <Route path={ withStoreRegex('/what-our-customers-say') } render={ (props) => <BrandReviews { ...props } /> } />,
            position: 85
        },
        {
            component: <Route path={ withStoreRegex('/premium-customers') } render={ (props) => <PremiumCustomerPage { ...props } /> } />,
            position: 86
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000
        }
    ];
}

export default RouterComponent;
