import PropTypes from 'prop-types';

import { ProductPriceContainer as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.container';
import {
    calculateFinalPrice,
    formatPrice
} from 'Util/Price';

import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX
} from './ProductPrice.config';
/** @namespace ZnetPwa/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPrice {
    static propTypes = {
        ...this.propTypes,
        special_price: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number]),
        unit_price: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number])
    };

    static defaultProps = {
        ...this.defaultProps,
        isSchemaRequired: false,
        special_price: null,
        unit_price: ''
    };

    getFormattedFinalPrice() {
        const {
            special_price,
            unit_price
        } = this.props;

        if (unit_price !== '' && unit_price !== 0) {
            if (special_price && special_price < unit_price) {
                return special_price;
            }

            return unit_price;
        }

        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue
                    } = {},
                    regular_price: {
                        value: regularPriceValue
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue
                    } = {}
                } = {}
            } = {},
            displayTaxInPrice
        } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            const finalPrice = calculateFinalPrice(
                discountPercentage,
                minimalPriceExclTaxValue,
                regularPriceExclTaxValue
            );

            return formatPrice(finalPrice, priceCurrency);
        }

        const finalPrice = calculateFinalPrice(discountPercentage, minimalPriceValue, regularPriceValue);

        return formatPrice(finalPrice, priceCurrency);
    }
}

export default ProductPriceContainer;
