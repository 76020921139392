import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MyAccountTabListItem from 'Component/MyAccountTabListItem';
import { tabMapType } from 'Type/Account';

import './MyAccountTabListDropdown.style';

/** @namespace ZnetPwa/Component/MyAccountTabListDropdown/Component/MyAccountTabListDropdownComponent */
export class MyAccountTabListDropdownComponent extends PureComponent {
    static propTypes = {
        tabMap: tabMapType.isRequired,
        onTabClick: PropTypes.func.isRequired,
        handleLogout: PropTypes.func.isRequired,
        expandMyAccountDropdown: PropTypes.func.isRequired,
        toggleExpandableContent: PropTypes.func.isRequired,
        isContentExpanded: PropTypes.bool.isRequired
    };

    renderTabListItem = (tabEntry) => {
        const [key] = tabEntry;
        const { onTabClick } = this.props;

        return (
            <MyAccountTabListItem
              key={ key }
              isActive={ false }
              changeActiveTab={ onTabClick }
              tabEntry={ tabEntry }
            />
        );
    };

    renderLogoutTab() {
        const { handleLogout } = this.props;

        return (
            <li
              key="logout"
              block="MyAccountTabListItem"
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ handleLogout }
                  role="link"
                >
                    { __('SIGN OUT') }
                </button>
            </li>
        );
    }

    render() {
        const {
            tabMap,
            isContentExpanded,
            expandMyAccountDropdown,
            toggleExpandableContent
        } = this.props;

        const tabs = [
            ...Object.entries(tabMap).map(this.renderTabListItem),
            this.renderLogoutTab()
        ];

        return (
            <div
              block="MyAccountTabListDropdown"
              mods={ { expanded: isContentExpanded } }
              onMouseOver={ expandMyAccountDropdown }
              onFocus={ expandMyAccountDropdown }
              onMouseOut={ toggleExpandableContent }
              onBlur={ toggleExpandableContent }
            >
                <ul>
                    { tabs }
                </ul>
            </div>
        );
    }
}

export default MyAccountTabListDropdownComponent;
