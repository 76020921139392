/* eslint-disable max-lines */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import { CONFIGURABLE } from 'Component/ProductSwatchPopup/ProductSwatchPopup.config';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import { ProductAttributeValue as SourceProductAttributeValue }
from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';
import { ProductType } from 'Type/ProductList';

import { WARRANTY_ATTRIBUTE } from '../AdditionalAttributes/AdditionalAttributes.config';
import {
    ATTRIBUTE_WISHLIST_BUTTON,
    STRING_ONLY_ATTRIBUTE_CODES
} from './ProductAttributeValue.config';

import './ProductAttributeValue.style';

/** @namespace ZnetPwa/Component/ProductAttributeValue/Component/ProductAttributeValueComponent */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    static propTypes = {
        ...this.propTypes,
        isFilterAttribute: PropTypes.bool,
        warrantyInfo: PropTypes.string,
        product: ProductType,
        isURL: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isFilterAttribute: false,
        warrantyInfo: '',
        isURL: false
    };

    renderImageValue(img, label, isAttributeWishlist = false) {
        const { isFormattedAsText, isSelected } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0
        };

        // If domain is passed from BE don't prepend anything
        if (isAttributeWishlist) {
            return (
                <>
                    <span
                      block="ProductAttributeValue"
                      elem="MediaImageLabel"
                    >
                        { label }
                    </span>
                    <img
                      block="ProductAttributeValue"
                      elem="Wishlist-Image"
                      src={ img.search('http://') !== -1 || img.search('https://') !== -1 ? img : `/media/attribute/swatch${img}` }
                      alt={ label }
                    />
                    <data
                      block="ProductAttributeValue"
                      elem="Image-Overlay"
                      value={ label }
                      title={ label }
                      style={ style }
                    />
                </>
            );
        }

        return (
            <>
                <img
                  block="ProductAttributeValue"
                  elem="Image"
                  src={ img.search('http://') !== -1 || img.search('https://') !== -1 ? img : `/media/attribute/swatch${img}` }
                  alt={ label }
                />
                <data
                  block="ProductAttributeValue"
                  elem="Image-Overlay"
                  value={ label }
                  title={ label }
                  style={ style }
                />
            </>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getOptionLabel(value) {
        const {
            attribute: {
                attribute_options
            },
            isFilterAttribute,
            isProductCountVisible
        } = this.props;

        if (attribute_options) {
            if (Array.isArray(attribute_options)) {
                const optionValues = attribute_options.filter(
                    (attribute) => attribute.value === value
                )[0];

                if (optionValues) {
                    if (!isProductCountVisible) {
                        return optionValues;
                    }

                    const { label, count = 0 } = optionValues;
                    return {
                        ...optionValues,
                        label: `${label} (${count})`
                    };
                }
            } else {
                const optionValues = attribute_options[value];
                if (optionValues) {
                    if (!isProductCountVisible) {
                        return optionValues;
                    }

                    const { label: itemLabel, count = 0 } = optionValues;

                    if (isFilterAttribute) {
                        return {
                            ...optionValues,
                            label: { itemLabel, count }
                        };
                    }

                    return {
                        ...optionValues,
                        label: `${itemLabel} (${count})`
                    };
                }
            }
        }

        return {};
    }

    renderSelectAttribute(isAttributeWishlist = false) {
        const { attribute: { attribute_value, attribute_code } } = this.props;
        const attributeOption = this.getOptionLabel(attribute_value);
        const { label, swatch_data } = attributeOption;

        if (!swatch_data || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderStringValue(label || __('N/A'));
        }

        const { value, type } = swatch_data;

        switch (type) {
        case '0':
            return this.renderStringValue(value, label);
        case '1':
            return this.renderColorValue(value, label);
        case '2':
            return isAttributeWishlist
                ? this.renderImageValue(value, label, true)
                : this.renderImageValue(value, label);
        default:
            return this.renderStringValue(label || __('N/A'));
        }
    }

    renderAttributeByType(isAttributeWishlist = false) {
        const { attribute: { attribute_type } } = this.props;

        switch (attribute_type) {
        case 'select':
            return isAttributeWishlist
                ? this.renderSelectAttribute(isAttributeWishlist)
                : this.renderSelectAttribute();
        case 'boolean':
            return this.renderBooleanAttribute();
        case 'text':
            return this.renderTextAttribute();
        case 'multiselect':
            return this.renderMultiSelectAttribute();
        case 'media_image':
            return this.renderImageAttribute();
        case 'textarea':
            return this.renderTextAreaAttribute();
        default:
            return this.renderPlaceholder();
        }
    }

    renderProductWishlistButton() {
        const {
            product,
            quantity,
            configurableVariantIndex,
            onProductValidationError,
            productOptionsData,
            groupedProductQuantity,
            isWishlistEnabled
        } = this.props;

        if (!isWishlistEnabled) {
            return null;
        }

        return (
            <ProductWishlistButton
              product={ product }
              quantity={ quantity }
              configurableVariantIndex={ configurableVariantIndex }
              onProductValidationError={ onProductValidationError }
              productOptionsData={ productOptionsData }
              groupedProductQuantity={ groupedProductQuantity }
              type={ ATTRIBUTE_WISHLIST_BUTTON }
            />
        );
    }

    renderCountValue(label, count) {
        const {
            attribute: {
                attribute_code
            } = {}
        } = this.props;

        return (
            <div
              block="ProductAttributeValue"
              elem="LabelAndCount"
            >
                { label }
                <span
                  block="ProductAttributeValue"
                  elem="Count"
                >
                    { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                    { attribute_code !== 'unit_price' ? ` (${ count })` : '' }
                </span>
            </div>
        );
    }

    renderDropdown(value) {
        const { isSelected, product: { type_id } = {} } = this.props;

        if (type_id !== CONFIGURABLE) {
            return (
                <Field
                  id={ value }
                  name={ value }
                  type="checkbox"
                  label={ value }
                  value={ value }
                  mix={ {
                      block: 'ProductAttributeValue',
                      elem: 'Text',
                      mods: { isSelected }
                  } }
                  checked={ isSelected }
                />
            );
        }

        return (
            <div
              block="ProductAttributeValue"
              elem="TextPills"
              mods={ { isSelected } }
            >
                { value }
            </div>
        );
    }

    renderStringValue(value, label) {
        const {
            isFormattedAsText,
            isSelected,
            warrantyInfo,
            isFilterAttribute,
            product: { type_id } = {},
            isURL
        } = this.props;
        const isSwatch = label;

        if (isFormattedAsText) {
            if (warrantyInfo === WARRANTY_ATTRIBUTE) {
                return (
                    <span
                      block="ProductAttributeValue"
                      elem="FormattedAsText"
                      mods={ { isSelected } }
                      title={ label }
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={ { __html: value } }
                    />
                );
            }

            if (isURL) {
                return (
                    <a href={ value } target="_blank" rel="noreferrer">
                        { __('Click here') }
                    </a>
                );
            }

            return label || value || __('N/A');
        }

        if (!isSwatch) {
            if (isFilterAttribute) {
                return (
                    <>
                    { this.renderCountValue(value?.itemLabel, value?.count) }
                    </>
                );
            }

            return this.renderDropdown(value);
        }

        if (type_id === CONFIGURABLE) {
            return (
                <div
                  block="ProductAttributeValue"
                  elem="TextPills"
                  mods={ { isSelected } }
                >
                    { label }
                </div>
            );
        }

        return (
            <span
              block="ProductAttributeValue"
              elem="String"
              mods={ { isSelected } }
              title={ label }
            >
                { value }
            </span>
        );
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            product,
            product: {
                type_id
            } = {},
            isSelected
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (product && type_id !== CONFIGURABLE) {
            return (
                <a
                  href={ href }
                  block="ProductAttributeValue"
                  mods={ { isSelected } }
                  onClick={ this.clickHandler }
                  mix={ mix }
                >
                    <div
                      block="ProductAttributeValue"
                      elem="Attribute-Wishlist"
                    >
                        { this.renderAttributeByType(true) }
                        { this.renderProductWishlistButton() }
                    </div>
                    { this.renderAttributeByType() }
                </a>
            );
        }

        return (
            <a
              href={ href }
              block="ProductAttributeValue"
              mods={ { isNotAvailable } }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
}

export default ProductAttributeValueComponent;
