import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import PropTypes from 'prop-types';

import ImageWebp from 'Component/ImageWebp';
import {
    Html as SourceHtml
} from 'SourceComponent/Html/Html.component';

/** @namespace ZnetPwa/Component/Html/Component/HtmlComponent */
export class HtmlComponent extends SourceHtml {
    static propTypes = {
        ...this.props,
        isBlog: PropTypes.bool.isRequired,
        fixImageSrc: PropTypes.func.isRequired
    };

    firstImageReplaced = false;

    /**
     * Replace img to React Images. Overriding source as we want to use webp images in picture tag
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    replaceImages({ attribs }) {
        const { fixImageSrc, isBlog } = this.props;
        const attributes = attributesToProps(attribs);

        if (attribs.src) {
            if (!this.firstImageReplaced) {
                this.firstImageReplaced = true;

                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                return <ImageWebp { ...fixImageSrc(attributes) } isLazy={ false } isBlog={ isBlog } />;
            }

            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            return <ImageWebp { ...fixImageSrc(attributes) } isBlog={ isBlog } />;
        }

        return null;
    }
}

export default HtmlComponent;
