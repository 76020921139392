import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MENU } from 'Component/Header/Header.config';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

import MobileMenuComponent from './MobileMenu.component';

import './MobileMenu.style';

/** @namespace ZnetPwa/Component/MobileMenu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleOverlayByKey: (MENU) => dispatch(toggleOverlayByKey(MENU))
});

/** @namespace ZnetPwa/Component/MobileMenu/Container/MobileMenuContainer */
export class MobileMenuContainer extends PureComponent {
    static propTypes = {
        toggleOverlayByKey: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleMobileMenuClick: this.handleMobileMenuClick.bind(this)
    };

    handleMobileMenuClick() {
        const { toggleOverlayByKey } = this.props;
        toggleOverlayByKey(MENU);
    }

    render() {
        return (
            <MobileMenuComponent
              { ...this.containerFunctions }
            />
        );
    }
}

/** @namespace ZnetPwa/Component/MobileMenu/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuContainer);
