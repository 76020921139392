import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace ZnetPwa/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    getRevokeAccountToken() {
        return new Field('revokeCustomerToken')
            .addFieldList(this.getRevokeAccountTokenFields());
    }

    getRevokeAccountTokenFields() {
        return [
            'result'
        ];
    }

    /**
     * Get SignIn mutation
     * @param {{email: String, password: String, captcha_token: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getSignInMutation(options) {
        const { email, password, captcha_token = null } = options;

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addArgument('captcha_token', 'String', captcha_token)
            .addField('token');
    }

    /**
     * Get ForgotPassword mutation
     * @param {{email: String, captcha_token: String}} options
     * @returns {Field}
     * @memberof MyAccount
     */
    getForgotPasswordMutation(options) {
        const { email, captcha_token = null } = options;

        return new Field('forgotPassword')
            .addArgument('email', 'String!', email)
            .addArgument('captcha_token', 'String', captcha_token)
            .addField('status');
    }

    // Adds company field to default query
    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'company',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            this._getRegionField()
        ];
    }

    // Added customerGroupName field
    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'customerGroupName',
            this._getAddressesField()
        ];
    }
}

export default new MyAccountQuery();
