/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export class ConfigQueryPlugin {
    /**
     * Get Datadog enabled flag
     * @returns [Field]
     */
    _aroundGetStoreConfigFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'datadog_enabled',
        'datadog_application_id',
        'datadog_client_token',
        'datadog_site',
        'datadog_service',
        'datadog_env',
        'datadog_version',
        'datadog_session_sample_rate',
        'datadog_session_replay_sample_rate',
        'datadog_track_user_interactions',
        'datadog_track_resources',
        'datadog_track_long_tasks',
        'datadog_forward_errors_to_logs',
        'datadog_default_privacy_level',
        'datadog_allowed_tracing_urls'
    ];
}

export const { _aroundGetStoreConfigFields } = new ConfigQueryPlugin();

export const config = {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: _aroundGetStoreConfigFields
        }
    }
};

export default config;
