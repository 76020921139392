/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { datadogRum } from '@datadog/browser-rum';

import { DataContainer as SourceDataContainer } from 'SourceUtil/Request/DataContainer';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { hash } from 'Util/Request/Hash';
import getStore from 'Util/Store';

import { ONE_MONTH_IN_SECONDS } from './QueryDispatcher';

/** @namespace ZnetPwa/Util/Request/DataContainer/DataContainer */
export class DataContainer extends SourceDataContainer {
    fetchData(rawQueries, onSucces = () => {}, onError = () => {}, isPrerenderFlagUpdated = true) {
        const preparedQuery = prepareQuery(rawQueries);
        const { query, variables } = preparedQuery;
        const queryHash = hash(query + JSON.stringify(variables));
        const state = getStore().getState();
        const {
            datadog_enabled = false
        } = state.ConfigReducer;

        if (!window.dataCache) {
            window.dataCache = {};
        }

        if (window.dataCache[queryHash]) {
            onSucces(window.dataCache[queryHash]);
            return;
        }

        this.promise = makeCancelable(
            executeGet(preparedQuery, this.dataModelName, ONE_MONTH_IN_SECONDS, isPrerenderFlagUpdated)
        );

        this.promise.promise.then(
            /** @namespace ZnetPwa/Util/Request/DataContainer/then */
            (response) => {
                window.dataCache[queryHash] = response;
                onSucces(response);
            },
            /** @namespace ZnetPwa/Util/Request/DataContainer/then */
            (err) => {
                onError(err);

                if (datadog_enabled) {
                    const message = err?.[0]?.debugMessage
                    || err?.[0]?.message
                    || 'Error in DataContainer';
                    const errorMessage = new Error(message);
                    datadogRum.addError(errorMessage);
                }
            }
        );
    }
}

export default DataContainer;
