/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_PRERENDER = 'UPDATE_PRERENDER';

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace ZnetPwa/Store/Prerender/Action/updatePrerender */
export const updatePrerender = (prerenderDelayAction) => ({
    type: UPDATE_PRERENDER,
    prerenderDelayAction
});
