/* eslint-disable max-lines */
/*
 * @category  Znet
 * @author    Vladislavs Jerins <info@scandiweb.com>
 */

import CartProductListQuery from 'Query/CartProductList.query';
import {
    CartQuery as SourceCartQuery
} from 'SourceQuery/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace ZnetPwa/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    /**
     * Get remove cart item mutation
     *
     * @param   {string} item_id
     * @param   {string} quoteId
     * @returns {Field}
     * @note    Add customization to improve remove configurable item from cart performance
     * @link    https://sepoy.atlassian.net/browse/ZFR-1810
     */
    getRemoveCartItemMutation(item_id, quoteId) {
        const mutation = new Field('removeCartItem')
            .addArgument('item_id', 'Int!', item_id)
            .addFieldList(this._getCartConfigurableFields(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    /**
     * Get save cart item mutation
     *
     * @param   {object} product
     * @param   {string} quoteId
     * @returns {Field}
     * @note    Add customization to improve add configurable item to cart performance
     * @link    https://sepoy.atlassian.net/browse/ZFR-1777
     */
    getSaveCartItemMutation(product, quoteId) {
        /**
         * @note If it is not configurable product, execute normal flow
         */
        if (product?.product_type !== 'configurable') {
            return super.getSaveCartItemMutation(product, quoteId);
        }

        /**
         * @note Add custom fields to add to cart mutation for configurable product to improve request performance
         */
        const mutation = new Field('saveCartItem')
            .addArgument('cartItem', 'CartItemInput!', product)
            .addFieldList(this._getCartConfigurableFields(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    _getCustomerAddressFields() {
        return [
            'firstname',
            'lastname',
            'email',
            'company',
            'telephone',
            'country_id',
            'street',
            'city',
            'postcode',
            'region',
            'region_id'
        ];
    }

    _getCustomerAddressField() {
        return new Field('quote_address')
            .addFieldList(this._getCustomerAddressFields());
    }

    _getCartTotalsFields() {
        return [
            this._getCustomerAddressField(),
            'discount_labels',
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'is_virtual',
            'applied_rule_ids',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'shipping_method',
            this._getCartItemsField(),
            this._getAppliedTaxesField()
        ];
    }

    /**
     * Get cart configurable fields
     *
     * @param   {string} quoteId
     * @returns {*[]}
     * @private
     * @note    Customization to improve add to cart/remove from cart mutation request
     * @note    Instead of inherit and use the cart query of the parent class,
     *          it is required to create a custom one with custom fields to improve
     *          performance
     * @note    Another alternative could have been to update the `_getProductField()` method,
     *          but this approach could affect other parts of the site that may require fields
     *          that are removed
     * @see     CartQuery.getCartQuery()
     * @see     CartQuery.getSaveCartItemMutation()
     * @see     CartQuery._getProductField()
     * @see     CartDispatcher.changeItemQty()
     * @see     CartDispatcher.addProductToCart()
     * @link    https://sepoy.atlassian.net/browse/ZFR-1777
     * @link    https://sepoy.atlassian.net/browse/ZFR-1810
     */
    _getCartConfigurableFields(quoteId) {
        const query = this._initCartConfigurableQuery(quoteId);
        query.addFieldList(this._getCartConfigurableFieldList());

        return [query];
    }

    /**
     * Init query for cart mutations
     *
     * @param   {string} quoteId
     * @returns {Field}
     * @private
     */
    _initCartConfigurableQuery(quoteId) {
        const query = new Field('getCartForCustomer').setAlias('cartData');
        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', quoteId);
        }

        return query;
    }

    /**
     * Get configurable field list for cart mutations
     *
     * @returns {Array}
     * @private
     */
    _getCartConfigurableFieldList() {
        return [
            'subtotal',
            'subtotal_incl_tax',
            'items_qty',
            'tax_amount',
            'grand_total',
            'discount_amount',
            'quote_currency_code',
            'subtotal_with_discount',
            'coupon_code',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'is_virtual',
            'applied_rule_ids',
            'shipping_amount',
            'shipping_incl_tax',
            'shipping_tax_amount',
            'shipping_method',
            this._getCartConfigurableItemsFieldList(),
            this._getAppliedTaxesField()
        ];
    }

    /**
     * Get configurable items field list for cart mutations
     *
     * @returns {Field}
     * @private
     */
    _getCartConfigurableItemsFieldList() {
        return new Field('items').addFieldList([
            'qty',
            'sku',
            'price',
            'item_id',
            'row_total',
            'row_total_incl_tax',
            'tax_amount',
            'tax_percent',
            'discount_amount',
            'discount_percent',
            this._getCustomizableOptionsFields(),
            this._getDownloadableLinksField(),
            this._getBundleOptionsField(),
            this._getCartConfigurableItemsProductFieldList()
        ]);
    }

    /**
     * Get product field list of configurable item for cart mutations
     *
     * @returns {Field}
     * @private
     * @note    Add customization to use `s_attributes_v2` field instead of `s_attributes`
     *          to improve performance
     * @see     CartQuery._getProductField()
     */
    _getCartConfigurableItemsProductFieldList() {
        CartProductListQuery.options.isForLinkedProducts = true;

        const productQuery = new Field('product')
            .addFieldList(CartProductListQuery._getProductInterfaceFields(false, true));

        CartProductListQuery.options.isForLinkedProducts = false;

        return productQuery;
    }
}

export default new CartQuery();
