export * from 'SourceUtil/Price/Price';

/** @namespace ZnetPwa/Util/Price/formatPrice */
export const formatPrice = (price, _) => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    const formattedPrice = new Intl.NumberFormat(language, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(price);

    return `$${formattedPrice}`;
};
