import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { GrFormClose } from 'react-icons/gr';

import ClickOutside from 'Component/ClickOutside';
import { MixType } from 'Type/Common';

import './Tooltip.style';

/** @namespace ZnetPwa/Component/Tooltip/Component/TooltipComponent */
export class TooltipComponent extends PureComponent {
    static propTypes = {
        tooltipText: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        onClickOutside: PropTypes.func.isRequired,
        render: PropTypes.func.isRequired,
        isShowing: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        mix: MixType,
        children: PropTypes.node.isRequired,
        preventClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        mix: {}
    };

    renderTextTooltip() {
        const {
            tooltipText, mix, onClick, isShowing, onClickOutside, preventClick
        } = this.props;

        return (
          <div
            block="Tooltip"
            mix={ mix }
          >
                  <BsInfoCircle
                    block="Tooltip"
                    elem="InfoIcon"
                    onClick={ onClick }
                  />
                  { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */ }
                  <div
                    block="Tooltip"
                    elem="Wrapper"
                    mods={ { isShowing } }
                    onClick={ preventClick }
                    onKeyDown={ preventClick }
                  >
                    <GrFormClose
                      block="Tooltip"
                      elem="CloseIcon"
                      onClick={ onClickOutside }
                      size={ 18 }
                    />
                    <div
                      block="Tooltip"
                      elem="Text"
                    >
                      { tooltipText }
                    </div>
                  </div>
          </div>
        );
    }

    renderContentTooltip() {
        const {
            title, render, mix, onClick, isShowing
        } = this.props;

        return (
          <div
            block="Tooltip"
            mix={ mix }
          >
            <BsInfoCircle
              block="Tooltip"
              elem="InfoIcon"
              onClick={ onClick }
            />
            <div
              block="Tooltip"
              elem="Wrapper"
              mods={ { isShowing } }
            >
                <p
                  block="Tooltip"
                  elem="Title"
                >
                    { title }
                </p>
                <div
                  block="Tooltip"
                  elem="Content"
                >
                  { render }
                </div>
            </div>
          </div>
        );
    }

    renderChildrenTooltip() {
        const {
            tooltipText,
            mix,
            isShowing,
            children
        } = this.props;

        return (
            <div
              block="Tooltip"
              elem="Element"
              mix={ mix }
            >
                    <div
                      block="Tooltip"
                      elem="Wrapper"
                      mods={ { isShowing } }
                    >
                      <div
                        block="Tooltip"
                        elem="Text"
                      >
                        { tooltipText }
                      </div>
                    </div>
                    { children }
            </div>
        );
    }

    render() {
        const {
            tooltipText,
            onClickOutside,
            children
        } = this.props;

        if (children && Object.keys(children)?.length) {
            return (
              <ClickOutside onClick={ onClickOutside }>
                { this.renderChildrenTooltip() }
              </ClickOutside>
            );
        }

        if (tooltipText) {
            return (
              <ClickOutside onClick={ onClickOutside }>
                { this.renderTextTooltip() }
              </ClickOutside>
            );
        }

        return (
            <ClickOutside onClick={ onClickOutside }>
              { this.renderContentTooltip() }
            </ClickOutside>
        );
    }
}

export default TooltipComponent;
