export class ConfigQueryPlugin {
    /**
     * Get Gclid extension enabled flag and cookie lifetime
     * @returns [Field]
     */
    _aroundGetStoreConfigFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'znetSyncGclidServiceStatus',
        'znetSyncGclidLifetime'
    ];
}

export const { _aroundGetStoreConfigFields } = new ConfigQueryPlugin();

export const config = {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: _aroundGetStoreConfigFields
        }
    }
};

export default config;
