import { ImageContainer as SourceImage } from 'SourceComponent/Image/Image.container';

/** @namespace ZnetPwa/Component/Image/Container/ImageContainer */
export class ImageContainer extends SourceImage {
    _getObjectFit() {
        const {
            mix: {
                block
            }
        } = this.props;

        if (block === 'ProductGalleryThumbnailImage') {
            return { objectFit: 'cover' };
        }

        return { objectFit: 'contain' };
    }

    _getStyle() {
        const { style } = this.props;
        return { ...this._getCorrectSize(), ...this._getObjectFit(), ...style };
    }
}

export default ImageContainer;
