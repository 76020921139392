/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { CONFIGURABLE } from 'Component/ProductSwatchPopup/ProductSwatchPopup.config';

import Event, {
    EVENT_GA4_VIEW_ITEM_LIST,
    EVENT_GTM_GENERAL_INIT,
    EVENT_GTM_IMPRESSIONS_CROSS_SELL,
    EVENT_GTM_IMPRESSIONS_HOME,
    EVENT_GTM_IMPRESSIONS_LINKED,
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH,
    EVENT_GTM_IMPRESSIONS_WISHLIST
} from '../../../util/Event';
import { getCurrentVariantIndexFromFilters } from '../../../util/Product';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';

/**
 * Website places, from where was received event data
 *
 * @type {string}
 */
export const PLP_IMPRESSIONS = 'catalog';
export const HOME_IMPRESSIONS = 'home';
export const WISHLIST_IMPRESSIONS = 'wishlist';
export const CHECKOUT_CROSS_SELL_IMPRESSIONS = 'checkout_cross_sell';
export const SEARCH_IMPRESSIONS = 'search';
export const RECOMMENDED_IMPRESSIONS = 'recommended';

export const GA4_VIEW_ITEM_LIST = 'ga4_view_items';

/**
 * Constants
 *
 * @type {number}
 */
export const SPAM_PROTECTION_DELAY = 2000;
export const PRODUCT_IMPRESSION_COUNT = 36;
export const PRODUCT_IMPRESSION_CHUNK_SIZE = 12;
export const EVENT_HANDLE_DELAY = 700;

/**
 * GTM PWA Impression Event
 *
 * Called when customer see product list
 * On: Home, PLP, WishList, Up-Sell, Cross-Sell, Accessories(related things on PDP)
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace, @scandipwa/scandipwa-guidelines/derived-class-names
export class Impression extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    /**
     * Bind PWA event handling
     */
    bindEvent() {
        // GA4 PLP, search
        Event.observer(EVENT_GA4_VIEW_ITEM_LIST, ({ items }) => {
            this.handle(GA4_VIEW_ITEM_LIST, items);
        });

        // PLP
        Event.observer(EVENT_GTM_IMPRESSIONS_PLP, ({ items, filters, category }) => {
            this.handle(PLP_IMPRESSIONS, items, filters, category);
        });

        // Home
        Event.observer(EVENT_GTM_IMPRESSIONS_HOME, ({ items, filters }) => {
            this.handle(HOME_IMPRESSIONS, items, filters);
        });

        // Checkout Cross-sell
        Event.observer(EVENT_GTM_IMPRESSIONS_CROSS_SELL, ({ items }) => {
            this.handle(CHECKOUT_CROSS_SELL_IMPRESSIONS, items);
        });

        // Wishlist
        Event.observer(EVENT_GTM_IMPRESSIONS_WISHLIST, ({ items }) => {
            this.handle(WISHLIST_IMPRESSIONS, items);
        });

        // Search
        Event.observer(EVENT_GTM_IMPRESSIONS_SEARCH, ({ items }) => {
            this.handle(SEARCH_IMPRESSIONS, items);
        });

        // Recommended
        Event.observer(EVENT_GTM_IMPRESSIONS_LINKED, ({ items }) => {
            this.handle(RECOMMENDED_IMPRESSIONS, items);
        });

        // General
        Event.observer(EVENT_GTM_GENERAL_INIT, () => {
            this.cleanStorage();
        });
    }

    static getCategory(product) {
        const {
            categories,
            flooring_type = null,
            type_id
        } = product;

        if (type_id === CONFIGURABLE) {
            if (categories?.length) {
                return categories[0]?.name;
            }
        }

        return flooring_type?.[0];
    }

    /**
     * Get GA4 product detail
     * Ref: https://sepoy.atlassian.net/browse/ZFR-1319
     * @param items
     *
     * @return {{ price: number, item_name: string, item_variant: string, item_id: string, item_category: string, item_brand: string }[]}
     */
    getGA4Products(items) {
        return items.reduce((acc, product, index) => {
            const {
                name: item_name,
                sku: item_id,
                regular_price: price,
                brand: item_brand,
                collection_color = null
            } = product;
            const item_category = Impression.getCategory(product);
            const ecommerceProductObject = {
                item_name,
                item_id,
                price,
                item_brand,
                index: index + 1, // Google starts from 1
                id: item_id, // Google Ads additional variable. The same as item_id
                google_business_vertical: 'retail' // Google Ads additional constant
            };

            if (collection_color !== null) {
                ecommerceProductObject.item_variant = collection_color;
            }

            if (item_category !== 'false' && item_category !== undefined) {
                ecommerceProductObject.item_category = item_category;
            }

            return (
                [
                    ...acc,
                    ecommerceProductObject
                ]);
        }, []);
    }

    /**
    * Handle view_item_list_ga4 for GA4
    */
    ga4Handler(products) {
        const items = this.getGA4Products(products);
        const itemsWithQty = items.map((item) => ({ ...item, quantity: 1 }));
        const ecommerce = {
            items: itemsWithQty
        };

        // GA4 data push
        // Ref: https://sepoy.atlassian.net/browse/ZFR-1319
        this.pushGA4EventData({ ecommerce });
    }

    /**
     * Handle Impressions
     *
     * @param productCollectionType product event type
     * @param products Product list
     * @param filters Category filters
     */
    handler(productCollectionType = PLP_IMPRESSIONS, products = [], filters = {}, category = {}) {
        if (productCollectionType === GA4_VIEW_ITEM_LIST) {
            this.ga4Handler(products);
            return;
        }

        const impressions = this.getImpressions(productCollectionType, products, filters, category);
        const storage = this.getStorage();
        const impressionUID = this.getImpressionUID(impressions);

        if (!impressions
            || Object.values(impressions).length === 0
            || this.spamProtection(SPAM_PROTECTION_DELAY, `${ productCollectionType }_${ impressionUID }`)
        ) {
            return;
        }

        if (!storage.impressions) {
            storage.impressions = [];
        }
        storage.impressions.push(...impressions);
        this.setStorage(storage);

        // Chunk data to small parts
        // eslint-disable-next-line fp/no-loops, fp/no-let
        for (let offset = 0; offset < impressions.length; offset += PRODUCT_IMPRESSION_CHUNK_SIZE) {
            this.pushEventData({
                ecommerce: {
                    currencyCode: this.getCurrencyCode(),
                    impressions: impressions.slice(offset, offset + PRODUCT_IMPRESSION_CHUNK_SIZE)
                }
            });
        }
    }

    /**
     * Get impressions
     *
     * @param productCollectionType
     * @param products
     * @param filters
     *
     * @return {{price: string, name: string, variant: string, id: string, position: number, list: string, category: string, brand: string}[]}
     */
    getImpressions(productCollectionType = PLP_IMPRESSIONS, products, filters, category) {
        const { name: categoryName = '', url_path = '' } = category;
        const productCollection = this.getProductCollection(productCollectionType, products);
        const productCount = Object.values(productCollection || []).length;

        const offset = PRODUCT_IMPRESSION_COUNT - productCount < 0
            ? Math.abs(PRODUCT_IMPRESSION_COUNT - productCount)
            : 0;

        return Object.values(productCollection || [])
            .slice(-PRODUCT_IMPRESSION_COUNT) // Last from list
            .filter((product) => Object.values(product).length)
            .map((product, index) => {
                const configurableVariantIndex = getCurrentVariantIndexFromFilters(product, filters);

                const productData = productCollectionType === WISHLIST_IMPRESSIONS
                    ? ProductHelper.getItemData(product)
                    : ProductHelper.getProductData({ ...product, configurableVariantIndex, category: url_path });

                return {
                    ...productData,
                    position: offset + index + 1,
                    list: this.getProductCollectionList(productCollectionType, product, categoryName)
                };
            });
    }

    /**
     * Get collection of products
     *
     * @param productCollectionType
     * @param params
     *
     * @return {Array}
     */
    getProductCollection(productCollectionType = PLP_IMPRESSIONS, products) {
        switch (productCollectionType) {
        case PLP_IMPRESSIONS:
        case WISHLIST_IMPRESSIONS:
        case HOME_IMPRESSIONS:
        case SEARCH_IMPRESSIONS:
        case RECOMMENDED_IMPRESSIONS:
        case CHECKOUT_CROSS_SELL_IMPRESSIONS:
            return products || [];
        default:
            return [];
        }
    }

    /**
     * Get product collection list name
     *
     * @param productCollectionType
     * @param product
     *
     * @return {string}
     */
    getProductCollectionList(productCollectionType = PLP_IMPRESSIONS, product, categoryName = '') {
        switch (productCollectionType) {
        case HOME_IMPRESSIONS:
            return 'Homepage';
        case RECOMMENDED_IMPRESSIONS:
            return 'Recommended';
        case SEARCH_IMPRESSIONS:
            return 'Search results';
        case WISHLIST_IMPRESSIONS:
            return 'Wishlist';
        case CHECKOUT_CROSS_SELL_IMPRESSIONS:
            return 'Cross sell impressions';
        case PLP_IMPRESSIONS:
            return categoryName
                ? `PLP - ${ categoryName }`
                : 'PLP';
        default:
            return ProductHelper.getList(product);
        }
    }

    /**
     * Get provided impression UID
     *
     * @param impression
     * @return {string}
     */
    getImpressionUID(impression = []) {
        return impression.reduce((acc, { id }) => `${ acc }_${ id }`, '');
    }
}

export default Impression;
