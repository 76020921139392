/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import { VIRTUAL } from 'Component/CartPopup/CartPopup.config';
import { RUGS } from 'Component/ProductActions/ProductActions.config';
import { ACCESSORIES_ATTRIBUTE_SET_NAME } from 'Route/ProductPage/ProductPage.config';
import {
    CartItemContainer as SourceCartItem,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CartItem/CartItem.container';
import { updateShippingFields } from 'Store/Checkout/Checkout.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { TotalsType } from 'Type/MiniCart';
import { isSignedIn } from 'Util/Auth';
import { CONFIGURABLE } from 'Util/Product';
import {
    getErrorMessage
} from 'Util/Request';
import { objectToUri } from 'Util/Url';

import { ACCESSORIES_POPUP } from './CartItem.config';

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace ZnetPwa/Component/CartItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cartTotals: state.CartReducer.cartTotals,
    shippingFields: state.CheckoutReducer.shippingFields
});

/** @namespace ZnetPwa/Component/CartItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    addProductToWishlist: (wishlistItem) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addItemToWishlist(dispatch, wishlistItem)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showAccessoriesPopup: (id) => dispatch(showPopup(ACCESSORIES_POPUP + id)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    updateShippingFields: (fields) => dispatch(updateShippingFields(fields))
});

/** @namespace ZnetPwa/Component/CartItem/Container/CartItemContainer */
export class CartItemContainer extends SourceCartItem {
    static propTypes = {
        ...this.propTypes,
        toggleIsCartItemLoading: PropTypes.func,
        cartTotals: TotalsType.isRequired,
        updateShippingFields: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        addProductToWishlist: PropTypes.func.isRequired,
        showAccessoriesPopup: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        shippingFields: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.bool
        ])
    };

    static defaultProps = {
        ...this.defaultProps,
        toggleIsCartItemLoading: () => {}
    };

    state = {
        ...this.state,
        cartItemQuantity: 1,
        isConfirmQtyVisible: false,
        isAddingAccessory: false,
        isCustomQtyInputVisible: false,
        activeQtyInput: -1
    };

    qtyInputRef = createRef();

    containerFunctions = {
        ...this.containerFunctions,
        addProductToWishlist: this.addProductToWishlist.bind(this),
        handleBulkRemove: this.handleBulkRemove.bind(this),
        closePopup: this.closePopup.bind(this),
        onConfirmClick: this.onConfirmClick.bind(this),
        onCartItemAddToCart: this.onCartItemAddToCart.bind(this),
        hideConfirmQty: this.hideConfirmQty.bind(this),
        onConfirmQtyChange: this.onConfirmQtyChange.bind(this),
        toggleCustomQtyInput: this.toggleCustomQtyInput.bind(this),
        onCartItemAddToCartClick: this.onCartItemAddToCartClick.bind(this)
    };

    componentDidMount() {
        const {
            item: {
                qty
            } = {}
        } = this.props;

        if (qty) {
            this.setState({ cartItemQuantity: qty });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            item: {
                qty
            } = {}
        } = this.props;
        const {
            item: {
                qty: prevQty
            } = {}
        } = prevProps;

        if (prevQty !== qty) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ cartItemQuantity: qty });
        }
    }

    containerProps = () => {
        const {
            cartItemQuantity,
            isAddingAccessory,
            isConfirmQtyVisible,
            isCustomQtyInputVisible,
            activeQtyInput
        } = this.state;
        const {
            product,
            isCartPopup
        } = this.props;

        return {
            product,
            activeQtyInput,
            cartItemQuantity,
            isCartPopup,
            isAddingAccessory,
            isConfirmQtyVisible,
            isCustomQtyInputVisible,
            qtyInputRef: this.qtyInputRef,
            linkTo: this._getProductLinkTo(),
            thumbnail: this._getProductThumbnail(),
            minSaleQuantity: this.getMinQuantity(),
            maxSaleQuantity: this.getMaxQuantity(),
            isProductInStock: this.productIsInStock(),
            isVirtual: this.isVirtual(),
            weight: this.getWeight()
        };
    };

    isVirtual() {
        const {
            item: {
                product: {
                    type_id
                } = {}
            }
        } = this.props;

        return type_id === VIRTUAL;
    }

    addProductToWishlist() {
        const {
            addProductToWishlist,
            showNotification,
            item: {
                product: {
                    sku,
                    quantity,
                    product_option
                }
            }
        } = this.props;

        if (!isSignedIn()) {
            return showNotification('info', __('You must login or register to add items to your wishlist.'));
        }

        return addProductToWishlist({ sku, product_option, quantity });
    }

    closePopup() {
        const { hideActiveOverlay } = this.props;

        hideActiveOverlay();
    }

    onCartItemAddToCartClick(id, value) {
        this.setState({
            activeQtyInput: id
        }, () => {
            this.onCartItemAddToCart(value);
            this.setState({ cartItemQuantity: 1 });
        });
    }

    onConfirmQtyChange(value) {
        const newQty = value === '' ? value : +value;

        this.showConfirmQty();
        this.setState({ cartItemQuantity: newQty });
    }

    showConfirmQty() {
        this.setState({
            isConfirmQtyVisible: true
        });
    }

    onConfirmClick() {
        const { cartItemQuantity } = this.state;

        this.onCartItemAddToCart(cartItemQuantity);
        this.hideConfirmQty(false, true);
    }

    toggleCustomQtyInput() {
        const {
            item: {
                product: {
                    id
                } = {}
            } = {}
        } = this.props;
        const { isCustomQtyInputVisible } = this.state;

        this.setState({
            isCustomQtyInputVisible: !isCustomQtyInputVisible
        }, () => {
            // Fix for: QTY input with confirm options required 2 clicks to get focused
            // ref was not working
            if (!isCustomQtyInputVisible) {
                document.getElementById(`AddToCartQty-custom-${ id }`).focus();
            }
        });
    }

    handleRemoveItem() {
        const {
            toggleIsCartItemLoading
        } = this.props;

        this.setState({ isLoading: true }, () => {
            toggleIsCartItemLoading(true);
            this.hideLoaderAfterPromise(this.removeProductAndUpdateCrossSell());
        });
    }

    hideConfirmQty(_, isQtyChanged = false) {
        const {
            item: {
                qty
            } = {}
        } = this.props;

        this.toggleCustomQtyInput();
        if (!isQtyChanged) {
            this.setState({
                isConfirmQtyVisible: false,
                cartItemQuantity: qty
            });
        } else {
            this.setState({
                isConfirmQtyVisible: false
            });
        }
    }

    onCartItemAddToCart(value) {
        this.setState({ cartItemQuantity: +value, isAddingAccessory: true },
            () => this.handleCartItemAddToCartClick());
    }

    async handleCartItemChangeQuantity(quantity, item) {
        const { changeItemQty, showNotification } = this.props;
        const { item_id, sku } = item;

        await changeItemQty({ item_id, quantity, sku }).then(
            /** @namespace ZnetPwa/Component/CartItem/Container/changeItemQty/then */
            () => {
                this.setState({ isAddingAccessory: false });
                showNotification('success', __('Product quantity changed!'));
            },
            /** @namespace ZnetPwa/Component/CartItem/Container/changeItemQty/then */
            () => {
                this.setState({ isAddingAccessory: false });
            }
        );
    }

    async handleRemoveCartItem(item) {
        const { removeProduct, showNotification } = this.props;

        await removeProduct(item.item_id).then(
            /** @namespace ZnetPwa/Component/CartItem/Container/removeProduct/then */
            () => {
                this.setState({ isAddingAccessory: false });
                showNotification('success', __('Product removed from cart!'));
            },
            /** @namespace ZnetPwa/Component/CartItem/Container/removeProduct/then */
            (error) => {
                this.setState({ isAddingAccessory: false });
                showNotification('error', getErrorMessage(error));
            }
        );
    }

    async handleCartItemAddToCartClick() {
        const {
            addProduct,
            item: {
                product,
                sku
            } = {},
            cartTotals: {
                items
            } = {},
            showNotification
        } = this.props;
        const productOptionsData = {
            requiredOptions: []
        };

        const { cartItemQuantity } = this.state;
        const filteredItems = items?.filter((item) => item.sku === sku);

        if (filteredItems?.length) {
            const item = filteredItems[0];

            if (cartItemQuantity === 0) {
                this.handleRemoveCartItem(item);
                // this.setState({ isAddingAccessory: false });
                return;
            }

            this.handleCartItemChangeQuantity(cartItemQuantity, item);
        } else {
            addProduct({
                product,
                quantity: cartItemQuantity,
                productOptionsData
            }).then(
                /** @namespace ZnetPwa/Component/CartItem/Container/addProduct/then */
                () => {
                    this.setState({ isAddingAccessory: false });
                    showNotification('success', __('Product added to cart!'));
                },
                /** @namespace ZnetPwa/Component/CartItem/Container/addProduct/then */
                () => this.setState({ isAddingAccessory: false, cartItemQuantity: 0 })
            );
        }
    }

    handleBulkRemove() {
        this.setState({ isLoading: true }, () => {
            this.hideLoaderAfterPromise(this.bulkRemoveProductAndUpdateCrossSell());
        });
    }

    async bulkRemoveProductAndUpdateCrossSell() {
        const {
            removeProduct,
            updateCrossSellProducts,
            updateCrossSellsOnRemove,
            item: { item_id }
        } = this.props;

        const result = await removeProduct(item_id);

        if (result && updateCrossSellsOnRemove) {
            await updateCrossSellProducts(result.items);
        }

        return result;
    }

    async removeProductAndUpdateCrossSell() {
        const {
            toggleIsCartItemLoading,
            removeProduct,
            updateCrossSellProducts,
            updateCrossSellsOnRemove,
            item: { item_id },
            shippingFields,
            updateShippingFields,
            showNotification
        } = this.props;

        const result = await removeProduct(item_id).then(
            /** @namespace ZnetPwa/Component/CartItem/Container/removeProduct/then */
            (data) => {
                toggleIsCartItemLoading(false);
                showNotification('success', __('Product removed from cart!'));

                return data;
            },
            /** @namespace ZnetPwa/Component/CartItem/Container/removeProduct/then */
            (error) => {
                toggleIsCartItemLoading(false);
                showNotification('error', getErrorMessage(error));

                return null;
            }
        );

        if (result && updateCrossSellsOnRemove) {
            await updateCrossSellProducts(result.items);
        }

        /** Reset selected shipping method and store address as
         * they might not exist because product of another brand
         * can be added.
         */
        if (result.items_qty === 0) {
            const {
                // eslint-disable-next-line no-unused-vars
                selectedShippingMethod,
                // eslint-disable-next-line no-unused-vars
                selectedStoreAddress,
                ...shippingFieldsData
            } = shippingFields;

            updateShippingFields(shippingFieldsData);
        }

        return result;
    }

    getWeight() {
        const {
            item: {
                qty,
                product: {
                    dynamicAttributes: {
                        attribute_set_name,
                        weight: flooringWeight = 0
                    } = {},
                    variants = []
                } = {},
                sku
            } = {}
        } = this.props;

        if (attribute_set_name === ACCESSORIES_ATTRIBUTE_SET_NAME) {
            return 0;
        } if (attribute_set_name === RUGS) {
            const currentVariant = variants?.filter((variant) => variant?.sku === sku)?.[0];
            if (currentVariant) {
                const {
                    dynamicAttributes: {
                        weight = 0
                    } = {}
                } = currentVariant;

                // With + converting to number
                return (qty * +weight).toFixed(2);
            }
        } else {
            // With + converting to number
            return (qty * +flooringWeight).toFixed(2);
        }

        return 0;
    }

    /**
     * Adjustements for attribute option's label in URL not code
     * Get link to product page
     * @param url_key Url to product
     * @return {{pathname: String, state Object}} Pathname and product state
     */
    _getProductLinkTo() {
        const {
            item: {
                product,
                product: {
                    type_id,
                    configurable_options,
                    parent,
                    url
                } = {}
            } = {}
        } = this.props;

        if (type_id !== CONFIGURABLE) {
            return {
                pathname: url,
                state: { product }
            };
        }

        const variant = this.getProductVariant();
        if (!variant) {
            return {};
        }
        const { attributes } = variant;

        const parameters = Object.entries(attributes).reduce(
            (parameters, [code, { attribute_value }]) => {
                if (Object.keys(configurable_options).includes(code)) {
                    const {
                        [code]: {
                            attribute_options: {
                                [attribute_value]: {
                                    label
                                } = {}
                            } = {}
                        } = {}
                    } = configurable_options;

                    return { ...parameters, [code]: label };
                }

                return parameters;
            }, {}
        );

        const stateProduct = parent || product;

        return {
            pathname: url,
            state: { product: stateProduct },
            search: objectToUri(parameters)
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemContainer);
