import { datadogRum } from '@datadog/browser-rum';

import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from 'SourceStore/Notification/Notification.action';
import {
    getInitialState
} from 'SourceStore/Notification/Notification.reducer';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import {
    deleteAuthorizationToken
} from 'Util/Auth';
import getStore from 'Util/Store';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);
/** @namespace ZnetPwa/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (
    state = getInitialState(),
    action
) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
    case SHOW_NOTIFICATION:
        const { msgType, msgText, msgDebug } = action;
        const { storeConfig } = BrowserDatabase.getItem('config') || {};
        const {
            datadog_enabled = false
        } = storeConfig;
        const store = getStore();
        const { dispatch } = store;

        notifications[Date.now()] = { msgType, msgText, msgDebug };

        if (msgType === 'error' && datadog_enabled) {
            const message = msgText || msgDebug;
            const errorMessage = new Error(message);
            datadogRum.addError(errorMessage);
        }

        if (msgText.includes('The current customer isn\'t authorized.')) {
            deleteAuthorizationToken();
            MyAccountDispatcher.then(
                ({ default: dispatcher }) => dispatcher.logout(true, dispatch)
            );
        }

        return {
            ...state,
            notifications
        };

    case HIDE_NOTIFICATION:
        // eslint-disable-next-line no-unused-vars
        const { [action.id]: id, ...shownNotifications } = notifications;

        return {
            ...state,
            notifications: shownNotifications
        };

    default:
        return state;
    }
};
export default NotificationReducer;
