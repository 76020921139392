import PropTypes from 'prop-types';

import { AddToCart as SourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.style';

/** @namespace ZnetPwa/Component/AddToCart/Component/AddToCartComponent */
export class AddToCartComponent extends SourceAddToCart {
    static propTypes = {
        ...this.propTypes,
        title: PropTypes.string,
        isQuickAddToCart: PropTypes.bool,
        isQuoteFormBtn: PropTypes.bool,
        isSample: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        title: '',
        isQuickAddToCart: true,
        isSample: false
    };

    renderPlusSign() {
        const { isSample } = this.props;

        if (!isSample) {
            return null;
        }

        return (
            <div block="AddToCart" elem="WishlistIcon">
                <span block="AddToCart" elem="WishlistIconPlus">
                    +
                </span>
            </div>
        );
    }

    render() {
        const {
            mix,
            product: { type_id },
            isSample,
            isQuoteFormBtn,
            isLoading,
            buttonClick,
            title
        } = this.props;

        if (!type_id) {
            this.renderPlaceholder();
        }

        const addToCartText = title && title !== '' ? title : __('Quick Add To Cart');

        if (isQuoteFormBtn) {
            return (
                <button
                  onClick={ buttonClick }
                  block="AddSampleToCart"
                  elem="QuoteBtn"
                  mods={ { isLoading } }
                  disabled={ isLoading }
                >
                    <span>
                        { __('BUY A SAMPLE') }
                    </span>
                </button>
            );
        }

        if (isSample) {
            return (
                <button
                  onClick={ buttonClick }
                  block="AddSampleToCart"
                  mix={ mix }
                  mods={ { isLoading } }
                  disabled={ isLoading }
                >
                    <div block="AddSampleToCart" elem="AddIcon">
                        <div block="AddSampleToCart" elem="AddPlusIcon">
                            +
                        </div>
                    </div>
                    <span>
                        { addToCartText }
                    </span>
                    <span>{ __('Adding...') }</span>
                </button>
            );
        }

        return (
            <button
              onClick={ buttonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { isLoading } }
              disabled={ isLoading }
            >
                { this.renderPlusSign() }
                <span>
                    { addToCartText }
                </span>
                <span>{ __('Adding...') }</span>
            </button>
        );
    }
}

export default AddToCartComponent;
