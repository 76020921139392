// Disabling for 1 line still threw an error
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import {
    AiOutlineArrowLeft,
    AiOutlineClose,
    AiOutlineMenu,
    AiOutlineRight
} from 'react-icons/ai';

import Link from 'Component/Link';
import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';
import { MenuItemType } from 'SourceType/Menu';
import { getSortedItems } from 'Util/Menu';

import './Menu.style';

/** @namespace ZnetPwa/Component/Menu/Component/MenuComponent */
export class MenuComponent extends SourceMenu {
    static propTypes = {
        ...this.propTypes,
        childrenArray: PropTypes.arrayOf(MenuItemType).isRequired,
        closeMenu: PropTypes.func.isRequired,
        onMenuClick: PropTypes.func.isRequired,
        menuRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ])
    };

    renderFirstLevelMenu = (item) => {
        const { device, closeMenu } = this.props;
        const {
            item_id,
            children,
            title,
            url
        } = item;

        if (Object.values(children).length > 0) {
            const childrenArray = getSortedItems(Object.values(children));

            if (device.isMobile) {
                return (
                    <li
                      block="Menu"
                      elem="SubmenuBlock"
                    >
                        <Link
                          to={ url }
                          id={ item_id }
                          onClickBeforeLoader={ closeMenu }
                          showLoader
                        >
                            { title }
                        </Link>
                        <label
                          block="Menu"
                          elem="SubmenuArrow"
                          htmlFor={ `checkbox_${ item_id }` }
                        >
                            <AiOutlineRight size="20" />
                        </label>
                        <input
                          type="checkbox"
                          id={ `checkbox_${ item_id }` }
                          name={ `checkbox_${ item_id }` }
                          block="Menu"
                          elem="MainMenuCheckbox"
                        />
                        <div block="Menu" elem="MobileSubmenu">
                            <div block="Menu" elem="MobileSubmenuHeader">
                                <label block="Menu" elem="CheckboxLabel" htmlFor={ `checkbox_${ item_id }` }>
                                    <AiOutlineArrowLeft size="25" />
                                </label>
                                <span>
                                    { title }
                                </span>
                            </div>
                            <ul>
                                { childrenArray.map(this.renderFirstLevelMenu) }
                            </ul>
                        </div>
                    </li>
                );
            }

            return (
                <li>
                    <Link
                      to={ url }
                      id={ item_id }
                      onClick={ closeMenu }
                    >
                      { title }
                    </Link>
                    <ul>
                        { childrenArray.map(this.renderFirstLevelMenu) }
                    </ul>
                </li>
            );
        }

        if (device.isMobile) {
            return (
                <li
                  block="Menu"
                  elem="ItemBlock"
                >
                    <Link
                      block="Menu"
                      elem="Link"
                      to={ url }
                      id={ item_id }
                      onClickBeforeLoader={ closeMenu }
                      showLoader
                    >
                        <label>
                            { title }
                        </label>
                    </Link>
                </li>
            );
        }

        return (
            <li>
                <Link
                  to={ url }
                  id={ item_id }
                >
                  { title }
                </Link>
            </li>
        );
    };

    renderMainMenu() {
        const {
            childrenArray,
            device,
            onMenuClick,
            menuRef
        } = this.props;

        if (device.isMobile) {
            return (
                <nav>
                    <input
                      type="checkbox"
                      id="menu"
                      name="menu"
                      block="Menu"
                      elem="MainMenuCheckbox"
                      ref={ menuRef }
                    />
                    <label
                      block="Menu"
                      elem="CheckboxLabel"
                      htmlFor="menu"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ (e) => onMenuClick(e) }
                      // eslint-disable-next-line react/jsx-no-bind
                      onKeyDown={ (e) => onMenuClick(e) }
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                      role="button"
                    >
                        <AiOutlineMenu size="30" />
                    </label>
                    <label block="Menu" elem="LabelOverlay" htmlFor="menu" />

                    <div block="Menu" elem="MobileSubmenu FirstLevelMenu">
                        <div block="Menu" elem="MobileSubmenuHeader">
                            <label block="Menu" elem="CheckboxLabel" htmlFor="menu">
                                <AiOutlineClose size="25" />
                            </label>
                            <span>{ __('MENU') }</span>
                        </div>
                            <ul>
                                { childrenArray?.map(this.renderFirstLevelMenu) }
                            </ul>
                    </div>
                </nav>
            );
        }

        if (childrenArray.length > 0) {
            return (
                <nav>
                    <ul>
                        { childrenArray?.map(this.renderFirstLevelMenu) }
                    </ul>
                </nav>
            );
        }

        return null;
    }

    render() {
        const {
            closeMenu,
            device
        } = this.props;

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              mods={ { isMobile: device.isMobile } }
              onMouseLeave={ closeMenu }
            >
                { this.renderMainMenu() }
            </div>
        );
    }
}

export default MenuComponent;
