/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    CART_OVERLAY,
    CHECKOUT,
    MENU,
    MENU_SUBCATEGORY,
    POPUP,
    SEARCH
} from 'SourceComponent/Header/Header.config';
import { isMobile } from 'Util/Mobile';

import PureChat from '../component/PureChat.container';

/** @namespace ScandiPWA/Blog/Plugin/Component/Header/Plugin/stateMap */
export class HeaderComponentPlugin {
    aroundStateMap = (originalMember) => ({
        ...originalMember,
        [POPUP]: {
            chat: !isMobile.any()
        },
        [CHECKOUT]: {
            back: true,
            title: false,
            account: true,
            chat: false
        },
        [CART_OVERLAY]: {
            title: false,
            chat: !isMobile.any()
        },
        [MENU]: {
            search: true,
            chat: !isMobile.any()
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            title: false,
            chat: !isMobile.any()
        },
        [SEARCH]: {
            back: true,
            search: true,
            chat: !isMobile.any()
        }
    });

    aroundRenderMap = (originalMember) => ({
        chat: this.renderPureChat.bind(this),
        ...originalMember
    });

    renderPureChat(isVisible = true) {
        return (
            <PureChat
              isVisible={ isVisible }
            />
        );
    }
}

const { aroundStateMap, aroundRenderMap } = new HeaderComponentPlugin();

export const config = {
    'Component/Header/Component': {
        'member-property': {
            stateMap: aroundStateMap,
            renderMap: aroundRenderMap
        }
    }
};

export default config;
