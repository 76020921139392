import {
    CLEAR_SEARCH_RESULTS,
    UPDATE_SEARCH_BAR,
    UPDATE_SEARCH_LOAD_STATUS
} from 'SourceStore/SearchBar/SearchBar.action';
import { getIndexedProducts } from 'Util/Product';

import { UPDATE_SEARCH_BAR_QUERY } from './SearchBar.action';

/** @namespace ZnetPwa/Store/SearchBar/Reducer/getInitialState */
export const getInitialState = () => ({
    productsInSearch: [],
    isLoading: true,
    query: ''
});

/** @namespace ZnetPwa/Store/SearchBar/Reducer/SearchBarReducer */
export const SearchBarReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_SEARCH_BAR:
        const { result: { products: { items: initialItems } } } = action;

        return {
            ...state,
            productsInSearch: getIndexedProducts(initialItems)
        };

    case UPDATE_SEARCH_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case CLEAR_SEARCH_RESULTS:
        return {
            ...state,
            productsInSearch: getInitialState().productsInSearch
        };

    case UPDATE_SEARCH_BAR_QUERY:
        const { query } = action;

        return {
            ...state,
            query
        };

    default:
        return state;
    }
};

export default SearchBarReducer;
