/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export * from 'SourceComponent/NewVersionPopup/NewVersionPopup.config';

export const NEW_VERSION_POPUP_ID = 'new_version';
export const SUBSEQUENT_PAGE_LOAD_FLAG = 'subsequent_page_load_flag';
