import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import getStore from 'Util/Store';

import GclidQuery from '../../query/Gclid.query';

/** @namespace GclidSpwa/Util/SendGclid/SendGclid */
export class SendGclid {
    static async sendGclid(instance) {
        const { checkoutStep, isGclidSent = false } = instance.state || {};
        const state = getStore().getState();
        const {
            znetSyncGclidServiceStatus = false
        } = state.ConfigReducer;

        if (checkoutStep === BILLING_STEP && !isGclidSent) {
            const gclid = BrowserDatabase.getItem('gclid');

            if (!isSignedIn()) {
                const guestQuoteId = BrowserDatabase.getItem('guest_quote_id')?.token;

                if (guestQuoteId && znetSyncGclidServiceStatus && gclid) {
                    instance.setState({ isGclidSent: true });
                    await GclidQuery.assignGclidToCart(gclid, guestQuoteId);
                }
            } else if (znetSyncGclidServiceStatus && gclid) {
                instance.setState({ isGclidSent: true });
                await GclidQuery.assignGclidToCart(gclid);
            }
        }

        return false;
    }
}

export default SendGclid;
