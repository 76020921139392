import React from 'react';
import PropTypes from 'prop-types';
import './Toggle.style';

/**
 * Toggle
 * @class Toggle
 * @namespace Component/Toggle/Component
 */
export class Toggle extends PureComponent {
    static propTypes = {
        isChecked: PropTypes.bool,
        label: PropTypes.string,
        isGreen: PropTypes.bool,
        id: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        isChecked: false,
        label: null,
        isGreen: false,
        disabled: false,
        onChange: null
    };

    __construct(props) {
        super.__construct(props);

        const { isChecked } = this.props;

        this.state = {
            activeStatus: isChecked
        };
    }

    handleClick() {
        const { onChange, id } = this.props;
        const { activeStatus } = this.state;

        this.setState({ activeStatus: !activeStatus });

        if (onChange !== null) {
            onChange(id, !activeStatus);
        }
    }

    render() {
        const {
            id, isGreen, disabled, label
        } = this.props;
        const { activeStatus } = this.state;
        const mods = {
            isGreen: !!isGreen,
            disabled: !!disabled
        };

        return (
            <div block="Toggle" mods={ mods }>
                <input
                  onChange={ () => this.handleClick() }
                  type="checkbox"
                  id={ id }
                  defaultChecked={ activeStatus }
                  disabled={ !!disabled }
                />
                <label // eslint-disable-line jsx-a11y/label-has-associated-control
                  htmlFor={ id }
                />
                <span block="Toggle" elem="Label">{ label }</span>
            </div>
        );
    }
}

export default Toggle;
