import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FiMenu } from 'react-icons/fi';

import { MENU } from 'Component/Header/Header.config';
import Menu from 'Component/Menu';
import Overlay from 'Component/Overlay';

import './MobileMenu.style';

/** @namespace ZnetPwa/Component/MobileMenu/Component/MobileMenuComponent */
export class MobileMenuComponent extends PureComponent {
    static propTypes = {
        handleMobileMenuClick: PropTypes.func.isRequired
    };

    render() {
        const { handleMobileMenuClick } = this.props;

        return (
            <>
            <FiMenu size="24px" className="Icon MobileMenu-Menu" onClick={ handleMobileMenuClick } />
            <Overlay id={ MENU } mix={ { block: 'MenuOverlay' } }>
                <Menu />
            </Overlay>
            </>
        );
    }
}

export default MobileMenuComponent;
