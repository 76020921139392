/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export {
    updateLinkedProducts,
    UPDATE_LINKED_PRODUCTS
} from 'SourceStore/LinkedProducts/LinkedProducts.action';
export const UPDATE_LINKED_PRODUCTS_LOADING_STATUS = 'UPDATE_LINKED_PRODUCTS_LOADING_STATUS';
/**
 * Update upsell products list (rewrite if already exists).
 * @param  {Array<String>} upsell List of products returned from fetch
 * @return {void}
 * @namespace ZnetPwa/Store/LinkedProducts/Action/setLinkedProductsIsLoading */
export const setLinkedProductsIsLoading = (loadingValue) => ({
    type: UPDATE_LINKED_PRODUCTS_LOADING_STATUS,
    loadingValue
});
