import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Captcha from 'Component/Captcha/Captcha.component';
import { isCrawler } from 'SourceUtil/Browser';

import {
    API_URL,
    SCRIPT_ID
} from './Captcha.config';

/** @namespace ZnetPwa/Component/Captcha/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace ZnetPwa/Component/Captcha/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isEnabled: state.ConfigReducer.captcha_is_enabled,
    siteKey: state.ConfigReducer.captcha_id
});

/** @namespace ZnetPwa/Component/Captcha/Container/CaptchaContainer */
export class CaptchaContainer extends PureComponent {
    static propTypes = {
        siteKey: PropTypes.string,
        isEnabled: PropTypes.bool,
        setToken: PropTypes.func.isRequired,
        isFormSubmittedUnsuccessfully: PropTypes.bool,
        isRegistrationFormSubmittedUnsuccessfully: PropTypes.bool,
        captchaId: PropTypes.string.isRequired
    };

    static defaultProps = {
        isFormSubmittedUnsuccessfully: false,
        isRegistrationFormSubmittedUnsuccessfully: false,
        isEnabled: false,
        siteKey: ''
    };

    containerFunctions = {
        initScript: this.initScript.bind(this)
    };

    state = {
        captchaGoogleId: -1,
        scriptInserted: false,
        // Add a flag to track component's mounted state as callback from captcha
        // can be called after component is unmounted
        isMounted: false
    };

    componentDidMount() {
        this.setState({ isMounted: true });
    }

    componentDidUpdate(prevProps) {
        const {
            isFormSubmittedUnsuccessfully: wasError
        } = prevProps;
        const {
            isFormSubmittedUnsuccessfully
        } = this.props;
        const {
            captchaGoogleId
        } = this.state;

        if (isFormSubmittedUnsuccessfully && isFormSubmittedUnsuccessfully !== wasError) {
            this.resetCaptcha(captchaGoogleId);
        }
    }

    componentWillUnmount() {
        this.cleanup();
        this.setState({ isMounted: false });
    }

    containerProps = () => {
        const {
            siteKey,
            isEnabled,
            setToken,
            captchaId
        } = this.props;

        return {
            siteKey,
            isEnabled,
            setToken,
            captchaId
        };
    };

    isEnabled = () => {
        const { isEnabled } = this.props;
        return isEnabled;
    };

    getScript = () => document.getElementById(SCRIPT_ID);

    getButton() {
        const {
            captchaId
        } = this.props;

        return document.getElementById(captchaId);
    }

    initScript() {
        const {
            scriptInserted,
            isMounted
        } = this.state;

        // Not rendering captcha for crawlers
        if (isCrawler() === true) {
            return;
        }

        if (!scriptInserted) {
            // Define the callback function
            window.onRecaptchaLoaded = () => {
                this.setupButton();
            };
            const scriptDOM = document.createElement('script');
            scriptDOM.src = `${API_URL}?render=explicit&onload=onRecaptchaLoaded`;
            scriptDOM.id = SCRIPT_ID;
            scriptDOM.async = true;

            document.body.appendChild(scriptDOM);
            if (isMounted) {
                this.setState({ scriptInserted: true });
            }
        } else if (!this.getButton()) {
            this.setupButton();
        }
    }

    setupButton() {
        const { isMounted } = this.state;
        const { siteKey, captchaId } = this.props;

        if (!window.grecaptcha || !window.grecaptcha.render) {
            console.error('reCAPTCHA not loaded');
            return;
        }

        if (!isMounted) {
            return;
        }

        try {
            const captchaGoogleId = window.grecaptcha.render(captchaId, {
                sitekey: siteKey,
                callback: this.submit.bind(this)
            });

            this.setState({ captchaGoogleId });
        } catch (error) {
            console.error('Error rendering captcha:', error);
        }
    }

    cleanup() {
        const script = this.getScript();
        if (!script) {
            return;
        }

        // Remove the global callback
        if (window.onRecaptchaLoad) {
            // eslint-disable-next-line fp/no-delete
            delete window.onRecaptchaLoad;
        }

        // eslint-disable-next-line fp/no-delete
        delete window.grecaptcha;
        script.parentNode.removeChild(script);
    }

    submit(response) {
        const {
            setToken
        } = this.props;
        const {
            isMounted,
            captchaGoogleId
        } = this.state;

        if (isMounted) {
            setToken(response);
        } else {
            this.resetCaptcha(captchaGoogleId);
        }
    }

    resetCaptcha(id) {
        const { setToken } = this.props;

        if (window.grecaptcha && id !== -1) {
            window.grecaptcha.reset(id);
            setToken(false);
        }
    }

    render() {
        if (!this.isEnabled()) {
            return null;
        }

        return (
            <Captcha
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptchaContainer);
