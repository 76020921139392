import {
    CART_TOTALS,
    updateShippingPrice
} from 'SourceStore/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

import { UPDATE_SHIPPING_PRICE, UPDATE_TOTALS } from './Cart.action';

export {
    CART_TOTALS,
    updateShippingPrice
};

/** @namespace ZnetPwa/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action) => {
    const { cartData: cartTotals } = action;

    if (Object.hasOwnProperty.call(cartTotals, 'items')) {
        const normalizedItemsProduct = cartTotals.items.map((item) => {
            // Excluding variants not to overflow the localstorage
            // eslint-disable-next-line no-unused-vars
            const { variants, ...normalizedItem } = item;
            normalizedItem.product = getIndexedProduct(item.product, item.sku);

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
        cartTotals.isInitialDataReceived = true;
    }

    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS
    );

    return { cartTotals };
};

/** @namespace ZnetPwa/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    cartTotals: BrowserDatabase.getItem(CART_TOTALS) || { isInitialDataReceived: false }
});

/** @namespace ZnetPwa/Store/Cart/Reducer/CartReducer */
export const CartReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_TOTALS:
        return updateCartTotals(action, state);
    case UPDATE_SHIPPING_PRICE:
        return updateShippingPrice(action, state);
    default:
        return state;
    }
};

export default CartReducer;
