/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { EqualHeight } from 'react-equal-height';

import CarouselScroll from 'Component/CarouselScroll';
import ContentWrapper from 'Component/ContentWrapper';
import { ATTRIBUTE_SET_ACCESSORIES } from 'Component/ProductActions/ProductActions.config';
import ProductCard from 'Component/ProductCard';
import { ProductLinks as SourceProductLinks } from 'SourceComponent/ProductLinks/ProductLinks.component';
import { CROSS_SELL, RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { CONFIGURABLE } from 'Util/Product';

import {
    FOUR,
    ITEMS_IN_CAROUSEL_DESKTOP, OUT_OF_STOCK, SUGGESTED_FILTER_VALUE, TWO
} from './ProductLinks.config';
/** @namespace ZnetPwa/Component/ProductLinks/Component/ProductLinksComponent */
export class ProductLinksComponent extends SourceProductLinks {
    static propTypes = {
        ...this.propTypes,
        onQuickViewClick: PropTypes.func.isRequired,
        isCrossSellSection: PropTypes.bool,
        isCartPopup: PropTypes.bool,
        checkScroll: PropTypes.func.isRequired,
        isScroll: PropTypes.bool.isRequired,
        suggested_accessories_skus: PropTypes.string.isRequired,
        filterProducts: PropTypes.func.isRequired
    };

    tooltipOverlayRef = createRef();

    static defaultProps = {
        ...this.defaultProps,
        isCrossSellSection: false,
        isCartPopup: false
    };

    renderHeading() {
        const {
            title,
            linkType,
            currentProduct: {
                dynamicAttributes: {
                    collection_color = ''
                } = {}
            } = {},
            filterOptions,
            isCartPopup
        } = this.props;

        const color = collection_color !== 'false' ? collection_color : '';

        if (linkType === RELATED) {
            return (
                <div block="ProductLinks" elem="TitleWrapper">
                    <div block="ProductLinks" elem="TitleSubWrapper">
                        <h3 block="ProductLinks" elem="Title">
                            { title }
                        </h3>
                        <span
                          block="ProductLinks"
                          elem="ColorsSubTitle"
                        >
                            { color }
                        </span>
                    </div>
                </div>
            );
        }

        if (linkType === CROSS_SELL) {
            return (
                <div
                  block="ProductLinks"
                  elem="FiltersAndTitleWrapper"
                >
                    { title
                        && (
                            <h1
                              block="ProductLinks"
                              elem="CrossTitle"
                            >
                                { title }
                            </h1>
                        ) }
                    <div
                      block="ProductLinks"
                      elem="FiltersWrapper"
                      mods={ { isCartPopup } }
                    >
                        { this.renderSuggestedFilterButton() }
                        { filterOptions.map(this.renderFilterButton) }
                    </div>
                </div>
            );
        }

        if (linkType === UPSELL) {
            return (
                <div
                  block="ProductLinks"
                  elem="UpsellHeading"
                >
                    { title }
                </div>
            );
        }

        return null;
    }

    renderSuggestedFilterButton() {
        const {
            setAccessoriesFilter,
            activeFilter,
            currentProduct: {
                dynamicAttributes: {
                    attribute_set_name = ''
                } = {}
            } = {}
        } = this.props;

        if (attribute_set_name === ATTRIBUTE_SET_ACCESSORIES) {
            return null;
        }

        const isFilterSet = activeFilter === SUGGESTED_FILTER_VALUE;

        return (
            <button
              block="ProductLinks"
              elem="FilterButton"
              mods={ { isActive: isFilterSet } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => setAccessoriesFilter(SUGGESTED_FILTER_VALUE) }
            >
                { __('Suggested') }
            </button>
        );
    }

    renderFilterButton = (filter, i) => {
        const { setAccessoriesFilter, activeFilter } = this.props;

        const {
            label, value
        } = filter;

        const isFilterSet = activeFilter === value;

        return (
            <button
              block="ProductLinks"
              elem="FilterButton"
              key={ i }
              mods={ { isActive: isFilterSet } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => setAccessoriesFilter(value) }
            >
                { label }
            </button>
        );
    };

    renderProductCard(product, i) {
        const {
            requiredAccessories,
            currentProduct: {
                sku
            } = {},
            onQuickViewClick,
            linkedProducts,
            linkType,
            productCardProps,
            productCardFunctions,
            isCartPopup,
            isScroll
        } = this.props;

        const {
            id = i,
            sku: productSku,
            stock_status,
            type_id
        } = product;

        // Display out of stock Configurable products as its Out of stock logic is handled differently
        if (stock_status === OUT_OF_STOCK && type_id !== CONFIGURABLE) {
            return null;
        }

        const selectedProduct = productSku === sku;
        const isRequired = requiredAccessories?.includes(productSku);
        let mix = { block: 'ProductLinks', elem: 'Card' };

        if (linkType === CROSS_SELL) {
            mix = { block: 'ProductLinks', elem: 'Crosssell', mods: { isRequired, isCartPopup } };
        }

        if (linkType === UPSELL) {
            mix = { block: 'ProductLinks', elem: 'UpsellCard' };
        }

        if (linkType === RELATED) {
            mix = { block: 'ProductLinks', elem: 'RelatedCard' };
        }

        return (
            <ProductCard
              mix={ mix }
              mods={ { type: selectedProduct, isRequired: true } }
              isEqualHeight
              isRequiredAccessory={ isRequired }
              isMolding={ product?.dynamicAttributes?.is_molding }
              isSelected={ selectedProduct }
              isCartPopup={ isCartPopup }
              product={ product }
              onQuickViewClick={ onQuickViewClick }
              key={ id }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...productCardProps }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...productCardFunctions }
              linkType={ linkType }
              linkedProducts={ linkedProducts }
              id={ id }
              tooltipOverlayRef={ this.tooltipOverlayRef }
              isScroll={ isScroll }
            />
        );
    }

    renderReorderItems(items) {
        const sorted = items?.sort((a, b) => a?.sku_hash?.localeCompare(b.sku_hash));

        return sorted;
    }

    renderItems() {
        const {
            filterProducts,
            checkScroll,
            linkType,
            currentProduct: {
                type_id = ''
            } = {},
            configurableInitialLinkedProducts: {
                [linkType]: { items: configurableItems },
                [RELATED]: { items: configurableRelatedItems }
            },
            linkedProducts: {
                [linkType]: { items, total_count },
                [RELATED]: { items: relatedItems }
            },
            activeAccessory,
            onActiveAccessoryChange,
            numberOfProductsToDisplayDesktop,
            numberOfProductsToDisplayMobile,
            device: {
                isMobile
            },
            showMore,
            onShowMoreClick,
            activeFilter,
            filterOptions,
            isCartPopup,
            suggested_accessories_skus
        } = this.props;

        const numberOfProductsToDisplay = isMobile ? numberOfProductsToDisplayMobile : numberOfProductsToDisplayDesktop;
        const showedItems = isMobile ? TWO : ITEMS_IN_CAROUSEL_DESKTOP;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderProductCard({}, i)
            );
        }

        if (linkType === CROSS_SELL) {
            const suggestedAccessoriesSkus = suggested_accessories_skus?.split(',');

            let filteredItems = items.filter((item) => {
                if (activeFilter === 0) {
                    return true;
                }

                if (activeFilter === -1) {
                    return suggestedAccessoriesSkus?.includes(item.sku);
                }

                const itemTypes = item.accessory_type ? item.accessory_type.split(',') : [];

                const isSet = (elt) => activeFilter === elt;

                return itemTypes.some(isSet);
            });

            filteredItems = filteredItems?.length ? filterProducts(filteredItems) : [];

            if (filteredItems.length === 0) {
                const activeLabel = activeFilter === -1
                    ? 'Suggested' : filterOptions.find((elt) => elt.value === activeFilter)?.label;

                return (
                    <p
                      block="ProductLinks"
                      elem="NoSelection"
                    >
                        { __('There are no accessories that match your selection: ') }
                        { `"${activeLabel}"` }
                    </p>
                );
            }

            if (isCartPopup) {
                return (
                    <div block="ProductLinks" elem="Accessories" mods={ { isCartPopup } }>
                        <EqualHeight>
                            <CarouselScroll
                              activeItemId={ activeAccessory }
                              onChange={ onActiveAccessoryChange }
                              showedItemCount={ showedItems }
                              showedActiveItemNr={ 1 }
                              dynamicItems
                              isCartPopup={ isCartPopup }
                              activeFilter={ activeFilter }
                            >
                                { filteredItems.map(this.renderProductCard) }
                            </CarouselScroll>
                        </EqualHeight>
                    </div>
                );
            }

            return (
                <div block="ProductLinks" elem="Accessories" onScroll={ checkScroll }>
                    { filteredItems.map(this.renderProductCard) }
                </div>
            );
        }

        if (linkType === UPSELL) {
            const upsellAndRelated = type_id === CONFIGURABLE
                ? filterProducts(configurableItems, FOUR).concat(filterProducts(configurableRelatedItems, FOUR))
                : filterProducts(items, FOUR).concat(filterProducts(relatedItems, FOUR));

            return (
                <div block="ProductLinks" elem="Upsell" mods={ { isCartPopup } }>
                    { upsellAndRelated.map(this.renderProductCard) }
                </div>
            );
        }

        if (linkType === RELATED) {
            return (
                <ul block="ProductLinks" elem="List" mods={ { linkType } }>
                    { items.map(this.renderProductCard) }
                </ul>
            );
        }

        const orderedProducts = this.renderReorderItems(items);
        const uniqueOrderedProducts = orderedProducts.filter((product, index, self) => index === self.findIndex(
            (otherProduct) => (
                otherProduct.id === product.id
            )
        ));

        const numberOfSwatches = showMore ? total_count : numberOfProductsToDisplay;
        let showMoreButton = null;

        if (total_count > numberOfProductsToDisplay) {
            const buttonText = showMore ? 'Show less' : 'Show more';
            showMoreButton = (
                <button
                  onClick={ onShowMoreClick }
                  block="ProductLinks"
                  elem="ShowMore"
                >
                    { buttonText }
                </button>
            );
        }

        return (
            <>
                <ul block="ProductLinks" elem="List">
                    { uniqueOrderedProducts.slice(0, numberOfSwatches).map(this.renderProductCard) }
                </ul>
                { showMoreButton }
            </>
        );
    }

    render() {
        const {
            areDetailsLoaded,
            isCrossSellSection,
            isCartPopup,
            linkType
        } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        if (isCrossSellSection) {
            return (
                <ContentWrapper
                  mix={ { block: 'ProductLinks', mods: { isCrossSellSection, isCartPopup } } }
                  wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
                  label={ __('Linked products') }
                >
                    <div block="ProductLinks" elem="TooltipOverlay" ref={ this.tooltipOverlayRef } />
                    { this.renderHeading() }
                    { this.renderItems() }
                </ContentWrapper>
            );
        }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper', mods: { linkType } } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                { this.renderItems() }
            </ContentWrapper>
        );
    }
}

export default ProductLinksComponent;
