import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ERROR_CONFIGURABLE_NOT_PROVIDED } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

/** @namespace ZnetPwa/Component/ProductWishlistButton/Container/ProductWishlistButtonContainer */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    toggleProductInWishlist = (add = true) => {
        const {
            product: { sku, type_id },
            quantity,
            isAddingWishlistItem,
            showNotification,
            productsInWishlist,
            addProductToWishlist,
            onProductValidationError,
            removeProductFromWishlist,
            location: { pathname }
        } = this.props;

        if (!isSignedIn()) {
            history.push({ pathname: '/account/login', state: pathname });
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return showNotification('info', __('You must login or register to add items to your wishlist.'));
        }

        if (isAddingWishlistItem) {
            return null;
        }

        const product = this._getProductVariant();
        if (product === ERROR_CONFIGURABLE_NOT_PROVIDED) {
            onProductValidationError(type_id);
            return showNotification('info', __('Please, select desirable option first!'));
        }

        this.setWishlistButtonLoading(true);

        const { sku: variantSku, product_option } = product;
        if (add) {
            return addProductToWishlist({ sku, product_option, quantity });
        }

        const { wishlist: { id: item_id } } = Object.values(productsInWishlist).find(
            ({ wishlist: { sku } }) => sku === variantSku
        );

        return removeProductFromWishlist({ item_id, sku: variantSku });
    };
}

export default withRouter(
    connect(sourceMapStateToProps, sourceMapDispatchToProps)(ProductWishlistButtonContainer)
);
