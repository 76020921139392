/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import { Slider as SourceSlider } from 'SourceComponent/Slider/Slider.component';
import { isCrawler } from 'SourceUtil/Browser';
import CSS from 'Util/CSS';

import {
    ANIMATION_DURATION
} from './Slider.config';

/** @namespace ZnetPwa/Component/Slider/Component/SliderComponent */
export class SliderComponent extends SourceSlider {
    static propTypes = {
        ...this.propTypes,
        gallery: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string
                ]),
                image: PropTypes.string,
                isPlaceholder: PropTypes.bool,
                alt: PropTypes.string,
                type: PropTypes.string,
                media_type: PropTypes.string
            })
        ).isRequired,
        isReady: PropTypes.bool,
        isPDP: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isReady: true,
        isPDP: false
    };

    componentDidUpdate(prevProps) {
        const { activeImage: prevActiveImage, isReady: prevIsReady } = prevProps;
        const { activeImage, isReady } = this.props;

        if (activeImage !== prevActiveImage && this.getIsSlider()) {
            const newTranslate = -activeImage * this.getSlideWidth();

            this.setAnimationSpeedStyle(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION));
            this.setTranlateXStyle(newTranslate);
        }

        if (isReady !== prevIsReady) {
            if (!this.getIsSlider()) {
                return;
            }

            const sliderChildren = this.draggableRef.current.children;
            const sliderWidth = this.draggableRef.current.offsetWidth;
            this.sliderWidth = sliderWidth;

            if (!sliderChildren || !sliderChildren[0]) {
                return;
            }

            this.setStyleVariablesOnMount();

            const sliderRef = this.getSliderRef();
            const sliderHeight = `${ sliderChildren[0].offsetHeight }px`;

            sliderChildren[0].onload = () => {
                CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
            };

            setTimeout(() => {
                CSS.setVariable(sliderRef, 'slider-height', sliderHeight);
            }, ANIMATION_DURATION);
        }
    }

    toLeft() {
        const { activeImage } = this.props;
        if (activeImage > 0) {
            this.changeActiveImage(activeImage - 1);
        }
    }

    toRight() {
        const { activeImage, children } = this.props;
        if (activeImage < (children.length - 1)) {
            this.changeActiveImage(activeImage + 1);
        }
    }

    renderArrows() {
        const { children, isTopMenu } = this.props;

        if (children.length <= 1) {
            return null;
        }

        const size = isTopMenu ? '18px' : '60px';
        const color = isTopMenu ? 'black' : 'white';

        return (
            <div
              block="Slider"
              elem="Arrows"
              mods={ { isTopMenu } }
            >
                <div
                  block="Arrows"
                  elem="Left"
                >
                    <BsChevronLeft
                      size={ size }
                      color={ color }
                      className="Icon"
                      onClick={ () => this.toLeft() }
                    />
                </div>
                <div
                  block="Arrows"
                  elem="Right"
                >
                    <BsChevronRight
                      size={ size }
                      color={ color }
                      className="Icon"
                      onClick={ () => this.toRight() }
                    />
                </div>
            </div>
        );
    }

    /**
     * Ref: https://sepoy.atlassian.net/browse/ZFR-1058
     */
    renderCrumbAndLabel(isActive, labelFor) {
        const { isPDP } = this.props;

        if (!isCrawler() || !isPDP) {
            return (
                <div
                  block="Slider"
                  elem="Crumb"
                  mods={ { isActive } }
                />
            );
        }

        return (
            /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
            <label htmlFor={ labelFor }>
            <div
              block="Slider"
              elem="Crumb"
              mods={ { isActive } }
            />
            </label>
        );
    }

    renderCrumb(_, i) {
        const { activeImage, gallery } = this.props;
        const isActive = i === Math.abs(-activeImage);
        const labelFor = `${ gallery?.[i]?.id }_${ gallery?.[i]?.position }`;

        return (
            <button
              block="Slider"
              elem="SingleCrumbBtn"
              mods={ { type: 'single' } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => this.changeActiveImage(i) }
              aria-label={ __('Slide crumb') }
            >
                { this.renderCrumbAndLabel(isActive, labelFor) }
            </button>
        );
    }

    render() {
        const {
            showCrumbs,
            mix
        } = this.props;

        return (
            <>
                <div
                  block="Slider"
                  mix={ mix }
                  ref={ this.getSliderRef() }
                >
                    { this.renderSliderContent() }
                    { this.renderArrows() }
                </div>
                { showCrumbs && this.renderCrumbs() }
            </>
        );
    }
}

export default SliderComponent;
