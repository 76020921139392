import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { LOGIN_ACCOUNT } from 'Component/LoginAccount/LoginAccount.config';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { CHECKOUT_SUCCESS_URL, CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    HeaderContainer as SourceHeader,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Header/Header.container';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { fetchQuery } from 'SourceUtil/Request';
import { updateCustomerDetails } from 'Store/MyAccount/MyAccount.action';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { showPopup } from 'Store/Popup/Popup.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import CSS from 'Util/CSS';
import history from 'Util/History';

import {
    CART_OVERLAY, SEARCH, TOP_MENU_POSITION, TOP_MENU_SCROLLY
} from './Header.config';

/** @namespace ZnetPwa/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    phone: state.ConfigReducer.store_phone
});

/** @namespace ZnetPwa/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateCustomer: (customer) => dispatch(updateCustomerDetails(customer)),
    showPopup: (id) => dispatch(showPopup(id))
});

/** @namespace ZnetPwa/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends SourceHeader {
    static propTypes = {
        ...this.propTypes,
        updateCustomer: PropTypes.func.isRequired,
        phone: PropTypes.string,
        showPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        phone: ''
    };

    topMenuRef = createRef();

    headerRef = createRef();

    containerProps = () => {
        const {
            phone,
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            device,
            isWishlistLoading
        } = this.props;

        const {
            isMyAccountOverlayOpened,
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin,
            activeTopMenu,
            showCloseTopMenu,
            topMenuBlocks
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);
        const isCheckoutSuccess = pathname.includes(CHECKOUT_SUCCESS_URL);

        return {
            phone,
            pathname,
            isMyAccountOverlayOpened,
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            isCheckoutSuccess,
            showMyAccountLogin,
            device,
            isWishlistLoading,
            activeTopMenu,
            showCloseTopMenu,
            headerRef: this.headerRef,
            topMenuRef: this.topMenuRef,
            topMenuBlocks
        };
    };

    containerFunctions = {
        ...this.containerFunctions,
        toggleOverlay: this.toggleOverlay.bind(this),
        openSignInPopUp: this.openSignInPopUp.bind(this),
        onActiveTopMenuChange: this.onActiveTopMenuChange.bind(this),
        closeTopMenu: this.closeTopMenu.bind(this)
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            ...this.state,
            isMyAccountOverlayOpened: false,
            activeTopMenu: 0,
            showCloseTopMenu: false,
            topMenuBlocks: ''
        };
    }

    toggleOverlay() {
        const { isMyAccountOverlayOpened } = this.state;
        this.setState({ isMyAccountOverlayOpened: !isMyAccountOverlayOpened });
    }

    _updateCustomer() {
        const { updateCustomer } = this.props;
        const customer = BrowserDatabase.getItem(CUSTOMER);

        if (customer && customer !== undefined) {
            updateCustomer(customer);
        }
    }

    componentDidMount() {
        this.handleHeaderVisibility();
        this._updateCustomer();
        super.componentDidMount();
        window.addEventListener('scroll', this.showCloseButton);

        fetchQuery(
            CmsBlockQuery.getTopCmsBlocksQuery()
        ).then(
            /** @namespace ZnetPwa/Component/Header/Container/fetchQuery/then */
            (data) => {
                this.setState({ topMenuBlocks: data }, () => {
                    if (this.topMenuRef?.current) {
                        CSS.setVariable(this.headerRef,
                            'header-top-menu-height',
                            `${this.topMenuRef?.current?.offsetHeight}px`);
                    }
                });
            },
            /** @namespace ZnetPwa/Component/Header/Container/fetchQuery/then */
            () => {
                showNotification('error', __('Error fetching top menu sections'));
            }
        );
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.showCloseButton);
    }

    showCloseButton = () => {
        if (window.scrollY > TOP_MENU_SCROLLY
            && window.getComputedStyle(this.topMenuRef.current).getPropertyValue('position') === TOP_MENU_POSITION) {
            this.setState({ showCloseTopMenu: true });
        }
    };

    closeTopMenu() {
        CSS.setVariable(this.topMenuRef, 'header-top-menu-position', 'block');
        this.setState({ showCloseTopMenu: false });
    }

    onMinicartButtonClick() {
        const {
            showOverlay,
            navigationState: { name }
        } = this.props;

        if (name === CART_OVERLAY) {
            return;
        }

        this.setState({ shouldRenderCartOverlay: true });

        showOverlay(CART_OVERLAY);
    }

    onActiveTopMenuChange(activeTopMenu) {
        this.setState({
            activeTopMenu
        });
    }

    onSearchOutsideClick(isSuggestionClick = false) {
        const {
            goToPreviousNavigationState,
            navigationState: { name },
            device
        } = this.props;

        if (!isSuggestionClick && !device.isMobile && name === SEARCH) {
            this.hideSearchOverlay();
            goToPreviousNavigationState();
        }
    }

    openSignInPopUp() {
        const { showPopup } = this.props;

        showPopup(LOGIN_ACCOUNT);
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
