/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { isCrawler } from 'SourceUtil/Browser';

import { BINGADS_HEADER_SCRIPT } from './BingAds.config';

/** @namespace BingAds/Component/BingAds/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    bingAds_enabled: state.ConfigReducer.bingAds_enabled,
    bingAds_key: state.ConfigReducer.bingAds_key
});

/** @namespace BingAds/Component/BingAds/Container/mapDispatchToProps */

export const mapDispatchToProps = () => ({});

/** @namespace BingAds/Component/BingAds/Container/BingAdsContainer */

export class BingAdsContainer extends PureComponent {
    static propTypes = {
        bingAds_enabled: PropTypes.number.isRequired,
        bingAds_key: PropTypes.string.isRequired
    };

    componentDidMount() {
        this.initialize();
    }

    /**
     * Initialize BingAds
     */
    initialize() {
        const { bingAds_enabled, bingAds_key } = this.props;

        if (bingAds_enabled && bingAds_key !== '' && !isCrawler()) {
            const existingScripts = document.getElementById(BINGADS_HEADER_SCRIPT);

            if (!existingScripts) {
                const { bingAds_key } = this.props;
                const script = document.createElement('script');

                script.id = BINGADS_HEADER_SCRIPT;

                script.innerHTML = `
                 <!-- Bing script -->
                    (function(w,d,t,r,u)
                    {
                        var f,n,i;
                        w[u]=w[u]||[],f=function()
                        {
                            var o={ti:"${ bingAds_key }", enableAutoSpaTracking: true};
                            o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
                        },
                        n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
                        {
                            var s=this.readyState;
                            s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
                        },
                        i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
                    })
                    (window,document,"script","//bat.bing.com/bat.js","uetq");`;

                document.head.insertBefore(script, document.head.childNodes[0]);
            }
        }
    }

    /**
     * Skip render
     *
     * @return {null}
     */

    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BingAdsContainer));
