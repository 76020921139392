/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_GTM_CHECKOUT = 'gtm_checkout';
export const EVENT_GTM_CHECKOUT_OPTION = 'gtm_checkout_option';
export const EVENT_GTM_IMPRESSIONS = 'gtm_impressions';
export const EVENT_GTM_IMPRESSIONS_PLP = 'gtm_impressions_plp';
export const EVENT_GTM_IMPRESSIONS_HOME = 'gtm_impressions_home';
export const EVENT_GTM_IMPRESSIONS_CROSS_SELL = 'gtm_impressions_cross_sell';
export const EVENT_GTM_IMPRESSIONS_WISHLIST = 'gtm_impressions_wishlist';
export const EVENT_GTM_IMPRESSIONS_SEARCH = 'gtm_impressions_search';
export const EVENT_GTM_IMPRESSIONS_LINKED = 'gtm_impressions_linked';
export const EVENT_GTM_META_UPDATE = 'gtm_meta_update';
export const EVENT_GTM_GENERAL_INIT = 'gtm_general_init';
export const EVENT_GTM_PRODUCT_ADD_TO_CART = 'gtm_product_add_to_cart';
export const EVENT_GTM_PRODUCT_CLICK = 'gtm_product_click';
export const EVENT_GTM_PRODUCT_DETAIL = 'gtm_product_detail';
export const EVENT_GTM_PRODUCT_REMOVE_FROM_CART = 'gtm_product_remove_from_cart';
export const EVENT_GTM_PURCHASE = 'gtm_purchase';
export const EVENT_GTM_USER_LOGIN = 'gtm_user_login';
export const EVENT_GTM_USER_REGISTER = 'gtm_user_register';

// GA4
export const EVENT_GA4_VIEW_ITEM_LIST = 'ga4_view_items_impressions';
export const EVENT_GA4_SAMPLE_ORDER = 'gtm_sample_order';
export const EVENT_GA4_PREMIUM_CUSTOMER_ORDER = 'gtm_premium_customer_order';
export const EVENT_GA4_OPEN_REQUEST_QUOTE_FORM = 'gtm_open_request_quote_form';
export const EVENT_GA4_SUBMIT_REQUEST_QUOTE_FORM = 'gtm_submit_request_quote_form';
export const EVENT_GA4_OPEN_REQUEST_PRICE_FORM = 'gtm_open_request_price_form';
export const EVENT_GA4_SUBMIT_REQUEST_PRICE_FORM = 'gtm_submit_request_price_form';
