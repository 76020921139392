import { VscClose } from 'react-icons/vsc';

import ClickOutside from 'Component/ClickOutside';
import SourcePopup from 'SourceComponent/Popup/Popup.component';
import isMobile from 'Util/Mobile';

/** @namespace ZnetPwa/Component/Popup/Component/PopupComponent */
export class PopupComponent extends SourcePopup {
    // Added + location.search because configurable rug parameters were erased on quote popup opening
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    onVisible() {
        const { onVisible } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        window.history.pushState(
            {
                popupOpen: true
            },
            '',
            location.pathname + location.search
        );

        onVisible();
    }

    renderCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ this.hidePopUp }
            >
                <VscClose size="30px" />
            </button>
        );
    }

    renderContent() {
        const { children, contentMix } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix } mods={ { IOS: isMobile.iOS() } }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    { children }
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
