/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import MenuQuery from 'Query/Menu.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { BreadcrumbsType } from 'Type/Breadcrumbs';
import history from 'Util/History';
import MenuHelper, { getSortedItems } from 'Util/Menu';

import Menu from './Menu.component';
import { HUNDRED } from './Menu.config';

/** @namespace ZnetPwa/Component/Menu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs
});

/** @namespace ZnetPwa/Component/Menu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace ZnetPwa/Component/Menu/Container/MenuContainer */
export class MenuContainer extends SourceMenuContainer {
    static propTypes = {
        ...this.propTypes,
        hideActiveOverlay: PropTypes.func.isRequired,
        breadcrumbs: BreadcrumbsType.isRequired
    };

    containerFunctions = {
        ...this.containerFunctions,
        closeMenu: this.closeMenu.bind(this),
        onMenuClick: this.onMenuClick.bind(this)
    };

    state = {
        ...this.state,
        childrenArray: [],
        menuItemOpenState: {},
        menuItemRefs: {}
    };

    menuRef = createRef();

    __construct(props) {
        super.__construct(props);

        const {
            stack: activeMenuItemsStack = []
        } = history.location.state || {};

        this.state = {
            menuItemOpenState: {},
            childrenArray: [],
            menuItemRefs: {},
            activeUrls: [],
            openKeys: [],
            selectedKeys: [],
            activeMenuItemsStack,
            menu: {}
        };
    }

    containerProps = () => {
        const {
            menuItemOpenState,
            childrenArray,
            menuItemRefs
        } = this.state;

        return {
            menuItemOpenState,
            menuItemRefs,
            childrenArray,
            menuRef: this.menuRef
        };
    };

    /** Workaround to sroll to top to solve
    * https://sepoy.atlassian.net/jira/software/c/projects/ZFR/boards/2?modal=detail&selectedIssue=ZFR-803
    * because menu is closed on sroll
    */
    onMenuClick(e) {
        e.preventDefault();
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.menuRef.current.click();
        }, HUNDRED);
    }

    setMenuState() {
        const childrenArray = this.getMenuState();

        this.setState({ childrenArray });
    }

    setMenuData() {
        this.populateHeaderStateFromStack();
        this.setMenuState();
    }

    _getMenu() {
        this.fetchData(
            [MenuQuery.getQuery(this._getMenuOptions())],
            ({ menu }) => this.setState({
                menu: MenuHelper.reduce(menu)
            }, this.setMenuData)
        );
    }

    getMenuState() {
        const { menu } = this.state;
        const categoryArray = Object.values(menu);
        const [{ children = [] } = {}] = categoryArray;

        return getSortedItems(Object.values(children));
    }

    closeMenu() {
        const {
            device
        } = this.props;

        if (device.isMobile) {
            const menu = document.getElementById('menu');

            if (menu) {
                menu.checked = false;
            }
        }

        this.setState({ activeMenuItemsStack: [] });
    }

    render() {
        return (
            <Menu
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerProps() }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
