/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const RENDER_NEWSLETTER = 'render_newsletter';
export const RENDER_CONTACT_INFO = 'render_contact_info';

export const TRACK_ORDER_HREF = '/my-account/my-orders';
export const LOGIN_HREF = '/account/login?redirect=/my-account/my-orders';

export const OPEN_HOURS_STATIC_BLOCK = 'open_hours';

export const FIVE_HUNDRED = 500;

export const NEWSLETTER_COLUMN = {
    title: __('Newsletter'),
    items: [
        {
            render: RENDER_NEWSLETTER
        }
    ]
};

export const CONTACT_COLUMN = {
    title: __('Znet Flooring'),
    items: [
        {
            render: RENDER_CONTACT_INFO
        },
        {
            href: '/contact-us',
            title: 'Email Us'
        }
    ]

};

// eslint-disable-next-line import/prefer-default-export
export const COLUMN_MAP = [
    {
        title: 'Company Info',
        items: [
            {
                href: '/contact-us',
                title: 'Contact Us'
            },
            {
                href: '/faq',
                title: 'FAQ'
            },
            {
                href: '/about-us',
                title: 'About Us'
            },
            {
                href: '/what-our-customers-say',
                title: 'What Our Customers Say'
            },
            {
                href: '/privacy-policy',
                title: 'Privacy Policy'
            },
            {
                href: '/terms-of-use',
                title: 'Terms & Conditions'
            },
            {
                href: '/blog/',
                title: 'Blog'
            }
        ]
    },
    {
        title: 'Customer Service',
        items: [
            {
                href: '/my-account/my-orders',
                title: 'Track Orders'
            },
            {
                href: '/returns',
                title: 'Returns & Exchanges'
            },
            {
                href: '/cancelled-orders',
                title: 'Cancelled Orders'
            },
            {
                href: '/ordering-policy',
                title: 'Ordering Policy'
            },
            {
                href: '/receiving-orders',
                title: 'Receiving Orders'
            },
            {
                href: '/shipping',
                title: 'Shipping Policy'
            }
        ]
    },
    {
        title: 'Customer Education',
        items: [
            {
                href: '/flooring-education/',
                title: 'Flooring Education'
            },
            {
                href: '/flooring-installation/',
                title: 'Flooring Installation'
            },
            {
                href: '/flooring-inspiration/',
                title: 'Flooring Inspiration'
            }
        ]
    },
    {
        title: 'Premium Customers',
        items: [
            {
                href: '/premium-customers',
                title: 'Become Premium'
            }
        ]
    },
    CONTACT_COLUMN
];
