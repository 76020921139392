export class UrlHandlerPlugin {
    getBypassCacheHosts = (args, callback, instance) => ([
        '(?!^.*amsociallogin)', // amsociallogin
        ...callback.apply(instance, args)
    ]);
}

const { getBypassCacheHosts } = new UrlHandlerPlugin();

export const config = {
    'SW/Handler/UrlHandler/getBypassCacheHosts': {
        function: [
            {
                position: 93,
                implementation: getBypassCacheHosts
            }
        ]
    }
};

export default config;
