/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import {
    APPLE_PAY,
    BRAINTREE,
    BRAINTREE_CONTAINER_ID,
    BRAINTREE_PAYPAL
} from '../component/Braintree/Braintree.config';
import BraintreeDropIn from '../util/Braintree';

export const braintree = new BraintreeDropIn(BRAINTREE_CONTAINER_ID);

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [
        {
            ConfigReducer: {
                braintree_cc_vault: braintreeVaultActive
            }
        }
    ] = args;

    return {
        ...callback(...args),
        braintreeVaultActive
    };
};

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/initApplePay */
export function initApplePay() {
    const {
        totals: {
            grand_total = 0,
            quote_currency_code
        } = {}
    } = this.props;

    return braintree.create(APPLE_PAY, grand_total, quote_currency_code);
}

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/initBraintree */
export function initBraintree() {
    const {
        totals: {
            grand_total = 0,
            quote_currency_code
        } = {}
    } = this.props;

    return braintree.create(BRAINTREE, grand_total, quote_currency_code);
}

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/getBraintreeData */
export function getBraintreeData() {
    const {
        totals: {
            grand_total = 0
        },
        email,
        address
    } = this.props;

    return {
        asyncData: braintree.requestPaymentNonce(grand_total, email, address)
    };
}

export const componentDidUpdate = (args, callback, instance) => {
    const {
        isPaymentError,
        togglePaymentError
    } = instance.props;
    const {
        selectedPaymentCode
    } = instance.state;

    /**
     * Checking for payment error and changing state to trigger component refresh.
     * Braintree does not support submitting the same nonce twice, this is the way to generate a new one.
     */
    if (isPaymentError) {
        togglePaymentError(false);
        instance.setState({ selectedPaymentCode: '' }, () => instance.setState({ selectedPaymentCode }));
    }

    callback.apply(instance, args);
};

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/containerFunctions */
export const containerFunctions = (originalMember, instance) => ({
    ...originalMember,
    initBraintree: initBraintree.bind(instance),
    initApplePay: initApplePay.bind(instance)
});

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/dataMap */
export const dataMap = (originalMember, instance) => ({
    ...originalMember,
    [APPLE_PAY]: getBraintreeData.bind(instance),
    [BRAINTREE_PAYPAL]: getBraintreeData.bind(instance),
    [BRAINTREE]: getBraintreeData.bind(instance)
});

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/moveApplePaymentMethod */
export function moveApplePaymentMethod(paymentMethods, index) {
    const applePay = paymentMethods.splice(index, 1);

    paymentMethods.push(applePay[0]);
}

/** @namespace BraintreeGraphql/Plugin/CheckoutPaymentsContainer/__construct */
export const __construct = (args, callback, instance) => {
    const { paymentMethods } = instance.props;
    const applePayIndex = paymentMethods.findIndex(({ code }) => code === APPLE_PAY);

    if (applePayIndex !== paymentMethods.length - 1 && applePayIndex >= 0) {
        moveApplePaymentMethod(paymentMethods, applePayIndex);
    }

    callback.apply(instance, args);
};

export default {
    'Component/CheckoutPayments/Container': {
        'member-property': {
            containerFunctions,
            dataMap
        },
        'member-function': {
            __construct,
            componentDidUpdate
        }
    },
    'Component/CheckoutPayments/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
