import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import CmsPage from 'Route/CmsPage';
import { isCrawler } from 'Util/Browser';

import { TERMS_CONDITIONS_PAGE, TERMS_POPUP } from './TermsPopup.config';

import './TermsPopup.style';

/** @namespace ZnetPwa/Component/TermsPopup/Component/TermsPopupComponent */
export class TermsPopupComponent extends PureComponent {
    render() {
        if (isCrawler()) {
            return null;
        }

        return (
            <Popup
              id={ TERMS_POPUP }
              clickOutside={ false }
              mix={ { block: 'TermsPopup' } }
            >
                <h3
                  block="TermsPopup"
                  elem="Title"
                >
                    { __('TERMS OF USE') }
                </h3>
                <CmsPage
                  pageIdentifier={ TERMS_CONDITIONS_PAGE }
                  is_terms_popup
                />
            </Popup>
        );
    }
}

export default TermsPopupComponent;
