import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STATE_CONFIRM_EMAIL, STATE_LOGGED_IN } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccount
} from 'SourceComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';

import MyAccountCreateAccount from './MyAccountCreateAccount.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace ZnetPwa/Component/MyAccountCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, dispatch)
    )
});
/** @namespace ZnetPwa/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isCaptchaEnabled: state.ConfigReducer.captcha_is_enabled
});

/** @namespace ZnetPwa/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccount {
    static propTypes = {
        ...super.propTypes,
        logout: PropTypes.func.isRequired,
        isCaptchaEnabled: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isCaptchaEnabled: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(this),
        onCreateAccountAttempt: this.onCreateAccountAttempt.bind(this),
        setToken: this.setToken.bind(this)
    };

    containerProps = () => {
        const { isFormSubmittedUnsuccessfully } = this.state;

        return {
            vatNumberValidation: this.getVatNumberValidation(),
            isFormSubmittedUnsuccessfully
        };
    };

    state = {
        ...this.state,
        isFormSubmittedUnsuccessfully: false,
        token: false
    };

    setToken(token) {
        this.setState({ token, isFormSubmittedUnsuccessfully: false });
    }

    onCreateAccountAttempt(_, invalidFields) {
        const { showNotification, setLoadingState, isCaptchaEnabled } = this.props;

        if (invalidFields) {
            showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
        }

        if (isCaptchaEnabled) {
            const {
                token
            } = this.state;

            if (!token) {
                showNotification('info', __('Missing captcha'));
                setLoadingState(false);
                return;
            }
        }

        setLoadingState(!invalidFields);
    }

    async onCreateAccountSuccess(fields) {
        const {
            logout,
            state: loginState,
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isCaptchaEnabled
        } = this.props;

        const {
            token
        } = this.state;

        if (!token && isCaptchaEnabled) {
            return;
        }

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed
        } = fields;

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                captcha_token: token
            },
            password
        };

        if (isLoading) {
            return;
        }

        try {
            if (loginState === STATE_LOGGED_IN) {
                logout();
            }
            const code = createAccount(customerData);
            code.then(
                /** @namespace ZnetPwa/Component/MyAccountCreateAccount/Container/then */
                (data) => {
                    if (data === false) {
                        this.setState({ isFormSubmittedUnsuccessfully: true });
                    }
                }
            );
            // if user needs confirmation
            if (code === 2) {
                setSignInState(STATE_CONFIRM_EMAIL);
            } else {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }

    render() {
        return (
            <MyAccountCreateAccount
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
