import {
    CmsBlockQuery as SourceCmsBlockQuery
} from 'SourceQuery/CmsBlock.query';
import { Field } from 'Util/Query';

/** @namespace ZnetPwa/Query/CmsBlock/Query/CmsBlockQuery */
export class CmsBlockQuery extends SourceCmsBlockQuery {
    getTopCmsBlocksQuery() {
        return new Field('topCmsBlocks')
            .addField(new Field('items')
                .addFieldList(['identifier', 'title', 'content']));
    }
}

export default new CmsBlockQuery();
