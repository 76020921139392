/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import PropTypes from 'prop-types';

import ApplePay from '../component/ApplePay';
import Braintree from '../component/Braintree';
import {
    APPLE_PAY,
    APPLE_PAY_VERSION,
    BRAINTREE,
    BRAINTREE_PAYPAL
} from '../component/Braintree/Braintree.config';

/** @namespace BraintreeGraphql/Plugin/CheckoutPayments/renderBraintreePayment */
export function renderBraintreePayment() {
    const {
        setLoading,
        initBraintree,
        paymentErrorMessage,
        onPaymentSavedInVaultChange,
        isSavePayment,
        braintreeVaultActive,
        selectedPaymentCode = 'braintree',
        totals: { grand_total }
    } = this.props;

    if (selectedPaymentCode === APPLE_PAY) {
        if (location.protocol !== 'https:' || !window.ApplePaySession
            // eslint-disable-next-line no-undef
            || !ApplePaySession.supportsVersion(APPLE_PAY_VERSION) || !ApplePaySession.canMakePayments()) {
            return (
                <p>Apple Pay not supported on your device</p>
            );
        }
    }

    return (
        <>
        <Braintree
          setLoading={ setLoading }
          selectedPaymentCode={ selectedPaymentCode }
          init={ initBraintree }
          onPaymentSavedInVaultChange={ onPaymentSavedInVaultChange }
          isSavePayment={ isSavePayment }
          isVaultActive={ braintreeVaultActive }
          grandTotal={ grand_total }
        />
        <p block="CheckoutPayments" elem="PaymentErrorMessage">
            { paymentErrorMessage }
        </p>
        </>
    );
}

/** @namespace BraintreeGraphql/Plugin/CheckoutPayments/propTypes */
export const propTypes = (originalMember) => ({
    ...originalMember,
    braintreeVaultActive: PropTypes.bool.isRequired,
    onPaymentSavedInVaultChange: PropTypes.func.isRequired
});

/** @namespace BraintreeGraphql/Plugin/CheckoutPayments/paymentRenderMap */
export const paymentRenderMap = (originalMember, instance) => ({
    ...originalMember,
    [APPLE_PAY]: renderBraintreePayment.bind(instance),
    [BRAINTREE_PAYPAL]: renderBraintreePayment.bind(instance),
    [BRAINTREE]: renderBraintreePayment.bind(instance)
});

export default {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap
        },
        'static-member': {
            propTypes
        }
    }
};
