export * from 'SourceRoute/CmsPage/CmsPage.config';

export const TWO_THOUSAND_MILLISECONDS = 2000;
export const CAPTCHA_BUTTON_DIV = 'captchaButtonDiv';
export const RENDERED_FORM = 'drip-form';
export const SAMPLE_CMS = 'SampleCms';
export const CERAMIC_TILE_IDENTIFIER = 'ceramic_tile_coming_soon';
export const FIVE_PERCENT_DECIMAL = 0.05;
export const THREE_HUNDRED_FORTY_NINE = 349;
export const TWO_HUNDRED_FIFTY = 250;
export const TWO_HUNDRED_SEVENTY_NINE = 279;
export const TWO_HUNDRED_NINETY_NINE = 299;
export const FORTY_NINE = 49;
export const SHAW = 'Shaw';
export const MSI = 'MSI';
export const MOHAWK = 'Mohawk';
export const KARNDEAN = 'Karndean';
export const THREE = 3;
export const HOMEPAGE_ID = 'home';
