import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace ZnetPwa/Component/Captcha/Component/CaptchaComponent */
export class CaptchaComponent extends PureComponent {
    static propTypes = {
        initScript: PropTypes.func.isRequired,
        captchaId: PropTypes.string.isRequired
    };

    render() {
        const {
            initScript,
            captchaId
        } = this.props;

        return (
            <>
                <div id={ captchaId } block="Captcha" />
                { initScript() }
            </>
        );
    }
}

export default CaptchaComponent;
