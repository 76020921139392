/** @namespace Drip/Plugin/ProductPagePlugin/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [
        {
            ConfigReducer: {
                drip: {
                    enabled: dripEnabled,
                    drip_version,
                    magento_version
                },
                secure_base_link_url
            }
        }
    ] = args;

    return {
        ...callback(...args),
        dripEnabled,
        drip_version,
        magento_version,
        secure_base_link_url
    };
};

/** @namespace Drip/Plugin/ProductPagePlugin/componentDidUpdate */
export const componentDidUpdate = (args, callback, instance) => {
    const {
        product: {
            id: product_id,
            sku,
            name,
            dynamicAttributes: {
                brand
            } = {},
            categories,
            price_range: {
                maximum_price: {
                    final_price: {
                        value: finalValue,
                        currency
                    } = {},
                    regular_price: {
                        value: regularValue
                    } = {}
                } = {}
            } = {},
            url,
            image: {
                url: image_url
            } = {}
        },
        dripEnabled,
        magento_version,
        drip_version,
        secure_base_link_url
    } = instance.props;
    const {
        product: {
            sku: prevSku
        } = {}
    } = args[0];

    if (dripEnabled !== undefined && dripEnabled && prevSku !== sku) {
        // Set is used to remove duplicates
        const concatinatedCategories = Array.from(new Set(categories?.reduce(
            (prevCategories, currentCategory) => {
                prevCategories.push(currentCategory.name);

                return prevCategories;
            }, []
        )));

        // Removing slash with slice as both parts contain it resulting in double slash
        const product_url = `${ secure_base_link_url?.slice(0, -1) }${ url }`;
        const version = `Magento ${ magento_version }, Drip Extension ${ drip_version }`;

        if (window._dcq !== undefined) {
            window._dcq.push(['track', 'Viewed a product', {
                product_id,
                sku,
                name,
                brand,
                categories: concatinatedCategories,
                price: finalValue,
                ...(finalValue < regularValue ? { compared_at_price: regularValue } : null),
                currency,
                product_url,
                image_url,
                source: 'magento',
                version
            }]);
        }
    }

    return callback(...args);
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    },
    'Route/ProductPage/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
