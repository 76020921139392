import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace ZnetPwa/Component/FieldSelect/Component/FieldSelectComponent */
export class FieldSelectComponent extends SourceFieldSelect {
    renderOption = (option) => {
        const {
            id,
            label,
            disabled
        } = option;

        const {
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick,
            value
        } = this.props;

        const isActive = label === value;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ { isExpanded, disabled, isActive } }
              key={ id }
              /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
            </li>
        );
    };

    renderVisibleMenuPlaceholder() {
        const {
            placeholder,
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick,
            selectOptions,
            value
        } = this.props;

        if (!placeholder) {
            return null;
        }

        const option = selectOptions.find((option) => option.value === value);

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ { isExpanded } }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { placeholder }
            </li>
        );
    }

    renderOptions() {
        const {
            selectOptions,
            isSelectExpanded: isExpanded
        } = this.props;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ { isExpanded } }
            >
                { this.renderVisibleMenuPlaceholder() }
                { selectOptions.map(this.renderOption) }
            </ul>
        );
    }
}

export default FieldSelectComponent;
