/* eslint-disable max-lines */
/* eslint-disable no-alert */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import { datadogRum } from '@datadog/browser-rum';

import { showNotification } from 'SourceStore/Notification/Notification.action';
import { Field } from 'Util/Query';
import { fetchMutation, fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

import { APPLE_PAY_VERSION } from '../../component/Braintree/Braintree.config';
import BraintreeQuery from '../../query/Braintree.query';

/** @namespace BraintreeGraphql/Util/Braintree/Braintree */
export class Braintree {
    isLoading = false;

    isThreeDSecure = false;

    __construct(containerId) {
        super.__construct();
        this.containerId = containerId;
        this.selectedPaymentOption = null;
    }

    async create(paymentMethod, amount, currency) {
        try {
            const { default: dropIn } = await import('braintree-web-drop-in');
            const authorization = await this.requestBraintreeClientToken();
            const configuration = await this.requestBraintreeConfig();
            const applePayConfig = await this.requestApplePayConfig();
            const payPalConfig = await this.requestPayPalIsActive();
            const { braintree_cc_isccv: isCcv = true } = await BraintreeQuery.requestBraintreeIsCvv();
            const card = {};
            this.isThreeDSecure = configuration.is_three_d_secure;

            const createProperties = {
                authorization,
                container: `#${ this.containerId }`,
                threeDSecure: this.isThreeDSecure,
                card: {
                    overrides: {
                        fields: {
                            postalCode: null
                        }
                    }
                }
            };

            if (payPalConfig.braintree_paypal_isactive) {
                createProperties.paypal = {
                    flow: 'checkout',
                    amount,
                    currency
                };
            }

            if (isCcv) {
                card.overrides = {
                    fields: {
                        number: {
                            maskInput: {
                                showLastFour: true
                            }
                        },
                        cvv: {
                            maskInput: true
                        },
                        postalCode: null
                    }
                };
            }

            if (Object.entries(card)) {
                createProperties.card = card;
            }

            if (applePayConfig.braintree_applepay_isactive && location.protocol === 'https:' && window.ApplePaySession
            // eslint-disable-next-line no-undef
            && ApplePaySession.supportsVersion(APPLE_PAY_VERSION) && ApplePaySession.canMakePayments()) {
                const paymentRequest = {
                    total: {
                        label: applePayConfig.braintree_applepay_merchant_name,
                        amount
                    }
                };

                createProperties.applePay = {
                    displayName: applePayConfig.braintree_applepay_merchant_name,
                    paymentRequest
                };
            }

            this.braintreeDropIn = await dropIn.create(createProperties);

            // Handle payment option selection
            this.braintreeDropIn.on('paymentOptionSelected', ({ paymentOption }) => {
                console.log('paymentOptionSelected', paymentOption);
                this.selectedPaymentOption = paymentOption;
            });

            return true;
        } catch (error) {
            const store = getStore();
            const { dispatch } = store;

            if (window.location.pathname.includes('/checkout/billing')) {
                dispatch(showNotification('error', __('Failed to load payment methods, please reload the page!')));
            }

            console.error('Error creating BraintreeDropIn:', error);
            return false; // Return false if an error occurs during the asynchronous operations
        }
    }

    requestBraintreeConfig() {
        const query = new Field('getBraintreeConfig').addFieldList(['is_three_d_secure']);
        return fetchQuery(query).then(
            /** @namespace BraintreeGraphql/Util/Braintree/fetchQuery/then */
            ({ getBraintreeConfig }) => getBraintreeConfig,
            /** @namespace BraintreeGraphql/Util/Braintree/fetchQuery/then */
            (err) => {
                const state = getStore().getState();
                const {
                    datadog_enabled = false
                } = state.ConfigReducer;

                if (datadog_enabled) {
                    const message = err?.[0]?.debugMessage
                        || err?.[0]?.message
                        || 'Error requesting Braintree config is_three_d_secure';
                    const errorMessage = new Error(message);
                    datadogRum.addError(errorMessage);
                }
            }
        );
    }

    requestApplePayConfig() {
        const query = new Field('storeConfig').addFieldList(
            ['braintree_applepay_merchant_name', 'braintree_applepay_isactive']
        );

        return fetchQuery(query).then(
            /** @namespace BraintreeGraphql/Util/Braintree/fetchQuery/then */
            ({ storeConfig }) => storeConfig,
            /** @namespace BraintreeGraphql/Util/Braintree/fetchQuery/then */
            (err) => {
                const state = getStore().getState();
                const {
                    datadog_enabled = false
                } = state.ConfigReducer;

                if (datadog_enabled) {
                    const message = err?.[0]?.debugMessage
                        || err?.[0]?.message
                        || 'Error requesting Braintree config applepay';
                    const errorMessage = new Error(message);
                    datadogRum.addError(errorMessage);
                }
            }
        );
    }

    requestPayPalIsActive() {
        const query = new Field('storeConfig').addFieldList(
            ['braintree_paypal_isactive']
        );

        return fetchQuery(query).then(
            /** @namespace BraintreeGraphql/Util/Braintree/fetchQuery/then */
            ({ storeConfig }) => storeConfig,
            /** @namespace BraintreeGraphql/Util/Braintree/fetchQuery/then */
            (err) => {
                const state = getStore().getState();
                const {
                    datadog_enabled = false
                } = state.ConfigReducer;

                if (datadog_enabled) {
                    const message = err?.[0]?.debugMessage
                        || err?.[0]?.message
                        || 'Error requesting Braintree config paypal';
                    const errorMessage = new Error(message);
                    datadogRum.addError(errorMessage);
                }
            }
        );
    }

    requestBraintreeClientToken() {
        const mutation = (new Field('createBraintreeClientToken')).setAlias('token');
        return fetchMutation(mutation).then(
            /** @namespace BraintreeGraphql/Util/Braintree/fetchMutation/then */
            ({ token }) => token
        );
    }

    convertAddressToBillingAddress(address) {
        const {
            firstname = '',
            lastname = '',
            telephone = '',
            street0 = '',
            street1 = '',
            city = '',
            postcode = '',
            country_id = '',
            region_code = ''
        } = address || {};

        return {
            givenName: firstname,
            surname: lastname,
            phoneNumber: telephone,
            streetAddress: street0,
            extendedAddress: street1,
            locality: city,
            region: region_code,
            postalCode: postcode,
            countryCodeAlpha2: country_id
        };
    }

    requestPaymentNonce(amount, email, address) {
        const requestData = this.isThreeDSecure ? {
            threeDSecure: {
                amount,
                email,
                billingAddress: this.convertAddressToBillingAddress(address)
            }
        } : {};

        if (!this.braintreeDropIn) {
            const store = getStore();
            const { dispatch } = store;

            dispatch(showNotification('error', __('Failed to load payment methods, please reload the page!')));
            console.error('BraintreeDropIn is not initialized');

            return Promise.reject();
        }

        return this.braintreeDropIn.requestPaymentMethod(requestData);
    }
}

export default Braintree;
