/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import { datadogRum } from '@datadog/browser-rum';

import { Field } from 'Util/Query';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

/** @namespace BraintreeGraphql/Query/Braintree/Query/BraintreeQuery */
export class BraintreeQuery {
    async requestBraintreeIsCvv() {
        const query = new Field('storeConfig').addFieldList(['braintree_cc_isccv']);
        const state = getStore().getState();
        const {
            datadog_enabled = false
        } = state.ConfigReducer;

        return fetchQuery(query).then(
            /** @namespace BraintreeGraphql/Query/Braintree/Query/fetchQuery/then */
            ({ storeConfig }) => storeConfig,
            /** @namespace BraintreeGraphql/Query/Braintree/Query/fetchQuery/then */
            (err) => {
                if (datadog_enabled) {
                    const message = err?.[0]?.debugMessage
                        || err?.[0]?.message
                        || 'Error requesting Braintree data';
                    const errorMessage = new Error(message);
                    datadogRum.addError(errorMessage);
                }
            }
        );
    }
}

export default new BraintreeQuery();
