/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import {
    PureComponent
} from 'react';
import { connect } from 'react-redux';

import { TYPE_CATEGORY } from 'SourceRoute/UrlRewrites/UrlRewrites.config';
import { DeviceType } from 'Type/Device';

import Footer from './Footer.component';
import { FIVE_HUNDRED } from './Footer.config';

/** @namespace ZnetPwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
    copyright: state.ConfigReducer.copyright,
    device: state.ConfigReducer.device,
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
    isUrlRewriteLoading: state.UrlRewritesReducer.isLoading,
    prerenderDelayActions: state.PrerenderReducer
});

/** @namespace ZnetPwa/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace ZnetPwa/Component/Footer/Container/FooterContainer */
export class FooterContainer extends PureComponent {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        copyright: PropTypes.string.isRequired,
        device: DeviceType.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        prerenderDelayActions: PropTypes.object.isRequired,
        urlRewrite: PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.string,
            sku: PropTypes.string,
            notFound: PropTypes.bool
        }).isRequired,
        isUrlRewriteLoading: PropTypes.bool.isRequired
    };

    containerFunctions = {
        scrollToTop: this.scrollToTop.bind(this)
    };

    state = {
        renderFooter: false
    };

    // Timer used for request throttling.
    timer = null;

    componentDidMount() {
        this.isLoadingCompleted();
    }

    containerProps() {
        const {
            isSignedIn,
            copyright,
            device
        } = this.props;

        return {
            isSignedIn,
            copyright,
            device
        };
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    checkActionObject() {
        const { prerenderDelayActions } = this.props;

        return Object.values(prerenderDelayActions).every((action) => action === true);
    }

    isCategoryPageAndIsLoaded() {
        const {
            prerenderDelayActions: {
                pageRenderCATEGORY = false
            } = {},
            isUrlRewriteLoading,
            urlRewrite: {
                type,
                notFound
            } = {}
        } = this.props;

        if (!isUrlRewriteLoading) {
            if (notFound) {
                return true;
            }

            if (type === TYPE_CATEGORY) {
                if (pageRenderCATEGORY) {
                    return true;
                }
            } else {
                return true;
            }
        }

        return false;
    }

    isLoadingCompleted() {
        // Throttle the requests to prevent sending ready message after one request finishes.
        window.clearTimeout(this.timer);
        this.timer = window.setTimeout(() => {
            if (this.checkActionObject() && this.isCategoryPageAndIsLoaded()) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ renderFooter: true });
            } else {
                this.isLoadingCompleted();
            }
        }, FIVE_HUNDRED);
    }

    render() {
        const {
            renderFooter
        } = this.state;

        if (!renderFooter) {
            return null;
        }

        return (
            <Footer
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
