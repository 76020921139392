/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Braintree from './Braintree.component';
import { APPLE_PAY, BRAINTREE, BRAINTREE_PAYPAL } from './Braintree.config';

/** @namespace BraintreeGraphql/Component/Braintree/Container/BraintreeContainer */
export class BraintreeContainer extends PureComponent {
    static propTypes = {
        setLoading: PropTypes.func.isRequired,
        init: PropTypes.func.isRequired,
        onPaymentSavedInVaultChange: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        isSavePayment: PropTypes.bool,
        isVaultActive: PropTypes.bool,
        grandTotal: PropTypes.number.isRequired
    };

    static defaultProps = {
        isSavePayment: false,
        isVaultActive: false
    };

    state = {
        isLoading: true
    };

    componentDidMount() {
        const { init, grandTotal, setLoading } = this.props;

        setLoading(true);

        init(grandTotal).then(
            /** @namespace BraintreeGraphql/Component/Braintree/Container/init/then */
            () => {
                // Forcing card option to be opened as users miss payments
                // Ref: https://sepoy.atlassian.net/browse/ZFR-1827
                document.getElementsByClassName('braintree-option__card')?.[0]?.click();
                this.setState({ isLoading: false });
                setLoading(false);
            },
            /** @namespace BraintreeGraphql/Component/Braintree/Container/init/then */
            () => {
                this.setState({ isLoading: false });
                setLoading(false);
            }
        );
    }

    containerProps() {
        const { isLoading } = this.state;
        const {
            onPaymentSavedInVaultChange,
            isSavePayment,
            isVaultActive,
            grandTotal,
            selectedPaymentCode
        } = this.props;

        return {
            isLoading,
            onPaymentSavedInVaultChange,
            isSavePayment,
            isVaultActive,
            grandTotal,
            selectedPaymentCode
        };
    }

    render() {
        return (
            <Braintree
              { ...this.containerProps() }
            />
        );
    }
}

export default BraintreeContainer;
