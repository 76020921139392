/* eslint-disable max-lines */
import { FLOORING_ATTRIBUTE } from 'Component/CartItem/CartItem.config';
import { ACCESSORIES_ATTRIBUTE_SET_ID } from 'Component/ProductCard/ProductCard.config';
import { DISCONTINUED_PRODUCT_LABELS } from 'Component/RequestQuoteButtons/RequestQuoteButtons.config';
import {
    BUNDLE,
    CONFIGURABLE,
    DOWNLOADABLE,
    SIMPLE
} from 'Util/Product';

/**
 * Returns correct product object using data from SS.
 * @param {String} discontinued_product
 * @param {String} ss_in_stock
 * @returns {Product}
 * @namespace ZnetPwa/Util/Product/getStockMeta */
export const getStockMeta = (discontinued_product, ss_in_stock) => ((ss_in_stock === '1' && !DISCONTINUED_PRODUCT_LABELS.includes(discontinued_product)) ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock');

/**
 * Returns correct product object using data from SS.
 * @param {Product} product
 * @returns {Product}
 * @namespace ZnetPwa/Util/Product/createProductObject
*/
export const createProductObject = (product) => {
    const {
        brand,
        collection_name,
        collection_color,
        plank_width,
        flooring_type,
        id,
        name,
        type_id,
        swatch_image,
        url,
        image,
        special_price,
        special_unit_price,
        unit_price,
        sku,
        require_form_to_add_to_cart,
        request_quote_enabled,
        show_price,
        attribute_set_id,
        coverage_area_type,
        rug_sizes,
        rug_color,
        parent_rug_color,
        regular_price,
        final_price,
        discontinued_product,
        ss_in_stock
    } = product;

    const isSpecialPrice = special_price
        && special_price !== undefined
        && special_unit_price !== undefined
        && parseFloat(special_unit_price) < parseFloat(unit_price);
    const accessoryUnitPrice = attribute_set_id === ACCESSORIES_ATTRIBUTE_SET_ID ? regular_price : null;

    // eslint-disable-next-line fp/no-let
    let price_label = isSpecialPrice || require_form_to_add_to_cart === 'Yes' ? __('Price In Cart') : '';
    price_label = request_quote_enabled === 'Yes' ? __('Price In Quote') : price_label;

    const flooring_type_modified = flooring_type && flooring_type?.length ? flooring_type[0] : plank_width;
    const modifiedSwatchUrl = `/media/catalog/product${ swatch_image }`;

    return {
        id,
        name: name?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&'),
        type_id,
        small_image: {
            url: image
        },
        swatch: {
            url: modifiedSwatchUrl
        },
        sku,
        url,
        attributes: {
            show_price
        },
        dynamicAttributes: {
            brand: brand?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&'),
            price_label,
            collection_name: collection_name?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&'),
            collection_color: collection_color?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&'),
            request_quote_enabled,
            require_form_to_add_to_cart,
            plank_width: plank_width?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&'),
            flooring_type: flooring_type_modified?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&'),
            special_price,
            unit_price: accessoryUnitPrice || unit_price,
            discontinued_product
        },
        rugAttributes: {
            rug_sizes,
            rug_color: rug_color?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&'),
            regular_price,
            parent_rug_color: parent_rug_color?.replace(/&quot;/g, '"')?.replace(/&amp;/g, '&')
        },
        attribute_set_id,
        coverage_area_type,
        final_price,
        regular_price,
        stockMeta: getStockMeta(discontinued_product, ss_in_stock)
    };
};

/**
 * Checks if the product is sample product.
 * @param {String} sku
 * @returns {boolean}
 * @namespace ZnetPwa/Util/Product/isSample */
export const isSample = (sku) => sku?.includes('-sample');

/**
 * Checks cart to mark isInstallationStatusSelected to true if there are only products
 * from Accessories or Rug attribute sets
 * @param {Object} cartTotals
 * @returns {boolean}
 * @namespace ZnetPwa/Util/Product/isOnlyRugsAccessories
 */
export const isOnlyRugsAccessories = (cartTotals = {}) => {
    const {
        items = []
    } = cartTotals;

    return !items.find((item) => {
        const attributeSet = item.product.dynamicAttributes.attribute_set_name;

        return attributeSet === FLOORING_ATTRIBUTE;
    });
};

/**
 * Checks whether every option is in attributes
 * @param {Object} attributes
 * @param {{ attribute_code: string }[]} options
 * @returns {boolean}
 * @namespace ZnetPwa/Util/Product/checkEveryOption */
export const checkEveryOption = (attributes, options) => Object.keys(options)
    .every((option) => {
        if (!attributes[option]) {
            return false;
        }

        // Adjusted to account for attribute label used in the URL not value
        const { attribute_value, attribute_options } = attributes[option];
        const attributeLabel = attribute_options[attribute_value]?.label;
        if (typeof options[option] === 'string') {
            return options[option].replace(/\+/g, ' ') === attributeLabel;
        }

        return options[option].includes(attributeLabel);
    });

/** @namespace ZnetPwa/Util/Product/getIndexedAttributeOption */
export const getIndexedAttributeOption = (option) => {
    const { swatch_data: defaultSwatchData } = option;
    if (!defaultSwatchData) {
        return option;
    }

    const { type } = defaultSwatchData;
    const swatch_data = type ? defaultSwatchData : null;

    return {
        ...option,
        swatch_data
    };
};

/** @namespace ZnetPwa/Util/Product/getIndexedAttributes */
export const getIndexedAttributes = (attributes) => attributes.reduce((indexedAttributes, attribute) => {
    const { attribute_code, attribute_options = [] } = attribute;

    return {
        ...indexedAttributes,
        [attribute_code]: {
            ...attribute,
            attribute_options: attribute_options.reduce((acc, option) => {
                const { value } = option;

                return {
                    ...acc,
                    [value]: getIndexedAttributeOption(option)
                };
            }, {})
        }
    };
}, {});

/** @namespace ZnetPwa/Util/Product/getIndexedConfigurableOptions */
export const getIndexedConfigurableOptions = (configurableOptions, indexedAttributes) => (
    configurableOptions.reduce((indexedConfigurableOptions, configurableOption) => {
        const { values, attribute_code } = configurableOption;

        return {
            ...indexedConfigurableOptions,
            [attribute_code]: {
                ...configurableOption,
                ...indexedAttributes[attribute_code],
                attribute_values: values.map(({ value_index }) => `${ value_index }`)
            }
        };
    }, {})
);

/** @namespace ZnetPwa/Util/Product/getIndexedVariants */
export const getIndexedVariants = (variants) => variants.map(({ product }) => {
    const { attributes } = product;
    return {
        ...product,
        attributes: getIndexedAttributes(attributes || [])
    };
});

/** @namespace ZnetPwa/Util/Product/getIndexedSingleVariant */
export const getIndexedSingleVariant = (variants, itemSku) => {
    const index = variants.findIndex(({ product: { sku } }) => sku === itemSku || itemSku.includes(sku));

    if (index < 0) {
        return getIndexedVariants(variants);
    }

    const indexedProduct = variants[index].product;
    const { attributes } = indexedProduct;

    return [
        { ...indexedProduct, attributes: getIndexedAttributes(attributes || []) }
    ];
};

/**
 * Get product variant index by options
 * @param {Object[]} variants
 * @param {{ attribute_code: string }[]} options
 * @returns {number}
 * @namespace ZnetPwa/Util/Product/getVariantIndex */
export const getVariantIndex = (variants, options) => variants
    .findIndex((variant) => checkEveryOption(variant.attributes, options));

/** @namespace ZnetPwa/Util/Product/getVariantsIndexes */
export const getVariantsIndexes = (variants, options) => Object.entries(variants)
    .reduce((indexes, [index, variant]) => {
        if (checkEveryOption(variant.attributes, options)) {
            indexes.push(+index);
        }

        return indexes;
    }, []);

/** @namespace ZnetPwa/Util/Product/getIndexedCustomOption */
export const getIndexedCustomOption = (option) => {
    const {
        checkboxValues,
        dropdownValues,
        fieldValues,
        areaValues,
        fileValues,
        ...otherFields
    } = option;

    if (checkboxValues) {
        const data = Array.isArray(checkboxValues) ? checkboxValues : [checkboxValues];
        return { type: 'checkbox', data, ...otherFields };
    }

    if (dropdownValues) {
        const data = Array.isArray(dropdownValues) ? dropdownValues : [dropdownValues];
        return { type: 'dropdown', data, ...otherFields };
    }

    if (fieldValues) {
        const data = Array.isArray(fieldValues) ? fieldValues : [fieldValues];
        return { type: 'field', data, ...otherFields };
    }

    if (areaValues) {
        const data = Array.isArray(areaValues) ? areaValues : [areaValues];
        return { type: 'area', data, ...otherFields };
    }

    if (fileValues) {
        const data = Array.isArray(fileValues) ? fileValues : [fileValues];
        return { type: 'file', data, ...otherFields };
    }

    // skip unsupported types
    return null;
};

/** @namespace ZnetPwa/Util/Product/getIndexedCustomOptions */
export const getIndexedCustomOptions = (options) => options.reduce(
    (acc, option) => {
        const indexedOption = getIndexedCustomOption(option);

        if (indexedOption) {
            acc.push(indexedOption);
        }

        return acc;
    },
    []
);

/** @namespace ZnetPwa/Util/Product/getIndexedReviews */
export const getIndexedReviews = (reviews) => {
    if (!reviews) {
        return null;
    }

    const { items } = reviews;
    const ONE_FIFTH_OF_A_HUNDRED = 20;

    return items.reduce((acc, review) => {
        const { rating_votes = [], ...restOfReview } = review;

        const newRatingVotes = rating_votes.reduce((acc, vote) => {
            const { rating_code, value } = vote;

            return [
                ...acc,
                {
                    rating_code,
                    value,
                    // stars / 5 * 100 to get percent
                    percent: value * ONE_FIFTH_OF_A_HUNDRED
                }
            ];
        }, []);

        return [
            ...acc,
            {
                ...restOfReview,
                rating_votes: newRatingVotes
            }
        ];
    }, []);
};

/** @namespace ZnetPwa/Util/Product/getIndexedProduct */
export const getIndexedProduct = (product, itemSku) => {
    const {
        variants: initialVariants = [],
        configurable_options: initialConfigurableOptions = [],
        attributes: initialAttributes = [],
        options: initialOptions = [],
        rating_summary,
        review_count,
        reviews: initialReviews
    } = product;

    const attributes = getIndexedAttributes(initialAttributes || []);
    const reviews = getIndexedReviews(initialReviews);

    return {
        ...product,
        configurable_options: getIndexedConfigurableOptions(initialConfigurableOptions, attributes),
        variants: itemSku ? getIndexedSingleVariant(initialVariants, itemSku) : getIndexedVariants(initialVariants),
        options: getIndexedCustomOptions(initialOptions || []),
        attributes,
        // Magento 2.4.1 review endpoint compatibility
        reviews,
        review_summary: {
            rating_summary,
            review_count
        }
    };
};

/** @namespace ZnetPwa/Util/Product/getIndexedProducts */
export const getIndexedProducts = (products) => products.map((product) => getIndexedProduct(product));

/** @namespace ZnetPwa/Util/Product/getIndexedParameteredProducts */
export const getIndexedParameteredProducts = (products) => Object.entries(products)
    .reduce((products, [id, product]) => ({
        ...products,
        [id]: getIndexedProduct(product)
    }), {});

/** @namespace ZnetPwa/Util/Product/getExtensionAttributes */
export const getExtensionAttributes = (product) => {
    const {
        configurable_options,
        configurableVariantIndex,
        productOptions,
        productOptionsMulti,
        downloadableLinks,
        variants,
        type_id
    } = product;

    if (type_id === CONFIGURABLE) {
        const { attributes = {} } = variants[configurableVariantIndex] || {};
        const properties = {
            configurable_item_options: Object.values(configurable_options)
                .reduce((prev, { attribute_id, attribute_code }) => {
                    const {
                        attribute_value,
                        attribute_id: attrId
                    } = attributes[attribute_code] || {};

                    if (attribute_value) {
                        return [
                            ...prev,
                            {
                                option_id: attribute_id || attrId,
                                option_value: attribute_value
                            }
                        ];
                    }

                    return prev;
                }, [])
        };

        if (productOptions) {
            properties.customizable_options = productOptions;
        }
        if (productOptionsMulti) {
            properties.customizable_options_multi = productOptionsMulti;
        }

        return properties;
    }

    if (type_id === BUNDLE && (productOptions || productOptionsMulti)) {
        return { bundle_options: Array.from(productOptions || []) };
    }

    if (type_id === SIMPLE && (productOptions || productOptionsMulti)) {
        return {
            customizable_options: productOptions || [],
            customizable_options_multi: productOptionsMulti || []
        };
    }

    if (type_id === DOWNLOADABLE && downloadableLinks) {
        return {
            downloadable_product_links: downloadableLinks
        };
    }

    return {};
};
