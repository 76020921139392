/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_CATEGORY_META } from './CategoryMeta.action';

export const emptyCategoryMeta = {
    meta_title: '',
    meta_description: '',
    meta_keywords: ''
};

/** @namespace Blog/Store/CategoryMeta/Reducer/getInitialState */
export const getInitialState = () => ({
    categoryMeta: emptyCategoryMeta
});

/** @namespace Blog/Store/CategoryMeta/Reducer/CategoryMetaReducer */
export const CategoryMetaReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_CATEGORY_META:
        const { categoryMeta } = action;

        return {
            ...state,
            categoryMeta
        };
    default:
        return state;
    }
};

export default CategoryMetaReducer;
