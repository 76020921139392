/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import BingAds from '../component/BingAds';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const addBingAdsComponent = (member) => [
    ...member,
    {
        component: <BingAds />,
        position: 131
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addBingAdsComponent
        }
    }
};
