import { SWATCHES } from 'Component/ProductListPage/ProductListPage.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import { SET_SWATCHES_LOADING, UPDATE_SWATCHES } from './ProductListPage.action';
import {
    SWATCH_TYPE_IMAGE
} from './ProductListPage.config';

/** @namespace ZnetPwa/Store/ProductListPage/Reducer/getInitialState */
export const getInitialState = () => ({
    simpleProductSwatches: BrowserDatabase.getItem(SWATCHES) || {},
    isSwatchesLoading: true
});

/** @namespace ZnetPwa/Store/ProductListPage/Reducer/ProductListPageReducer */
export const ProductListPageReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_SWATCHES_LOADING:
        return {
            ...state,
            isSwatchesLoading: true
        };

    case UPDATE_SWATCHES:
        const {
            payload
        } = action;

        if (payload) {
            const { simpleProductSwatches } = state;
            const updatedSwatches = { ...simpleProductSwatches };

            const config = BrowserDatabase.getItem('config');
            const placeholderImageUrl = config?.storeConfig?.placeholder_image;

            payload.map(
                (elem) => {
                    const { sku, product_swatches: { items = [] } } = elem;

                    const swatches = items.map((swatch) => ({
                        sku: swatch.sku,
                        url: swatch.url,
                        label: swatch.name,
                        price: swatch?.dynamicAttributes?.price,
                        priceLabel: swatch?.dynamicAttributes?.price_label,
                        type: SWATCH_TYPE_IMAGE,
                        value: swatch?.swatchImages?.swatch || placeholderImageUrl
                    }));

                    updatedSwatches[sku] = swatches;

                    return '';
                }
            );

            return {
                ...state,
                isSwatchesLoading: false,
                simpleProductSwatches: updatedSwatches
            };
        }

        return {
            ...state,
            isSwatchesLoading: false
        };
    default:
        return state;
    }
};

export default ProductListPageReducer;
