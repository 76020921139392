/**
 * @description Custom product list query builder for cart feature purposes
 * @author      C. M. de Picciotto <cmdepicciotto@sepoy.io>
 * @link        https://sepoy.atlassian.net/browse/ZFR-1777
 */

import {
    ProductListQuery as SourceProductListQuery
} from 'Query/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace ZnetPwa/Query/CartProductList/Query/CartProductListQuery */
export class CartProductListQuery extends SourceProductListQuery {
    /**
     * Get attribute field
     *
     * @param   {boolean} isVariant
     * @returns {Field}
     * @private
     * @note    Customization to use more performant `s_attributes_v2` field instead of `s_attribute`
     */
    _getAttributesField(isVariant) {
        return new Field('s_attributes_v2')
            .setAlias('attributes')
            .addFieldList(this._getAttributeFields(isVariant));
    }
}

export default new CartProductListQuery();
