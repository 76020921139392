import PropTypes from 'prop-types';

export const facetMapType = PropTypes.objectOf(PropTypes.elementType);

export const ConfigurableProductSwatchType = PropTypes.objectOf(
    PropTypes.shape({
        products: PropTypes.arrayOf(
            PropTypes.shape({
                sku: PropTypes.string,
                url: PropTypes.string,
                name: PropTypes.string,
                swatchImageUrl: PropTypes.string,
                attribute: PropTypes.string
            })
        ),
        minPrice: PropTypes.number,
        maxPrice: PropTypes.number
    })
);
