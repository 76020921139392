import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common';

import TooltipComponent from './Tooltip.component';

/** @namespace ZnetPwa/Component/Tooltip/Container/TooltipContainer */
export class TooltipContainer extends PureComponent {
    static propTypes = {
        tooltipText: PropTypes.string.isRequired,
        render: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        children: PropTypes.node,
        mix: MixType
    };

    static defaultProps = {
        mix: {},
        children: {}
    };

    state = {
        isShowing: false
    };

    containerFunctions = {
        onClick: this.onClick.bind(this),
        onClickOutside: this.onClickOutside.bind(this),
        preventClick: this.preventClick.bind(this)
    };

    containerProps = () => {
        const {
            tooltipText,
            render,
            title,
            mix,
            children
        } = this.props;

        const { isShowing } = this.state;

        return {
            children,
            tooltipText,
            render,
            title,
            mix,
            isShowing
        };
    };

    onClick(e) {
        const { isShowing } = this.state;

        this.setState({
            isShowing: !isShowing
        });

        this.preventClick(e);
    }

    onClickOutside() {
        this.setState({
            isShowing: false
        });
    }

    preventClick(e) {
        /** On mobile we cannot hover tooltip, so the click is needed
         * some tooltips are nested in a tags that triggers click,
         * therefore we prevent it
         */
        e.stopPropagation();
        e.preventDefault();
    }

    render() {
        return (
            <TooltipComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default TooltipContainer;
