import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscription
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';
import { showNotification } from 'Store/Notification/Notification.action';

/** @namespace ZnetPwa/Component/NewsletterSubscription/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});
/** @namespace ZnetPwa/Component/NewsletterSubscription/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isCaptchaEnabled: state.ConfigReducer.captcha_is_enabled
});

/** @namespace ZnetPwa/Component/NewsletterSubscription/Container/NewsletterSubscriptionContainer */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscription {
    static propTypes = {
        ...super.propTypes,
        showNotification: PropTypes.func.isRequired,
        isCaptchaEnabled: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isCaptchaEnabled: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        setToken: this.setToken.bind(this)
    };

    state = {
        ...this.state,
        token: false
    };

    setToken(token) {
        this.setState({ token });
    }

    onFormSubmit(fields) {
        const { subscribeToNewsletter, isCaptchaEnabled, showNotification } = this.props;
        const { newsletterEmail: email } = fields;

        this.setState({ isLoading: true });
        const {
            token
        } = this.state;

        if (isCaptchaEnabled) {
            if (!token) {
                showNotification('info', __('Missing captcha'));
                this.setState({ isLoading: false });
                return;
            }
        }

        subscribeToNewsletter({ email, token })
            .then(this.onFormSubmitDone)
            .catch(this.onFormSubmitDone);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterSubscriptionContainer);
