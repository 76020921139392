/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © ScandiPWA, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author Akim Goncar <info@scandiweb.com>
 */

import PropTypes from 'prop-types';

import MyAccountCreateAccount from 'Component/MyAccountCreateAccount';
import MyAccountSignIn from 'Component/MyAccountSignIn';

import SocialAuthentication from '../component/SocialAuthentication';
import {
    ABOVE_LOGIN,
    ABOVE_REGISTRATION,
    BELOW_LOGIN,
    BELOW_REGISTRATION
} from '../component/SocialAuthentication/SocialAuthentication.config';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        amastySocialLoginPosition: state.ConfigReducer.amastySocialLoginPosition
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    amastySocialLoginPosition: PropTypes.string.isRequired
});

export class MyAccountOverlayPlugin {
    renderCreateAccount = (args, callback, instance) => {
        const {
            state,
            handleSignIn,
            setSignInState,
            setLoadingState,
            onSignIn,
            amastySocialLoginPosition
        } = instance.props;

        if (amastySocialLoginPosition?.includes(BELOW_REGISTRATION)) {
            return (
                <>
                <SocialAuthentication />
                <MyAccountCreateAccount
                  state={ state }
                  handleSignIn={ handleSignIn }
                  setLoadingState={ setLoadingState }
                  setSignInState={ setSignInState }
                  onSignIn={ onSignIn }
                />
                </>
            );
        }

        if (amastySocialLoginPosition?.includes(ABOVE_REGISTRATION)) {
            return (
                <>
                <SocialAuthentication />
                <MyAccountCreateAccount
                  state={ state }
                  handleSignIn={ handleSignIn }
                  setLoadingState={ setLoadingState }
                  setSignInState={ setSignInState }
                  onSignIn={ onSignIn }
                />
                </>
            );
        }

        return (
            <MyAccountCreateAccount
              state={ state }
              handleSignIn={ handleSignIn }
              setLoadingState={ setLoadingState }
              setSignInState={ setSignInState }
              onSignIn={ onSignIn }
            />
        );
    };

    renderSignIn = (args, callback, instance) => {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            onSignIn,
            amastySocialLoginPosition
        } = instance.props;

        if (amastySocialLoginPosition?.includes(BELOW_LOGIN)) {
            return (
                <>
                <MyAccountSignIn
                  state={ state }
                  onFormError={ onFormError }
                  handleForgotPassword={ handleForgotPassword }
                  handleCreateAccount={ handleCreateAccount }
                  isCheckout={ isCheckout }
                  setLoadingState={ setLoadingState }
                  onSignIn={ onSignIn }
                />
                <SocialAuthentication />
                </>
            );
        }

        if (amastySocialLoginPosition?.includes(ABOVE_LOGIN)) {
            return (
                <>
                <SocialAuthentication />
                <MyAccountSignIn
                  state={ state }
                  onFormError={ onFormError }
                  handleForgotPassword={ handleForgotPassword }
                  handleCreateAccount={ handleCreateAccount }
                  isCheckout={ isCheckout }
                  setLoadingState={ setLoadingState }
                  onSignIn={ onSignIn }
                />
                </>
            );
        }

        return (
            <MyAccountSignIn
              state={ state }
              onFormError={ onFormError }
              handleForgotPassword={ handleForgotPassword }
              handleCreateAccount={ handleCreateAccount }
              isCheckout={ isCheckout }
              setLoadingState={ setLoadingState }
              onSignIn={ onSignIn }
            />
        );
    };
}

export const { renderCreateAccount, renderSignIn } = new MyAccountOverlayPlugin();

export default {
    'Component/MyAccountOverlay/Component': {
        'member-function': {
            renderCreateAccount: [
                {
                    position: 126,
                    implementation: renderCreateAccount
                }
            ],
            renderSignIn: [
                {
                    position: 126,
                    implementation: renderSignIn
                }
            ]
        },
        'static-member': {
            propTypes
        }
    },
    'Component/MyAccountOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
