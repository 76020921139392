/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { createPortal } from 'react-dom';

import { TITLE_SUFFIX } from 'Route/ProductPage/ProductPage.config';
import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace ZnetPwa/Component/Meta/Component/MetaComponent */
export class MetaComponent extends SourceMeta {
    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        if (title && title !== '' && title !== TITLE_SUFFIX) {
            return (
                <title>
                    { `${ titlePrefix }${ title || default_title }${ titleSuffix }` }
                </title>
            );
        }

        return null;
    }

    renderMeta() {
        const {
            metadata
        } = this.props;

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    // Check if the tag is an Open Graph tag
                    const isOGTag = tag.name && tag.name.startsWith('og:');

                    // Create a new object for tag attributes, replace 'name' with 'property' for OG tags
                    const tagAttributes = isOGTag
                        ? { property: tag.name, content: tag.content }
                        : { ...tag };

                    return <meta key={ tag.name } { ...tagAttributes } />;
                }) }
            </>
        );
    }

    render() {
        return createPortal(
            { ...this.renderMeta() },
            document.head
        );
    }
}

export default MetaComponent;
