import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import WishlistDispatcher from 'Store/Wishlist/Wishlist.dispatcher';
import { setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import {
    urlToSearchState
} from 'Util/SearchSpring';
import { appendWithStoreCode } from 'Util/Url';

export class RouterContainerPlugin {
    // Workaround for accessing original instance's
    // State and props within the event handler function
   originalInstance = null;

    componentDidMount = (args, callback = () => {}, instance) => {
        this.originalInstance = instance;

        window.addEventListener('message', this.socialLoginEventHandler);

        callback.apply(instance, args);
    };

    componentWillUnmount = (args, callback = () => {}, instance) => {
        window.removeEventListener('message', this.socialLoginEventHandler);

        callback.apply(instance, args);
    };

    __construct() {
        this.socialLoginEventHandler = this.socialLoginEventHandler.bind(this);
    }

    socialLoginEventHandler = (event) => {
        if (!this.originalInstance) {
            return;
        }

        const {
            signIn,
            showNotification
        } = this.originalInstance.props;

        if (event?.data?.result && event?.data?.token) {
            if (event.data.noRedirect) {
                const timeToLiveInSeconds = 60;
                BrowserDatabase.setItem(true, 'disable_login_redirect', timeToLiveInSeconds);
            }

            setAuthorizationToken(event.data.token);
            signIn(event.data.skipCartUpdate);
            showNotification('success', __('You have successfully logged in.'));

            if (window?.location?.search) {
                const { redirect } = urlToSearchState(location);

                if (redirect) {
                    history.push(appendWithStoreCode(redirect));
                }
            }
        } else if (event?.data?.messages && event?.data?.messages[0]) {
            showNotification('error', event.data.messages[0]);
        }
    };

    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;
        const original = callback(...args);

        return {
            ...original,
            showNotification: (type, message) => dispatch(showNotification(type, message)),
            signIn: (skipCartUpdate) => {
                dispatch(updateCustomerSignInStatus(true));
                MyAccountDispatcher.requestCustomerData(dispatch).then(
                    () => {
                        if (!skipCartUpdate) {
                            CartDispatcher.updateInitialCartData(dispatch);
                            WishlistDispatcher.updateInitialWishlistData(dispatch);
                        }
                    }
                );
            }
        };
    };
}

const { componentDidMount, mapDispatchToProps } = new RouterContainerPlugin();

export const config = {
    'Component/Router/Container': {
        'member-function': {
            componentDidMount: [
                {
                    position: 100,
                    implementation: componentDidMount
                }
            ]
        }
    },
    'Component/Router/Container/mapDispatchToProps': {
        function: [mapDispatchToProps]
    }
};

export default config;
