/* eslint-disable max-lines */
// eslint-disable-next-line max-len
import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';
import { DeviceType } from 'Type/Device';
import { isSignedIn } from 'Util/Auth';

import {
    LOGIN_HREF, OPEN_HOURS_STATIC_BLOCK,
    RENDER_CONTACT_INFO,
    RENDER_NEWSLETTER, TRACK_ORDER_HREF
} from './Footer.config';

/** @namespace ZnetPwa/Component/Footer/Component/FooterComponent */
export class FooterComponent extends SourceFooter {
    static propTypes = {
        isSignedIn: PropTypes.bool.isRequired,
        copyright: PropTypes.string.isRequired,
        device: DeviceType.isRequired,
        scrollToTop: PropTypes.func.isRequired
    };

    renderMap = {
        [RENDER_NEWSLETTER]: {
            render: this.renderNewsletterSubscriptionBlock.bind(this)
        },
        [RENDER_CONTACT_INFO]: {
            render: this.renderContactInfo.bind(this)
        }
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps,) {
        const { isSignedIn: prevSignIn } = prevProps;

        if (prevSignIn !== isSignedIn()) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isSignedIn: !isSignedIn });
        }
    }

    renderStayConnected() {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'StayConnectedWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'Social' } }
              label=""
            >
                <div>
                    <span block="Footer" elem="Title">{ __('Stay Connected') }</span>
                    <div block="Footer" elem="SocialIcons">
                        <Link
                          block="Footer"
                          elem="Link"
                          mods={ { facebook: true } }
                          target="blank"
                          to="https://www.facebook.com/ZnetFlooring/"
                        />
                        <Link
                          block="Footer"
                          elem="Link"
                          mods={ { twitter: true } }
                          target="blank"
                          to="https://twitter.com/ZnetFlooring"
                        />
                        <Link
                          block="Footer"
                          elem="Link"
                          mods={ { pinterest: true } }
                          target="blank"
                          to="https://www.pinterest.com/znetflooring/"
                        />
                        <Link
                          block="Footer"
                          elem="Link"
                          mods={ { instagram: true } }
                          target="blank"
                          to="https://www.instagram.com/znetflooring/"
                        />
                        <Link
                          block="Footer"
                          elem="Link"
                          mods={ { youtube: true } }
                          target="blank"
                          to="https://www.youtube.com/channel/UCOJF9T6bd-0YakO_jGo_7QA"
                        >
                            <img
                              alt="Youtube"
                              src="/pub/media/footer_assets/youtube.svg"
                              width="32"
                              height="32"
                            />
                        </Link>
                        <Link
                          block="Footer"
                          elem="Link"
                          mods={ { houzz: true } }
                          target="blank"
                          to="https://www.houzz.com/pro/znetflooring/"
                        >
                            <img
                              alt="Houzz"
                              src="/pub/media/footer_assets/houzz.svg"
                              width="32"
                              height="32"
                            />
                        </Link>
                    </div>
                    <div block="Footer" elem="Payments">
                        <span
                          block="Footer"
                          elem="Logo"
                          mods={ { visa: true } }
                        />
                        <span
                          block="Footer"
                          elem="Logo"
                          mods={ { mastercard: true } }
                        />
                        <span
                          block="Footer"
                          elem="Logo"
                          mods={ { discover: true } }
                        />
                        <span
                          block="Footer"
                          elem="Logo"
                          mods={ { amax: true } }
                        />
                        <span
                          block="Footer"
                          elem="Logo"
                          mods={ { paypal: true } }
                        />
                    </div>
                </div>
            </ContentWrapper>
        );
    }

    renderContactInfo() {
        return (
          <p block="ContactInfo">
            <div block="ContactInfo" elem="Address">
              10161 Park Run Dr. Ste 150
              <br />
              Las Vegas, NV 89145
            </div>
            <a block="ContactInfo" elem="Number" href="tel:1-725-201-0303">1 (725) 201-0303</a>
          </p>
        );
    }

    renderColumnItemLink = ({ href = '/', title, src }, i) => {
        const { scrollToTop } = this.props;
        const mods = src ? { type: 'image' } : {};
        let modifiedHref = href;

        if (!isSignedIn() && href === TRACK_ORDER_HREF) {
            modifiedHref = LOGIN_HREF;
        }

        return (
          <Link
            block="Footer"
            elem="ColumnItem"
            to={ modifiedHref }
            onClick={ scrollToTop }
            mods={ mods }
            key={ i }
            aria-label={ title }
          >
              { this.renderColumnItemContent(src, title) }
          </Link>
        );
    };

    renderContent() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        if (footer_cms) {
            return this.renderCmsBlockWrapper();
        }

        return (
            <div block="Footer" elem="Content">
                { this.renderFooterTop() }
                { this.renderStayConnected() }
                { this.renderColumns() }
            </div>
        );
    }

    renderCopyrightContent() {
        const { copyright } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <span block="Footer" elem="Copyright">
                    { copyright }
                </span>
            </ContentWrapper>
        );
    }

    renderFooterTop() {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'Top' } }
              wrapperMix={ { block: 'Footer', elem: 'NewsletterWrapper' } }
              label=""
            >
                <div block="Footer" elem="Newsletter">
                  { this.renderNewsletterSubscriptionBlock(0) }
                </div>
                <div block="Footer" elem="Contact">
                  <span block="Footer" elem="ContactTitle">
                    { __('Questions? Call ') }
                    <a block="Footer" elem="ContactNumber" href="tel:(725) 201-0303">(725) 201-0303</a>
                  </span>
                  <CmsBlock identifier={ OPEN_HOURS_STATIC_BLOCK } />
                </div>
            </ContentWrapper>
        );
    }

    renderColumn = (column, i) => {
        const {
            title,
            items,
            isItemsHorizontal,
            mods = {}
        } = column;
        const contentMods = isItemsHorizontal ? { direction: 'horizontal' } : {};

        return (
          <div block="Footer" elem="Column" mods={ mods } key={ i }>
              <p block="Footer" elem="ColumnTitle">
                  { title }
              </p>
              <div
                block="Footer"
                elem="ColumnContent"
                mods={ contentMods }
              >
                  { items.map(this.renderColumnItem) }
              </div>
          </div>
        );
    };

    render() {
        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderContent() }
                { this.renderCopyrightContent() }
            </footer>
        );
    }
}

export default FooterComponent;
