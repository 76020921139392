import ProductListQuery from 'Query/ProductList.query';
import {
    LinkedProductsDispatcher as SourceLinkedProductsDispatcher
} from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';
import { setLinkedProductsIsLoading, updateLinkedProducts } from 'Store/LinkedProducts/LinkedProducts.action';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

export const LINKED_PRODUCTS = 'LINKED_PRODUCTS';

/** @namespace ZnetPwa/Store/LinkedProducts/Dispatcher/LinkedProductsDispatcher */
export class LinkedProductsDispatcher extends SourceLinkedProductsDispatcher {
    onSuccess(data, dispatch, product_links) {
        const linkedProducts = this._processResponse(data, product_links);

        dispatch(updateLinkedProducts({
            ...linkedProducts,
            updateCrossSell: true
        }));
        dispatch(setLinkedProductsIsLoading(false));
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', __('Error fetching LinkedProducts!'), error));
        dispatch(setLinkedProductsIsLoading(false));
    }

    // Updated default updateCrossSell to true as accessories from other products were shown
    /**
     * Clear linked products list
     * @param {{productsSkuArray: Array<String>}} options A object containing different aspects of query, each item can be omitted
     * @return {Query} ProductList query
     * @memberof LinkedProductsDispatcher
     */
    clearLinkedProducts(dispatch, updateCrossSell = true) {
        const linkedProducts = {
            upsell: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] }
        };

        BrowserDatabase.setItem(linkedProducts, LINKED_PRODUCTS);

        dispatch(updateLinkedProducts({
            ...linkedProducts,
            updateCrossSell
        }));
    }

    async fetchCrossSellProducts(dispatch, product_links) {
        const query = this.prepareRequest(product_links);
        const data = await fetchQuery(query);
        const {
            LinkedProductsReducer: {
                linkedProducts: linkedProductsFromState = {
                    upsell: { total_count: 0, items: [] },
                    related: { total_count: 0, items: [] },
                    crosssell: { total_count: 0, items: [] }
                }
            } = {}
        } = getStore().getState();
        const { crosssell } = this._processResponse(data, product_links);

        Object.assign(linkedProductsFromState, {
            crosssell,
            updateCrossSell: true
        });

        dispatch(updateLinkedProducts(linkedProductsFromState));
    }

    clearCrossSellProducts(dispatch) {
        const {
            LinkedProductsReducer: {
                linkedProducts: linkedProductsFromState = {
                    upsell: { total_count: 0, items: [] },
                    related: { total_count: 0, items: [] },
                    crosssell: { total_count: 0, items: [] }
                }
            } = {}
        } = getStore().getState();

        Object.assign(linkedProductsFromState, {
            crosssell: { total_count: 0, items: [] },
            updateCrossSell: true
        });

        dispatch(updateLinkedProducts(linkedProductsFromState));
    }

    prepareRequest(product_links, dispatch) {
        const relatedSKUs = product_links.reduce((links, link) => {
            const { linked_product_sku } = link;

            /** Removed encodeURI because it escaped space and products were not found by SKU */
            return [...links, linked_product_sku];
        }, []);

        if (dispatch) {
            dispatch(setLinkedProductsIsLoading(true));
        }

        return [
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: relatedSKUs
                    },
                    pageSize: relatedSKUs.length
                },
                notRequireInfo: true
            })
        ];
    }
}

export default new LinkedProductsDispatcher();
