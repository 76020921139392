/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { roundPrice } from 'Util/Price';

import Event, { EVENT_GA4_PREMIUM_CUSTOMER_ORDER } from '../../../util/Event';
import ProductHelper from '../utils';
import { NOT_APPLICABLE } from '../utils/Product';
import BaseEvent from './BaseEvent.event';

export const EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 10000;

/**
 * On order success page "Purchase"
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace, @scandipwa/scandipwa-guidelines/derived-class-names
export class PremiumCustomerPurchase extends BaseEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_GA4_PREMIUM_CUSTOMER_ORDER, ({ orderID: orderId, totals }) => {
            this.handle(
                orderId,
                totals
            );
        });
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    handler(orderId, totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const data = this.getActionFields(orderId, totals);

        const {
            coupon,
            id,
            revenue,
            tax,
            shipping
        } = data;

        const ecommerce = {
            transaction_id: id,
            value: revenue,
            tax,
            shipping,
            currency: this.getCurrencyCode(),
            items: this.getGA4Products(totals)
        };

        if (coupon && coupon !== '') {
            ecommerce.coupon = coupon;
        }

        // GA4 data push
        this.pushGA4EventData({ ecommerce });
    }

    /**
     * Get order information
     *
     * @return {{revenue: number, coupon_discount_abs: string, coupon: string, shipping: number, affiliation: string, coupon_discount_amount: string, tax: number, id: *}}
     */
    getActionFields(orderId = '', {
        tax_amount, grand_total, shipping_amount, discount_amount, coupon_code = ''
    }) {
        return {
            id: orderId,
            tax: +roundPrice(tax_amount),
            coupon: coupon_code,
            revenue: +roundPrice(grand_total),
            shipping: +roundPrice(shipping_amount),
            coupon_discount_amount: +roundPrice(discount_amount)
        };
    }

    /**
     * Get GA4 product detail
     * Ref: https://sepoy.atlassian.net/browse/ZFR-1319
     * @param totals
     *
     * @return {{ quantity: number, price: number, item_name: string, item_variant: string, item_id: string, item_category: string, item_brand: string }[]}
     * @param cartData
     */
    getGA4Products({ items }) {
        const products = items.reduce((acc, item) => (
            [
                ...acc,
                {
                    ...ProductHelper.getGA4ItemData(item),
                    quantity: ProductHelper.getQuantity(item)
                }
            ]
        ), []);

        const groupedProducts = this.getGroupedProducts();
        Object.values(groupedProducts || {}).forEach(({ data }) => {
            const {
                price,
                name: item_name,
                variant: item_variant,
                id,
                category: item_category,
                brand: item_brand
            } = data;

            const ecommerceProduct = {
                price,
                item_name,
                item_variant,
                item_id: id,
                item_category,
                item_brand,
                id, // Google Ads additional variable. The same as item_id
                google_business_vertical: 'retail' // Google Ads additional constant
            };

            if (item_variant !== NOT_APPLICABLE) {
                ecommerceProduct.item_variant = item_variant;
            }

            return products.push(ecommerceProduct);
        });

        return products;
    }

    /**
     * Get product detail
     *
     * @param totals
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, category: string, brand: string, url: string}[]}
     * @param cartData
     */
    getProducts({ items }) {
        const products = items.reduce((acc, item) => (
            [
                ...acc,
                {
                    ...ProductHelper.getItemData(item),
                    quantity: ProductHelper.getQuantity(item)
                }
            ]
        ), []);

        const groupedProducts = this.getGroupedProducts();
        Object.values(groupedProducts || {}).forEach(({ data }) => products.push(data));

        return products;
    }
}

export default PremiumCustomerPurchase;
