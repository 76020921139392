export const SEARCHSPRING_SEARCH_OVERLAY = 'search';
export const SIX_PRODUCTS_IN_SEARCH = 6;
export const FOUR_PRODUCTS_IN_MOBILE_SEARCH = 4;
export const CATEGORY_HIERARCHY = 'category_hierarchy';
export const ALL_ACCESSORIES = 'All Accessories';
export const CATEGORY_LABEL = 'Category';
export const FLOORING_ATTRIBUTE_ID = 11;
export const RUG_ATTRIBUTE_ID = 14;
export const ACCESSORIES_ATTRIBUTE_ID = 9;
export const ROW_SIZE_DESKTOP = 3;
export const ROW_SIZE_TABLET = 2;
export const ROW_SIZE_MOBILE = 2;
export const TABLET_WIDTH = 1024;
export const BRAND_FIELD = 'brand';
export const PRICE_FIELD = 'price';
export const FIVE_BRANDS = 5;
export const SEARCH = 'Search';
