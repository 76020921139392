/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GA4_OPEN_REQUEST_PRICE_FORM } from '../../../util/Event';
import BaseEvent from './BaseEvent.event';

export const EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 10000;

/**
 * On order success page "Purchase"
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace, @scandipwa/scandipwa-guidelines/derived-class-names
export class OpenRequestPriceForm extends BaseEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_GA4_OPEN_REQUEST_PRICE_FORM, () => this.handle());
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    handler() {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }
        // GA4 data push
        this.pushGA4EventData();
    }
}

export default OpenRequestPriceForm;
