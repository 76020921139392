/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { createRef } from 'react';

import CarouselScrollArrow from 'Component/CarouselScrollArrow';
import CarouselScrollItem from 'Component/CarouselScrollItem';
import { CarouselScroll as SourceCarouselScroll } from 'SourceComponent/CarouselScroll/CarouselScroll.component';
import CSS from 'Util/CSS';
import { isMobile } from 'Util/Mobile';

import {
    MAX_PRODUCT_CARD_WIDTH,
    MIN_PRODUCT_CARD_WIDTH,
    SWIPE_DIFF
} from './CarouselScroll.config';

/** @namespace ZnetPwa/Component/CarouselScroll/Component/CarouselScrollComponent */
export class CarouselScrollComponent extends SourceCarouselScroll {
    static propTypes = {
        ...this.propTypes,
        isCartPopup: PropTypes.bool,
        dynamicItems: PropTypes.bool,
        showCrumbs: PropTypes.bool,
        activeFilter: PropTypes.number
    };

    static defaultProps = {
        ...this.defaultProps,
        isCartPopup: false,
        dynamicItems: false,
        showCrumbs: true,
        activeFilter: -1
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);
        const {
            showedItemCount
        } = this.props;

        this.itemRef = createRef();

        this.state = {
            ...this.state,
            showedItemCount,
            touchPosition: null
        };
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps) {
        const { activeFilter: prevActiveFilter } = prevProps;
        const { activeFilter } = this.props;

        // If filter changes create new ref as the previous accessory might not be shown
        // and scroll to the beginning
        if (activeFilter !== prevActiveFilter) {
            this.itemRef = createRef();

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ activeItemId: 0 },
                () => this.setTranslate(0));
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { showedItemCount, dynamicItems } = this.props;

        const heightSize = 100;
        const height = `${ heightSize / showedItemCount }%`;
        const { offsetWidth: carouselWidth } = this.carouselRef.current;

        // eslint-disable-next-line fp/no-let
        let carouselItemWidth = 0;

        // Calculating how many items to display based on width of the screen
        if (carouselWidth / showedItemCount < MIN_PRODUCT_CARD_WIDTH) {
            if (carouselWidth < MAX_PRODUCT_CARD_WIDTH) {
                carouselItemWidth = carouselWidth;
            } else {
                carouselItemWidth = MAX_PRODUCT_CARD_WIDTH;
            }
        } else {
            carouselItemWidth = carouselWidth / showedItemCount;
        }

        const actualShowedItems = dynamicItems ? carouselWidth / carouselItemWidth
            : showedItemCount;

        this.setState({ showedItemCount: actualShowedItems });

        CSS.setVariable(this.carouselRef, 'carousel-item-width', `${carouselItemWidth}px`);

        CSS.setVariable(this.carouselRef, 'carousel-item-height', height);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getNextTranslate(nextId) {
        const { showedActiveItemNr, children: { length: childrenLength } } = this.props;
        const { showedItemCount } = this.state;
        const { offsetWidth = 0 } = this.itemRef?.current || {};

        // When selected item hasn't reached wanted position
        if (nextId < (showedActiveItemNr - 1) || childrenLength <= showedItemCount) {
            return 0;
        }

        const isEndReached = (showedItemCount - showedActiveItemNr) + nextId + 1 > childrenLength;

        const position = isEndReached
            ? childrenLength - showedItemCount
            : nextId - (showedActiveItemNr - 1);

        return `${ -position * offsetWidth }px`;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    setTranslate(nextId) {
        const { children: { length: childrenLength } } = this.props;
        const { showedItemCount } = this.state;

        if (childrenLength <= showedItemCount) {
            return;
        }

        const translate = this.getNextTranslate(nextId);
        CSS.setVariable(this.carouselRef, 'translateY', translate);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleTouchStart = (event) => {
        const touchDown = event.touches[0].clientX;
        this.setState({ touchPosition: touchDown });
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleTouchMove = (event) => {
        const { children: { length: childrenLength } } = this.props;
        const { showedItemCount, activeItemId } = this.state;
        const touchDown = this.state.touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = event.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > SWIPE_DIFF && !(activeItemId >= childrenLength - showedItemCount)) {
            this.handleArrowClick(true);
        }

        if (diff < -SWIPE_DIFF) {
            this.handleArrowClick();
        }

        this.setState({ touchPosition: null });
    };

    renderArrow(isNextArrow = false) {
        const { showArrow, children: { length: childrenLength }, dynamicItems } = this.props;
        const { showedItemCount, activeItemId } = this.state;

        if (!showArrow || childrenLength <= showedItemCount) {
            return null;
        }

        if (dynamicItems) {
            // Don't show arrow if we already are showing all the items
            const isDisabled = isNextArrow
                ? activeItemId >= childrenLength - showedItemCount
                : !activeItemId;

            return (
                <CarouselScrollArrow
                  isNextArrow={ isNextArrow }
                  isDisabled={ isDisabled }
                  onClick={ this.handleArrowClick }
                />
            );
        }

        const isDisabled = isNextArrow
            ? activeItemId === childrenLength - 1
            : !activeItemId;

        return (
            <CarouselScrollArrow
              isNextArrow={ isNextArrow }
              isDisabled={ isDisabled }
              onClick={ this.handleArrowClick }
            />
        );
    }

    renderContentItem = (child, key) => {
        const { activeItemId } = this.state;
        const { dynamicItems } = this.props;

        // If scroll for accessories
        if (dynamicItems) {
            return (
                <CarouselScrollItem
                  key={ key }
                  position={ key }
                  itemRef={ this.itemRef }
                  isActive={ key === activeItemId }
                >
                    { child }
                </CarouselScrollItem>
            );
        }

        return (
            <CarouselScrollItem
              key={ key }
              position={ key }
              onClick={ this.handleChange }
              itemRef={ this.itemRef }
              isActive={ key === activeItemId }
            >
                { child }
            </CarouselScrollItem>
        );
    };

    renderContent() {
        const { children, mods = {} } = this.props;

        return (
            <div block="CarouselScroll" elem="ContentWrapper" mods={ mods }>
                <div
                  block="CarouselScroll"
                  elem="Content"
                  mods={ mods }
                  onTouchStart={ this.handleTouchStart }
                  onTouchMove={ this.handleTouchMove }
                >
                    { children.map(this.renderContentItem) }
                </div>
            </div>
        );
    }

    renderCrumbs() {
        const {
            children,
            children: { length: childrenLength },
            showCrumbs
        } = this.props;

        const { showedItemCount } = this.state;

        if ((childrenLength <= showedItemCount) || !showCrumbs) {
            return null;
        }

        return (
            <div
              block="Slider"
              elem="Crumbs"
            >
                { children.map(this.renderCrumb) }
            </div>
        );
    }

    renderCrumb = (_, i) => {
        const { activeItemId, showedItemCount } = this.state;
        const { children: { length: childrenLength } } = this.props;
        const isActive = isMobile.any()
            ? Math.round(i / showedItemCount) === Math.round((activeItemId) / showedItemCount)
            : Math.round(i / showedItemCount) === Math.round((activeItemId + 1) / showedItemCount);
        const changeId = i > (childrenLength - showedItemCount) ? (childrenLength - showedItemCount) : i;

        if ((i % showedItemCount) > 0) {
            return null;
        }

        return (
            <button
              block="Slider"
              elem="Image"
              mods={ { type: 'single' } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => this.handleChange(changeId) }
              aria-label={ __('Slide crumb') }
            >
                <div
                  block="Slider"
                  elem="Crumb"
                  mods={ { isActive } }
                />
            </button>
        );
    };

    render() {
        const { dynamicItems, isCartPopup } = this.props;

        return (
            <>
                <div
                  block="CarouselScroll"
                  elem="Arrows"
                  mods={ { isCartPopup } }
                >
                    { this.renderArrow() }
                    { this.renderArrow(true) }
                </div>
                <div block="CarouselScroll" ref={ this.carouselRef } mods={ { dynamicItems } }>
                    { this.renderContent() }
                </div>
                { this.renderCrumbs() }
            </>
        );
    }
}

export default CarouselScrollComponent;
