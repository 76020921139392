import { DemoNotice as SourceDemoNotice } from 'SourceComponent/DemoNotice/DemoNotice.component';

import './DemoNotice.override.style';

/** @namespace ZnetPwa/Component/DemoNotice/Component/DemoNoticeComponent */
export class DemoNoticeComponent extends SourceDemoNotice {
    renderText() {
        return (
            <div
              block="Header"
              elem="DevelopmentDislaimer"
              key="devSiteDisclaimer"
            >
                { __('This site is under development. Orders won\'t be processed. Please visit ') }
                <a
                  block="Header"
                  elem="DevelopmentDislaimerLink"
                  href="https://znetflooring.com/"
                >
                    znetflooring.com
                </a>
            </div>
        );
    }
}

export default DemoNoticeComponent;
