/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { isSignedIn } from 'Util/Auth';

import { BRAINTREE_CONTAINER_ID } from './Braintree.config';

import './Braintree.style';

/** @namespace BraintreeGraphql/Component/Braintree/Component/BraintreeComponent */
export class BraintreeComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        onPaymentSavedInVaultChange: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        isSavePayment: PropTypes.bool.isRequired,
        isVaultActive: PropTypes.bool.isRequired
    };

    renderSavePaymentCheckbox() {
        const {
            onPaymentSavedInVaultChange,
            isSavePayment,
            isVaultActive,
            isLoading
        } = this.props;

        if (isLoading || !isSignedIn() || !isVaultActive) {
            return null;
        }

        return (
            <Field
              id="isSavePayment"
              name="isSavePayment"
              type="checkbox"
              label={ __('Save card for later?') }
              value="isSavePayment"
              mix={ { block: 'Braintree', elem: 'Checkbox' } }
              checked={ isSavePayment }
              onChange={ onPaymentSavedInVaultChange }
            />
        );
    }

    render() {
        const { selectedPaymentCode } = this.props;

        return (
            <div block="Braintree" mods={ { type: selectedPaymentCode } }>
                <div
                  block="Braintree"
                  elem="Form"
                  id={ BRAINTREE_CONTAINER_ID }
                />
                { this.renderSavePaymentCheckbox() }
            </div>
        );
    }
}

export default BraintreeComponent;
