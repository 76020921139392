/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { SEARCHSPRING_SEARCH_OVERLAY } from 'Component/SearchSpringOverlay/SearchSpringOverlay.config';
import { toggleOverlayByKey } from 'SourceStore/Overlay/Overlay.action';
import { toggleScroll } from 'Util/Browser';
import { getCookie } from 'Util/Cookies/Cookies';
import history from 'Util/History';
import { isMobile } from 'Util/Mobile';
import {
    urlToSearchState
} from 'Util/SearchSpring';

import SearchSpringField from './SearchSpringField.component';
import { SEARCH_DELAY, TEN_YEARS_IN_SECONDS } from './SearchSpringField.config';

/** @namespace ZnetPwa/Component/SearchSpringField/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleOverlayByKey: () => dispatch(toggleOverlayByKey(SEARCHSPRING_SEARCH_OVERLAY))
});

/** @namespace ZnetPwa/Component/SearchSpringField/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    // Only updated if there is a correction or suggestion
    queryInState: state.SearchBarReducer.query
});

/** @namespace ZnetPwa/Component/SearchSpringField/Container/SearchSpringFieldContainer */
export class SearchSpringFieldContainer extends PureComponent {
    static propTypes = {
        showOverlay: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onSearchBarClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        pathname: PropTypes.string.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired,
        isVisible: PropTypes.bool.isRequired,
        isActive: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        queryInState: PropTypes.string.isRequired
    };

    // Timer used for request throttling.
    timer = null;

    // Two values are used because of the throttling
    // fieldValue - updated without throttling to show current query in search
    // value - updated with throttling and passed to Overlay component where actual search is executed
    state = {
        fieldValue: '',
        value: '',
        isSuggestOptionClick: false,
        isMobileSearchOpen: false,
        // Used to not have delayed update of search field value when it was replaced by suggested/corrected query
        isCancelFieldUpdate: false
    };

    containerFunctions = {
        clearSearch: this.clearSearch.bind(this),
        handleSubmit: this.handleSubmit.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onAutosuggestOptionClick: this.onAutosuggestOptionClick.bind(this),
        showMobileSearch: this.showMobileSearch.bind(this),
        hideMobileSearch: this.hideMobileSearch.bind(this),
        onSearchOutsideProcessor: this.onSearchOutsideProcessor.bind(this)
    };

    componentDidMount() {
        const { onSearchOutsideClick } = this.props;

        // Set cookies required for SearchSpring
        this.createSessionIdNamespaceCookie();
        this.createUserIdCookie();

        // If link with search query is used, include search query in search field
        const { query = '' } = urlToSearchState(window.location);
        const decodedURI = decodeURI(query);

        this.setState({ fieldValue: decodedURI, value: decodedURI });

        window.addEventListener('scroll', onSearchOutsideClick);
    }

    componentDidUpdate(prevProps) {
        const { pathname: prevPathname, queryInState: prevQueryInState } = prevProps;
        const { pathname, queryInState } = this.props;

        if (prevPathname !== pathname) {
            const { query = '' } = urlToSearchState(window.location);
            const decodedURI = decodeURI(query);

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ fieldValue: decodedURI, value: decodedURI });
        }

        if (queryInState !== prevQueryInState) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(
                { isCancelFieldUpdate: true, fieldValue: queryInState, value: queryInState },
                () => this.toggleFieldUpdates(false)
            );
        }
    }

    containerProps = () => {
        const {
            value,
            fieldValue,
            isMobileSearchOpen,
            isSuggestOptionClick
        } = this.state;
        const {
            onSearchBarClick,
            isActive,
            isVisible,
            isMobile
        } = this.props;

        return {
            value,
            fieldValue,
            onSearchBarClick,
            isActive,
            isVisible,
            isMobile,
            isMobileSearchOpen,
            isSuggestOptionClick
        };
    };

    toggleFieldUpdates(status) {
        this.setState({ isCancelFieldUpdate: status });
    }

    showMobileSearch() {
        const { toggleOverlayByKey, activeOverlay } = this.props;

        this.setState({ isMobileSearchOpen: true });
        this.freezeScroll();

        if (isMobile.any() && activeOverlay !== SEARCHSPRING_SEARCH_OVERLAY) {
            toggleOverlayByKey();
        }
    }

    hideMobileSearch() {
        setTimeout(() => {
            this.setState({ isMobileSearchOpen: false });
            this.unfreezeScroll();
        }, 0);
    }

    freezeScroll() {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
        document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
    }

    unfreezeScroll() {
        toggleScroll(true);
        document.body.style.marginTop = 0;
        window.scrollTo(0, this.YoffsetWhenScrollDisabled);
    }

    clearSearch(e) {
        e.preventDefault();
        this.setState({ value: '', fieldValue: '' });
    }

    handleSubmit(event) {
        const { toggleOverlayByKey, onSearchBarChange } = this.props;
        const { isMobileSearchOpen, fieldValue } = this.state;

        // Update searchCriteria in case ENTER or SEARCH is clicked before search is updated due to timeout set
        if (fieldValue) {
            const event = {
                target: {
                    value: fieldValue
                }
            };

            onSearchBarChange(event);
            this.setState({ isCancelFieldUpdate: false, value: fieldValue });
        }

        event.preventDefault();
        toggleOverlayByKey();

        if (isMobileSearchOpen) {
            this.hideMobileSearch();
        }

        if (fieldValue) {
            history.push(`/search/${fieldValue.trim().replace('%', '')}`);
        }
    }

    onSearchOutsideProcessor(isSuggestOptionClick) {
        const { onSearchOutsideClick } = this.props;

        onSearchOutsideClick(isSuggestOptionClick);
        this.setState({ isSuggestOptionClick: false });
    }

    onAutosuggestOptionClick(query) {
        this.setState({ value: query, fieldValue: query, isSuggestOptionClick: true });
    }

    /**
     * On search bar input event.
     *
     * @param {Object} event
     * @return {*}
     */
    onSearchBarChange(event) {
        const { onSearchBarChange } = this.props;
        const { isCancelFieldUpdate } = this.state;
        const query = event.currentTarget.value;

        // Throttle the requests to prevent sending a request on every keystroke.
        window.clearTimeout(this.timer);
        event.persist();
        this.timer = window.setTimeout(() => {
            // Used to not have delayed update of value when it was replaced by suggested/corrected query
            if (!isCancelFieldUpdate) {
                onSearchBarChange(event);
                this.setState({ value: query });
            }

            this.toggleFieldUpdates(false);
        }, SEARCH_DELAY);

        this.setState({ fieldValue: query });
    }

    /* No need to clear it as If neither expires nor max-age specified
    it will expire at the end of session as this cookie is supposed to.
    */
    createSessionIdNamespaceCookie() {
        if (getCookie('ssSessionIdNamespace') === null) {
            document.cookie = `ssSessionIdNamespace=${ uuidv4() }`;
        }
    }

    // If no user cookie create one
    createUserIdCookie() {
        if (getCookie('ssUserId') === null) {
            document.cookie = `ssUserId=${ uuidv4() };max-age=${ TEN_YEARS_IN_SECONDS }`;
        }
    }

    render() {
        return (
            <SearchSpringField
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSpringFieldContainer);
