export const UPDATE_SWATCHES = 'UPDATE_SWATCHES';
export const SET_SWATCHES_LOADING = 'SET_SWATCHES_LOADING';

/** @namespace ZnetPwa/Store/ProductListPage/Action/updateSwatches */
export const updateSwatches = (payload) => ({
    type: UPDATE_SWATCHES,
    payload
});

/** @namespace ZnetPwa/Store/ProductListPage/Action/setSwatchesLoading */
export const setSwatchesLoading = () => ({
    type: SET_SWATCHES_LOADING
});
