import { fetchMutation } from 'Util/Request';
import SocialNetworksQuery from '../query/SocialNetworks.query';

export class MyAccountDispatcherPlugin {
    logout = async (args, callback, instance) => {
        const {
            socialLogin_logoutCustomer: logoutCustomer
        } = await fetchMutation(SocialNetworksQuery.getLogoutCustomerMutation());

        if (logoutCustomer) {
            callback.apply(instance, args);
        }
    };
}

const { logout } = new MyAccountDispatcherPlugin();

export const config = {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            logout: [
                {
                    position: 115,
                    implementation: logout
                }
            ]
        }
    }
};

export default config;
