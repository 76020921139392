import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CUSTOMER_ACCOUNT_PAGE } from 'Component/Header/Header.config';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import {
    ADDRESS_BOOK,
    DASHBOARD, MY_ORDERS,
    MY_WISHLIST, NEWSLETTER_SUBSCRIPTION
} from 'Type/Account';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountTabListDropdown from './MyAccountTabListDropdown.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace ZnetPwa/Component/MyAccountTabListDropdown/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace ZnetPwa/Component/MyAccountTabListDropdown/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, dispatch)
    ),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace ZnetPwa/Component/MyAccountTabListDropdown/Container/MyAccountTabListDropdownContainer */
export class MyAccountTabListDropdownContainer extends PureComponent {
    static propTypes = {
        isMyAccountOverlayOpened: PropTypes.bool.isRequired,
        changeHeaderState: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired
    };

    tabMap = {
        [DASHBOARD]: {
            url: '/dashboard',
            name: __('Dashboard')
        },
        [ADDRESS_BOOK]: {
            url: '/address-book',
            name: __('Address book')
        },
        [MY_ORDERS]: {
            url: '/my-orders',
            name: __('My orders')
        },
        [MY_WISHLIST]: {
            url: '/my-wishlist',
            name: __('My wishlist')
        },
        [NEWSLETTER_SUBSCRIPTION]: {
            url: '/newsletter-subscription',
            name: __('Newsletter Subscription')
        }
    };

    containerFunctions = {
        onTabClick: this.onTabClick.bind(this),
        handleLogout: this.handleLogout.bind(this),
        expandMyAccountDropdown: this.expandMyAccountDropdown.bind(this)
    };

    state = {
        isContentExpanded: false
    };

    componentDidUpdate(prevProps) {
        const { isMyAccountOverlayOpened: prevIsMyAccountOverlayOpened } = prevProps;
        const { isMyAccountOverlayOpened } = this.props;

        if (isMyAccountOverlayOpened !== prevIsMyAccountOverlayOpened) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isContentExpanded: isMyAccountOverlayOpened });
        }
    }

    containerProps() {
        const { isContentExpanded } = this.state;

        return {
            toggleExpandableContent: this.toggleExpandableContent,
            isContentExpanded,
            tabMap: this.tabMap
        };
    }

    expandMyAccountDropdown() {
        this.setState({ isContentExpanded: true });
    }

    toggleExpandableContent = () => {
        this.setState(({ isContentExpanded: false }));
    };

    handleLogout() {
        const { logout } = this.props;

        logout();
    }

    changeDefaultHeaderState() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            title: 'My account',
            name: CUSTOMER_ACCOUNT_PAGE,
            onBackClick: () => history.push(appendWithStoreCode('/'))
        });
    }

    changeHeaderState(activeTab) {
        if (activeTab !== MY_WISHLIST) {
            this.changeDefaultHeaderState();
        }
    }

    onTabClick(activeTab) {
        const { [activeTab]: { url } } = this.tabMap;
        if (!isSignedIn()) {
            return;
        }
        this.toggleExpandableContent();

        history.push(appendWithStoreCode(`${ MY_ACCOUNT_URL }${ url }`));
        this.changeHeaderState(activeTab);
    }

    render() {
        return (
            <MyAccountTabListDropdown
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListDropdownContainer);
