import PropTypes from 'prop-types';

import Captcha from 'Component/Captcha';
import { CAPTCHA_FORGOT_PASSWORD_ID } from 'Component/Captcha/Captcha.config';
import Field from 'Component/Field';
import Form from 'Component/Form';
import {
    MyAccountForgotPassword as SourceMyAccountForgotPassword
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';

import './MyAccountForgotPassword.override.style';

/** @namespace ZnetPwa/Component/MyAccountForgotPassword/Component/MyAccountForgotPasswordComponent */
export class MyAccountForgotPasswordComponent extends SourceMyAccountForgotPassword {
    static propTypes = {
        ...super.propTypes,
        setToken: PropTypes.func.isRequired
    };

    renderForgotPasswordForm() {
        const {
            onForgotPasswordAttempt,
            onForgotPasswordSuccess,
            onFormError,
            setToken
        } = this.props;

        return (
            <Form
              key="forgot-password"
              onSubmit={ onForgotPasswordAttempt }
              onSubmitSuccess={ onForgotPasswordSuccess }
              onSubmitError={ onFormError }
            >
                <Field
                  type="text"
                  id="email"
                  name="email"
                  label={ __('Email') }
                  autocomplete="email"
                  validation={ ['notEmpty', 'email'] }
                />
                <Captcha setToken={ setToken } captchaId={ CAPTCHA_FORGOT_PASSWORD_ID } />
                <div block="MyAccountOverlay" elem="Buttons">
                    <button block="Button" type="submit">
                        { __('Send reset link') }
                    </button>
                </div>
            </Form>
        );
    }
}

export default MyAccountForgotPasswordComponent;
