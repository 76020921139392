import { connect } from 'react-redux';
import { fetchQuery, fetchMutation } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';
import SocialNetworksQuery from '../../query/SocialNetworks.query';
import MyAccountMySocialNetworks from './MyAccountMySocialNetworks.component';

/** @namespace ScandiPWA/SocialLoginGraphql/Component/MyAccountMySocialNetworks/Container/mapStateToProps */
export const mapStateToProps = state => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace ScandiPWA/SocialLoginGraphql/Component/MyAccountMySocialNetworks/Container/mapDispatchToProps */
export const mapDispatchToProps = dispatch => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace ScandiPWA/SocialLoginGraphql/Component/MyAccountMySocialNetworks/Container */
export class MyAccountMySocialNetworksContainer extends PureComponent {
    getAccountsData = () => fetchQuery(
        SocialNetworksQuery.getAccountsDataQuery()
    );

    unlinkAccount = type => fetchMutation(
        SocialNetworksQuery.unlinkMutation(type)
    );

    render() {
        return (
            <MyAccountMySocialNetworks
              getAccountsData={ this.getAccountsData }
              unlinkAccount={ this.unlinkAccount }
              { ...this.props }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    MyAccountMySocialNetworksContainer,
);
