/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © ScandiPWA, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/social-login
 * @author Vladislavs Jerins  <info@scandiweb.com>
 */

import SocialAuthentication from '../component/SocialAuthentication';

export class CartPagePlugin {
    renderTotals = (args, callback, instance) => (
        <>
            <SocialAuthentication isCartPage />
            { callback.apply(instance, args) }
        </>
    );
}

export const { renderTotals } = new CartPagePlugin();

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderTotals
        }
    }
};
