import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { FLOORING_ATTRIBUTE } from 'Component/CheckoutDeliveryOptions/CheckoutDeliveryOptions.config';
import { STATE_MISMATCH_POPUP } from 'Component/StateZipMismatchPopup/StateZipMismatchPopup.config';
import { BILLING_STEP } from 'SourceRoute/Checkout/Checkout.config';
import {
    updateCurbsideDisclaimerError,
    updateDeliveryDisclaimerError,
    updateInstallationStatusError,
    updatePickupAcknowledgementError
} from 'Store/MyAccount/MyAccount.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { customerType } from 'Type/Account';
import { countriesType } from 'Type/Config';
import { TotalsType } from 'Type/MiniCart';
import { getCityAndRegionFromZipcode, trimCustomerAddress } from 'Util/Address';

import Form from './Form.component';

/** @namespace ZnetPwa/Component/Form/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    countries: state.ConfigReducer.countries,
    customer: state.MyAccountReducer.customer,
    isExistingInstallation: state.MyAccountReducer.existing_installation,
    isPickupAcknowledge: state.MyAccountReducer.pickupAcknowledge,
    isCurbsideDisclaimer: state.MyAccountReducer.curbsideDisclaimer,
    isDeliveryDisclaimer: state.MyAccountReducer.deliveryDisclaimer,
    shippingMethod: state.MyAccountReducer.method,
    cartTotals: state.CartReducer.cartTotals
});

/** @namespace ZnetPwa/Component/Form/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: () => dispatch(showPopup(STATE_MISMATCH_POPUP)),
    updateInstallationStatusError: (status, scrollToError) => dispatch(
        updateInstallationStatusError(status, scrollToError)
    ),
    updatePickupAcknowledgementError: (status) => dispatch(updatePickupAcknowledgementError(status)),
    updateCurbsideDisclaimerError: (status, scrollToError) => dispatch(
        updateCurbsideDisclaimerError(status, scrollToError)
    ),
    updateDeliveryDisclaimerError: (status, scrollToError) => dispatch(
        updateDeliveryDisclaimerError(status, scrollToError)
    )
});

/** @namespace ZnetPwa/Component/Form/Container/FormContainer */
export class FormContainer extends PureComponent {
    static propTypes = {
        isSameAsShipping: PropTypes.bool,
        onSubmitError: PropTypes.func,
        id: PropTypes.string,
        showPopup: PropTypes.func.isRequired,
        cartTotals: TotalsType.isRequired,
        countries: countriesType.isRequired,
        customer: customerType.isRequired,
        selectedCustomerAddressId: PropTypes.number.isRequired,
        isPickupAcknowledge: PropTypes.bool.isRequired,
        isCurbsideDisclaimer: PropTypes.bool.isRequired,
        isDeliveryDisclaimer: PropTypes.bool.isRequired,
        isExistingInstallation: PropTypes.bool.isRequired,
        updateDeliveryDisclaimerScrollToStatus: PropTypes.func.isRequired,
        updateInstallationStatusError: PropTypes.func.isRequired,
        updatePickupAcknowledgementError: PropTypes.func.isRequired,
        updateCurbsideDisclaimerError: PropTypes.func.isRequired,
        updateDeliveryDisclaimerError: PropTypes.func.isRequired,
        shippingMethod: PropTypes.string.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        isSameAsShipping: false,
        onSubmitError: () => {},
        id: ''
    };

    containerFunctions = {
        getAddressById: this.getAddressById.bind(this),
        isZipAndStateMismatch: this.isZipAndStateMismatch.bind(this),
        setInstallationError: this.setInstallationError.bind(this),
        setPickupAcknowledgementError: this.setPickupAcknowledgementError.bind(this),
        setCurbsideDisclaimerError: this.setCurbsideDisclaimerError.bind(this),
        setDeliveryDisclaimerError: this.setDeliveryDisclaimerError.bind(this)
    };

    containerProps = () => {
        const {
            isSameAsShipping,
            customer,
            selectedCustomerAddressId,
            isExistingInstallation,
            isPickupAcknowledge,
            isCurbsideDisclaimer,
            isDeliveryDisclaimer,
            shippingMethod
        } = this.props;

        return {
            isSameAsShipping,
            customer,
            selectedCustomerAddressId,
            shippingMethod,
            isPickupAcknowledge,
            isCurbsideDisclaimer: this.checkForFlooringProducts(isCurbsideDisclaimer),
            isDeliveryDisclaimer: this.checkForFlooringProducts(isDeliveryDisclaimer),
            isExistingInstallation
        };
    };

    // Check for flooring products, as rugs would have an error by default
    // Ref: https://sepoy.atlassian.net/browse/ZFR-1129
    checkForFlooringProducts(disclaimerValue) {
        const {
            cartTotals: {
                items = []
            } = {}
        } = this.props;

        const containsFlooring = items?.some((item) => item.product.dynamicAttributes.attribute_set_name
        === FLOORING_ATTRIBUTE);

        return containsFlooring ? disclaimerValue : true;
    }

    setInstallationError(scrollToError = false) {
        const {
            updateInstallationStatusError
        } = this.props;

        updateInstallationStatusError(true, scrollToError);
    }

    setPickupAcknowledgementError() {
        const {
            updatePickupAcknowledgementError
        } = this.props;

        updatePickupAcknowledgementError(true);
    }

    setCurbsideDisclaimerError(scrollToError = false) {
        const {
            updateCurbsideDisclaimerError
        } = this.props;

        updateCurbsideDisclaimerError(true, scrollToError);
    }

    setDeliveryDisclaimerError(scrollToError = false) {
        const {
            updateDeliveryDisclaimerError
        } = this.props;

        updateDeliveryDisclaimerError(true, scrollToError);
    }

    getAddressById(addressId) {
        const { customer: { addresses } } = this.props;
        const address = addresses?.find(({ id }) => id === addressId);

        return {
            ...trimCustomerAddress(address),
            save_in_address_book: false,
            id: addressId
        };
    }

    async isZipAndStateMismatch(country_id, postcode, userRegionCode) {
        const {
            id,
            isSameAsShipping,
            onSubmitError,
            showPopup
        } = this.props;

        if (isSameAsShipping) {
            return true;
        }
        const [, regionCode] = await getCityAndRegionFromZipcode(country_id, postcode);

        if (regionCode !== userRegionCode && regionCode !== null) {
            if (id === BILLING_STEP) {
                onSubmitError();
            }

            showPopup();

            return true;
        }

        return false;
    }

    render() {
        return (
            <Form
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
