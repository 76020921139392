import Link from 'Component/Link';
import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

/** @namespace ZnetPwa/Component/Breadcrumb/Component/BreadcrumbComponent */
export class BreadcrumbComponent extends SourceBreadcrumb {
    renderLink() {
        const {
            index,
            isDisabled
        } = this.props;

        const url = this.getLinkUrl() || {};

        return (
            <Link
              block="Breadcrumb"
              elem="Link"
              to={ url }
              tabIndex={ isDisabled ? '-1' : '0' }
              showLoader
            >
                <meta itemProp="item" content={ window.location.origin + url.pathname } />
                <span itemProp="name">
                    { this.renderName() }
                </span>
                <meta itemProp="position" content={ index } />
            </Link>
        );
    }
}

export default BreadcrumbComponent;
