/**
 * @description Config used in multi-manufacturer cart utility
 * @author      C. M. de Picciotto <cmdepicciotto@sepoy.io>
 */

/**
 * @const {string} RUG_ATTRIBUTE_SET_ID
 */
export const RUG_ATTRIBUTE_SET_ID = 14;

/**
 * @const {string} ACCESSORIES_ATTRIBUTE_SET_ID
 */
export const ACCESSORIES_ATTRIBUTE_SET_ID = 9;

/**
 * @const {string} SAMPLE_PRODUCT_SUFFIX_SKU
 */
export const SAMPLE_PRODUCT_SUFFIX_SKU = '-sample';
