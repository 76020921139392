import Captcha from 'Component/Captcha';
import { CAPTCHA_NEWSLETTER_ID } from 'Component/Captcha/Captcha.config';
import Form from 'Component/Form';
import {
    NewsletterSubscription as SourceNewsletterSubscription
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

/** @namespace ZnetPwa/Component/NewsletterSubscription/Component/NewsletterSubscriptionComponent */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscription {
    renderActions() {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'FieldForm', elem: 'Button' } }
              aria-label={ __('Submit') }
            >
                { __('Submit') }
            </button>
        );
    }

    render() {
        const { isLoading, onFormSubmit, setToken } = this.props;
        return (
            <div block="NewsletterSubscription">
                <Form
                  onSubmitSuccess={ onFormSubmit }
                  mix={ { block: 'FieldForm' } }
                >
                    <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                        <span block="FieldForm" elem="Title">
                            { __('Stay Informed') }
                            <br />
                            <small block="FieldForm" elem="Title-Caption">{ __('Join our Insiders Club') }</small>
                        </span>
                        <div block="FieldForm" elem="FieldWrapper">
                            <div block="FieldForm" elem="Fieldset">
                                { this.renderFields() }
                                { this.renderActions() }
                            </div>
                            <Captcha setToken={ setToken } captchaId={ CAPTCHA_NEWSLETTER_ID } />
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
