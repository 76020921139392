export * from 'SourceComponent/ProductActions/ProductActions.config';
export const PRODUCT_ACTIONS_WISHLIST_BUTTON = 'ProductActionsWishlist';
export const PRODUCT_DETAILS = 'details';
export const ACCESSORY_DETAILS = 'accessory-details';
export const PRODUCT_ACCESSORIES = 'accessories';
export const RUG_CHILD_DETAILS = 'rug-child-details';
export const FLOORING_CATEGORY = 'Flooring';
export const NUMBER_OF_SWATCHES = 100;
export const MAX_ACCESSORIES_COUNT = 100;
export const ESTIMATED_SHIPPING_DAYS = 4;
export const ATTRIBUTE_SET_ACCESSORIES = 'Accessories';
export const INSTALL_LOCATION = 'install_location';
export const INSTALL_LOCATION_GROUP = 'installation-info';
export const WARRANTY_INFO = 'warranty_info';
export const WARRANTY_INFO_GROUP = 'warranty-info';
export const RUGS = 'Rug';
export const STANDARD_QUOTE_FORM = 'Standard Quote Form';
export const STRICT_QUOTE_FORM = 'Strict Quote Form';
export const NO_MAP_RESTRICTIONS = 'No MAP Restrictions';
export const WHOLESALE = 'Wholesale';
export const VERIFIED_CUSTOMERS = 'Verified Customers';
