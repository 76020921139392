export * from 'SourceUtil/Address/index';

/**
 * Added try catch as if there is a downtime in zippotamus
 * it is impossible to checkout. This way it allows to input
 * any state/zipcode if there is a downtime
 * Ref: https://sepoy.atlassian.net/browse/ZFR-1792
 */
/** @namespace ZnetPwa/Util/Address/Index/getCityAndRegionFromZipcode */
export const getCityAndRegionFromZipcode = async (countryId, value) => {
    try {
        const response = await fetch(`https://api.zippopotam.us/${countryId}/${value.split(' ')[0]}`);
        const data = await response.json();

        return data && Object.entries(data).length > 0
            ? [
                data.places[0]['place name'],
                data.places[0]['state abbreviation']
            ]
            : [null, null];
    } catch (error) {
        console.log(error);

        return [null, null];
    }
};
