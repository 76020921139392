/* eslint-disable max-len */
import { lazy } from 'react';

import { WidgetFactory as SourceWidgetFactory } from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    CATALOG_PRODUCT_LIST,
    INSPIRATION_PRODUCT_LIST,
    NEW_PRODUCTS,
    RECENTLY_VIEWED,
    SLIDER
} from './WidgetFactory.config';

export const ProductListWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/ProductListWidget'));
export const NewProducts = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/NewProducts'));
export const HomeSlider = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/SliderWidget'));
export const RecentlyViewedWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/RecentlyViewedWidget'));

export const InspirationProductListWidget = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Component/InspirationProductListWidget'));

/** @namespace ZnetPwa/Component/WidgetFactory/Component/WidgetFactoryComponent */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    renderMap = {
        [SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback
        },
        [NEW_PRODUCTS]: {
            component: NewProducts
        },
        [CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget
        },
        [RECENTLY_VIEWED]: {
            component: RecentlyViewedWidget
        },
        [INSPIRATION_PRODUCT_LIST]: {
            component: InspirationProductListWidget
        }
    };
}

export default WidgetFactoryComponent;
