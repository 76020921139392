import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { BsInfoCircle } from 'react-icons/bs';
import { GrFormClose } from 'react-icons/gr';

import TooltipComponent from 'Component/Tooltip/Tooltip.component';

import 'Component/Tooltip/Tooltip.style';
import './AccessoriesTooltip.style.scss';

/** @namespace ZnetPwa/Component/AccessoriesTooltip/Component/AccessoriesTooltipComponent */
export class AccessoriesTooltipComponent extends TooltipComponent {
    static propTypes = {
        ...this.propTypes,
        onHover: PropTypes.func.isRequired,
        onMouseEnter: PropTypes.bool.isRequired,
        onMouseLeave: PropTypes.bool.isRequired,
        blockRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired,
        tooltipRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired,
        tooltipWrapperRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired
    };

    renderWrapper() {
        const {
            tooltipText,
            blockRef,
            isShowing,
            onClickOutside,
            preventClick,
            tooltipWrapperRef
        } = this.props;

        if (blockRef?.current) {
            return (
                createPortal(
                    (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <div
                        block="Tooltip"
                        elem="Wrapper"
                        mods={ { isShowing } }
                        onClick={ preventClick }
                        onKeyDown={ preventClick }
                        ref={ tooltipWrapperRef }
                      >
                        <GrFormClose
                          block="Tooltip"
                          elem="CloseIcon"
                          onClick={ onClickOutside }
                          size={ 18 }
                        />
                        <div
                          block="Tooltip"
                          elem="Text"
                        >
                          { tooltipText }
                        </div>
                      </div>
                    ),
                    blockRef.current
                )
            );
        }

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              block="Tooltip"
              elem="Wrapper"
              mods={ { isShowing } }
              onClick={ preventClick }
              onKeyDown={ preventClick }
            >
              <GrFormClose
                block="Tooltip"
                elem="CloseIcon"
                onClick={ onClickOutside }
                size={ 18 }
              />
              <div
                block="Tooltip"
                elem="Text"
              >
                { tooltipText }
              </div>
            </div>
        );
    }

    renderTextTooltip() {
        const {
            mix,
            onClick,
            tooltipRef,
            onMouseEnter,
            onMouseLeave
        } = this.props;

        return (
          <div
            block="Tooltip"
            mix={ mix }
          >
                <div
                  block="Tooltip"
                  elem="InfoClickableArea"
                  onClick={ onClick }
                  onKeyDown={ onClick }
                  onMouseEnter={ onMouseEnter }
                  onMouseLeave={ onMouseLeave }
                  role="button"
                  tabIndex="-1"
                >
                  <BsInfoCircle
                    block="Tooltip"
                    elem="InfoIcon"
                  />
                </div>
                  <div block="Tooltip" elem="RefElem" ref={ tooltipRef } />
                  { this.renderWrapper() }
          </div>
        );
    }
}

export default AccessoriesTooltipComponent;
