/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const EVENT_GENERAL = 'general';
export const EVENT_IMPRESSION = 'productImpression';
export const EVENT_GA4_IMPRESSION = 'view_item_list_ga4';
export const EVENT_PRODUCT_CLICK = 'productClick';
export const EVENT_ADD_TO_CART = 'addToCart';
export const EVENT_GA4_ADD_TO_CART = 'add_to_cart_ga4';
export const EVENT_REMOVE_FROM_CART = 'removeFromCart';
export const EVENT_GA4_REMOVE_FROM_CART = 'remove_from_cart_ga4';
export const EVENT_PRODUCT_DETAIL = 'productDetail';
export const EVENT_GA4_VIEW_ITEM = 'view_item_ga4';
export const EVENT_PURCHASE = 'purchase';
export const EVENT_GA4_PURCHASE = 'purchase_ga4';

export const EVENT_GA4_SAMPLE_PURCHASE = 'sample_purchase_ga4';
export const EVENT_GA4_PREMIUM_CUSTOMER_ORDER = 'premium_customer_order_ga4';
export const EVENT_GA4_OPEN_REQUEST_QUOTE_FORM = 'open_request_quote_form_ga4';
export const EVENT_GA4_SUBMIT_REQUEST_QUOTE_FORM = 'submit_request_quote_form_ga4';
export const EVENT_GA4_OPEN_REQUEST_PRICE_FORM = 'open_request_price_form_ga4';
export const EVENT_GA4_SUBMIT_REQUEST_PRICE_FORM = 'submit_request_price_form_ga4';

export const EVENT_CHECKOUT = 'checkout';
export const EVENT_GA4_CHECKOUT = 'begin_checkout_ga4';
export const EVENT_CHECKOUT_OPTION = 'checkoutOption';
export const EVENT_USER_REGISTER = 'userRegister';
export const EVENT_USER_LOGIN = 'userLogin';
export const EVENT_NOT_FOUND = 'notFound';
export const EVENT_CATEGORY_FILTERS = 'categoryFilters';
export const EVENT_ADDITIONAL = 'additional';

export const DATA_LAYER_NAME = 'dataLayer';
export const GA4_DATA_LAYER_NAME = 'dataLayer';
export const GROUPED_PRODUCTS_PREFIX = 'GROUPED_PRODUCTS_';
export const GROUPED_PRODUCTS_GUEST = 'GROUPED_PRODUCTS_GUEST';
export const GTM_INJECTION_TIMEOUT = 4000;

export const UA_GA4_EVENT_MAP = {
    [EVENT_PURCHASE]: EVENT_GA4_PURCHASE,
    [EVENT_GA4_SAMPLE_PURCHASE]: EVENT_GA4_SAMPLE_PURCHASE,
    [EVENT_GA4_PREMIUM_CUSTOMER_ORDER]: EVENT_GA4_PREMIUM_CUSTOMER_ORDER,
    [EVENT_GA4_OPEN_REQUEST_QUOTE_FORM]: EVENT_GA4_OPEN_REQUEST_QUOTE_FORM,
    [EVENT_GA4_SUBMIT_REQUEST_QUOTE_FORM]: EVENT_GA4_SUBMIT_REQUEST_QUOTE_FORM,
    [EVENT_GA4_OPEN_REQUEST_PRICE_FORM]: EVENT_GA4_OPEN_REQUEST_PRICE_FORM,
    [EVENT_GA4_SUBMIT_REQUEST_PRICE_FORM]: EVENT_GA4_SUBMIT_REQUEST_PRICE_FORM,
    [EVENT_IMPRESSION]: EVENT_GA4_IMPRESSION,
    [EVENT_PRODUCT_DETAIL]: EVENT_GA4_VIEW_ITEM,
    [EVENT_ADD_TO_CART]: EVENT_GA4_ADD_TO_CART,
    [EVENT_CHECKOUT]: EVENT_GA4_CHECKOUT,
    [EVENT_REMOVE_FROM_CART]: EVENT_GA4_REMOVE_FROM_CART
};
