import PropTypes from 'prop-types';
import MyAccountMySocialNetworks from '../component/MyAccountMySocialNetworks';

export const SOCIAL = 'social-networks';

export class MyAccountComponentPlugin {
    renderMap = originalMember => ({
        ...originalMember,
        [SOCIAL]: MyAccountMySocialNetworks
    });

    propTypes = originalMember => ({
        ...originalMember,
        activeTab: PropTypes.oneOfType([
            originalMember.activeTab,
            PropTypes.oneOf([SOCIAL])
        ])
    });
}

// eslint-disable-next-line
export class MyAccountContainerPlugin {
    tabMap = originalMember => ({
        ...originalMember,
        [SOCIAL]: {
            url: '/social-networks',
            name: __('Social Networks')
        }
    });
}

/* eslint-disable-next-line */
export const { renderMap, propTypes } = new MyAccountComponentPlugin();
export const { tabMap } = new MyAccountContainerPlugin();

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: [
                {
                    position: 101,
                    implementation: renderMap
                }
            ]
        },
        'static-member': {
            propTypes: [
                {
                    position: 101,
                    implementation: propTypes
                }
            ]
        }
    },
    'Component/MyAccountTabList/Component': {
        'static-member': {
            propTypes: [
                {
                    position: 101,
                    implementation: propTypes
                }
            ]
        }
    },
    'Route/MyAccount/Container': {
        'member-property': {
            tabMap: [
                {
                    position: 101,
                    implementation: tabMap
                }
            ]
        }
    }
};
