import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import DripQuery from '../query/Drip.query';

export const addDripConfigQuery = (args, callback) => {
    const original = callback(...args);

    return [
        ...(Array.isArray(original) ? original : [original]),
        DripQuery.getDripConfiguration()
    ];
};

export const addDripToConfigReducerInitialState = (args, callback) => {
    const { drip } = BrowserDatabase.getItem('config') || { drip: {} };

    return {
        ...callback(...args),
        drip
    };
};

export const addDripToConfigUpdate = (args, callback, context) => {
    const [, action] = args;

    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { drip } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        drip
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addDripConfigQuery
        }
    },
    'Store/Config/Reducer': {
        function: addDripToConfigUpdate
    },
    'Store/Config/Reducer/getInitialState': {
        function: addDripToConfigReducerInitialState
    }
};
