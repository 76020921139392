/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';

import {
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS
} from './Checkout.action';

/** @namespace ZnetPwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true
});

/** @namespace ZnetPwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;
        const fieldsToUpdate = BrowserDatabase.getItem('shippingFields') || {};

        Object.keys(shippingFields).forEach((key) => {
            if (key !== 'installationStatusSelected' && shippingFields[key] !== '' && shippingFields[key] !== null) {
                fieldsToUpdate[key] = shippingFields[key];
            }
        });

        if (!shippingFields.street1 && shippingFields.street0) {
            fieldsToUpdate.street1 = '';
        }

        if (!shippingFields.company && shippingFields.street0) {
            fieldsToUpdate.company = '';
        }

        BrowserDatabase.setItem(fieldsToUpdate, 'shippingFields');

        return {
            ...state,
            shippingFields
        };

    case UPDATE_EMAIL:
        const { email } = action;
        const browserData = BrowserDatabase.getItem('shippingFields') || {};

        if (email) {
            browserData.email = email;
            BrowserDatabase.setItem(browserData, 'shippingFields');
        }

        return {
            ...state,
            email
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;
        return {
            ...state,
            isEmailAvailable
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
