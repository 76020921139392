import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';

import ClickOutside from 'Component/ClickOutside';
import SearchSpringOverlay from 'Component/SearchSpringOverlay';
import { isCrawler } from 'Util/Browser';

import './SearchSpringField.style';

/** @namespace ZnetPwa/Component/SearchSpringField/Component/SearchSpringFieldComponent */
export class SearchSpringFieldComponent extends PureComponent {
    static propTypes = {
        onSearchBarClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onSearchOutsideProcessor: PropTypes.func.isRequired,
        onAutosuggestOptionClick: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        fieldValue: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        clearSearch: PropTypes.func.isRequired,
        showMobileSearch: PropTypes.func.isRequired,
        hideMobileSearch: PropTypes.func.isRequired,
        isMobileSearchOpen: PropTypes.bool.isRequired,
        isSuggestOptionClick: PropTypes.bool.isRequired
    };

    searchInput = null;

    renderClearForm() {
        const {
            fieldValue,
            clearSearch
        } = this.props;

        if (!isCrawler()) {
            return (
              <button
                block="SearchField"
                elem="ClearForm"
                mods={ { isVisible: fieldValue.length > 0 } }
                onClick={ clearSearch }
                onKeyDown={ clearSearch }
              >
                  { __('Clear') }
              </button>
            );
        }

        return null;
    }

    renderSearchField() {
        const {
            value,
            fieldValue,
            isActive,
            onSearchBarChange,
            onSearchBarClick,
            onSearchOutsideProcessor,
            onAutosuggestOptionClick,
            handleSubmit,
            hideMobileSearch,
            isSuggestOptionClick
        } = this.props;

        if (window.location.pathname !== '/') {
            return (
                // eslint-disable-next-line react/jsx-no-bind
                <ClickOutside onClick={ () => onSearchOutsideProcessor(isSuggestOptionClick) }>
                    <div block="SearchField" elem="Wrapper">
                        <form
                          block="SearchField"
                          elem="Form"
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={ (e) => handleSubmit(e) }
                        >
                            <input
                              id="search-field"
                              block="SearchField"
                              elem="Input"
                              value={ fieldValue }
                              onClick={ onSearchBarClick }
                              onChange={ onSearchBarChange }
                              mods={ { isActive } }
                              autoComplete="off"
                              placeholder={ __('Search') }
                              aria-label="Search"
                              ref={ (input) => {
                                  this.searchInput = input;
                              } }
                            />
                            <button
                              block="SearchField"
                              elem="SubmitButton"
                              type="submit"
                              mods={ { isActive } }
                            >
                                <AiOutlineSearch size="20px" className="Icon" />
                            </button>
                            { this.renderClearForm() }
                        </form>
                        <SearchSpringOverlay
                          hideMobileSearch={ hideMobileSearch }
                          search={ value }
                          onAutosuggestOptionClick={ onAutosuggestOptionClick }
                        />
                    </div>
                </ClickOutside>
            );
        }

        /** Used to have Sitelinks search box */
        return (
            // eslint-disable-next-line react/jsx-no-bind
            <ClickOutside onClick={ () => onSearchOutsideProcessor(isSuggestOptionClick) }>
                <div block="SearchField" elem="Wrapper">
                    <div itemScope itemType="https://schema.org/WebSite">
                        <meta itemProp="url" content={ window.location.hostname } />
                        <form
                          block="SearchField"
                          elem="Form"
                          // eslint-disable-next-line react/jsx-no-bind
                          onSubmit={ (e) => handleSubmit(e) }
                          itemProp="potentialAction"
                          itemScope
                          itemType="https://schema.org/SearchAction"
                        >
                            <meta
                              itemProp="target"
                              content={ `${ window.location.hostname }/search/{search_term_string}` }
                            />
                            <input
                              id="search-field"
                              block="SearchField"
                              elem="Input"
                              itemProp="query-input"
                              name="search_term_string"
                              value={ fieldValue }
                              onClick={ onSearchBarClick }
                              onChange={ onSearchBarChange }
                              mods={ { isActive } }
                              autoComplete="off"
                              placeholder={ __('Search') }
                              aria-label="Search"
                              ref={ (input) => {
                                  this.searchInput = input;
                              } }
                            />
                            <button
                              block="SearchField"
                              elem="SubmitButton"
                              type="submit"
                              mods={ { isActive } }
                            >
                                <AiOutlineSearch size="20px" className="Icon" />
                            </button>
                            { this.renderClearForm() }
                        </form>
                    </div>
                    <SearchSpringOverlay
                      hideMobileSearch={ hideMobileSearch }
                      search={ value }
                      onAutosuggestOptionClick={ onAutosuggestOptionClick }
                    />
                </div>
            </ClickOutside>
        );
    }

    renderMobileSearchField() {
        const { hideMobileSearch, isMobileSearchOpen } = this.props;

        if (!isMobileSearchOpen) {
            return null;
        }

        return (
            <div
              block="SearchField"
              elem="MobileSearchOverlay"
            >
                <div
                  block="SearchField"
                  elem="MobileSearchWrapper"
                >
                  { this.renderSearchField() }
                  <button
                    block="SearchField"
                    elem="CancelButton"
                    onClick={ hideMobileSearch }
                  >
                    { __('Cancel') }
                  </button>
                </div>
            </div>
        );
    }

    /**
     * Render
     *
     * @return {JSX}
     */

    render() {
        const { isMobile, isVisible, showMobileSearch } = this.props;

        if (!isVisible) {
            return null;
        }

        if (isMobile) {
            return (
                <>
                <div block="SearchField" elem="MobileSearch">
                  <button
                    aria-label="Search"
                    block="SearchField"
                    elem="MobileSearchButton"
                    onClick={ showMobileSearch }
                  >
                      <BsSearch
                        size="20px"
                        block="SearchField"
                        elem="SearchIcon"
                      />
                  </button>
                </div>
                { this.renderMobileSearchField() }
                </>
            );
        }

        return this.renderSearchField();
    }
}

export default SearchSpringFieldComponent;
