import PropTypes from 'prop-types';

import { Image as SourceImage } from 'SourceComponent/Image/Image.component';

import {
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from './Image.config';

/** @namespace ZnetPwa/Component/Image/Component/ImageComponent */
export class ImageComponent extends SourceImage {
    static propTypes = {
        ...this.propTypes,
        style: PropTypes.shape({
            objectFit: PropTypes.string,
            width: PropTypes.string,
            height: PropTypes.string
        })
    };

    renderImage() {
        const {
            alt,
            isPlaceholder,
            src,
            style,
            title,
            isLazy = true
        } = this.props;
        const { imageStatus } = this.state;
        const loadingStyle = isLazy ? 'lazy' : 'eager';

        if (isPlaceholder) {
            return null;
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
            return this.renderImageNotFound();
        case IMAGE_NOT_SPECIFIED:
            return (
                <span block="Image" elem="Content">{ __('Image not specified') }</span>
            );
        case IMAGE_LOADED:
        case IMAGE_LOADING:
            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src || '' }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  title={ title }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  loading={ loadingStyle }
                />
            );
        default:
            return null;
        }
    }
}

export default ImageComponent;
