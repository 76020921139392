export const QUOTE_FORM = 'QUOTE_FORM';
export const QUOTE_FORM_THANKS = 'QuoteFormThanks';
export const API_KEY = '0fe724373b781785a256e3f2ea10ec05';
export const ACCOUNT_ID = '8191580';
export const QUOTE_REQUEST_WORKFLOW = 295825263;
export const TWO_HUNDRED_FIFTY = 250;
export const FIVE_PERCENT_DECIMAL = 0.05;
export const THREE_HUNDRED_FORTY_NINE = 349;
export const TWO_HUNDRED_SEVENTY_NINE = 279;
export const TWO_HUNDRED_NINETY_NINE = 299;
export const FORTY_NINE = 49;
export const SHAW = 'Shaw';
export const MSI = 'MSI';
export const MOHAWK = 'Mohawk';
export const KARNDEAN = 'Karndean';
export const THREE = 3;
