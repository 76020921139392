/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';
import PrerenderReducer from 'Store/Prerender/Prerender.reducer';
import ProductListPageReducer from 'Store/ProductListPage/ProductListPage.reducer';

/** @namespace ZnetPwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...baseGetStaticReducers(),
    ProductListPageReducer,
    PrerenderReducer
});

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
