import { Field } from 'Util/Query';

/** @namespace ScandiPWA/SocialLoginGraphql/Query */
export class SocialNetworksQuery {
    /**
     * Get enabled social buttons
     *
     * @returns {*}
     */
    getSocialButtonsQuery() {
        return new Field('socialLogin_buttons')
            .addFieldList(this._getSocialButtonFields());
    }

    /**
     * Get customer data about linked/unlinked social accounts
     *
     * @returns {*}
     */
    getAccountsDataQuery() {
        return new Field('socialLogin_accountData')
            .addField(this._getLinkedAccounts())
            .addField(this._getUnlinkedAccounts());
    }

    /**
     * Delete social account link
     *
     * @returns {Field}
     */
    unlinkMutation(type) {
        return new Field('socialLogin_unlinkAccount')
            .addArgument('type', 'String!', type)
            .addField(this._getLinkedAccountsFields())
            .addField(this._getUnlinkedAccountsFields());
    }

    getLogoutCustomerMutation() {
        return new Field('socialLogin_logoutCustomer');
    }

    _getLinkedAccounts() {
        return new Field('linked')
            .addFieldList(this._getLinkedAccountsFields());
    }

    _getUnlinkedAccounts() {
        return new Field('buttons')
            .addFieldList(this._getUnlinkedAccountsFields());
    }

    _getUnlinkedAccountsFields() {
        return [
            'type',
            'label',
            'url'
        ];
    }

    _getSocialButtonFields() {
        return [
            'type',
            'label',
            'url'
        ];
    }

    _getLinkedAccountsFields() {
        return [
            'type',
            'label',
            'url',
            'name'
        ];
    }
}

export default new (SocialNetworksQuery)();
