import { Logo as SourceLogo } from 'SourceComponent/Logo/Logo.component';

import { ReactComponent as ZnetLogo } from './logo.svg';

/** @namespace ZnetPwa/Component/Logo/Component/LogoComponent */
export class LogoComponent extends SourceLogo {
    renderPlaceholderLogo() {
        return (
            <ZnetLogo />
        );
    }
}

export default LogoComponent;
