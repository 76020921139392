/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { BiHeart } from 'react-icons/bi';
import { BsBag, BsPerson, BsX } from 'react-icons/bs';

import CartOverlay from 'Component/CartOverlay';
import Html from 'Component/Html';
import Link from 'Component/Link';
import LoginAccountComponent from 'Component/LoginAccount/LoginAccount.component';
import Menu from 'Component/Menu';
import MyAccountTabListDropdown from 'Component/MyAccountTabListDropdown';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import OfflineNotice from 'Component/OfflineNotice';
import SearchSpringField from 'Component/SearchSpringField';
import Slider from 'Component/Slider';
import TermsPopup from 'Component/TermsPopup';
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';
import { isSignedIn } from 'Util/Auth';
import { isCrawler } from 'Util/Browser';

import {
    CART,
    CART_EDITING,
    CART_OVERLAY,
    CATEGORY,
    CHECKOUT,
    CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS,
    CMS_PAGE,
    CONTACT_US,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    MENU,
    MENU_SUBCATEGORY,
    PDP,
    POPUP,
    PRODUCT_COMPARE,
    SEARCH
} from './Header.config';

import './Header.style';

/** @namespace ZnetPwa/Component/Header/Component/HeaderComponent */
export class HeaderComponent extends SourceHeader {
    static propTypes = {
        ...this.propTypes,
        headerRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired,
        toggleOverlay: PropTypes.func.isRequired,
        isMyAccountOverlayOpened: PropTypes.bool.isRequired,
        phone: PropTypes.string.isRequired
    };

    stateMap = {
        [DEFAULT_STATE_NAME]: {
            title: false,
            logo: true
        },
        [POPUP]: {
            title: false,
            close: false
        },
        [PDP]: {
            back: true,
            title: false
        },
        [CATEGORY]: {
            back: true,
            title: false
        },
        [CUSTOMER_ACCOUNT]: {
            title: false
        },
        [CUSTOMER_SUB_ACCOUNT]: {
            title: false,
            back: true
        },
        [CUSTOMER_ACCOUNT_PAGE]: {
            title: false
        },
        [CUSTOMER_WISHLIST]: {
            share: true,
            title: false,
            edit: true,
            ok: true
        },
        [MENU]: {
            search: true
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            title: false
        },
        [SEARCH]: {
            back: true,
            search: true
        },
        [CART]: {
            title: false
        },
        [CART_OVERLAY]: {
            title: false
        },
        [CART_EDITING]: {
            ok: true,
            title: false,
            cancel: true
        },
        [FILTER]: {
            close: true,
            clear: true,
            title: false
        },
        [CHECKOUT]: {
            back: true,
            title: false,
            account: true
        },
        [CHECKOUT_SUCCESS]: {
            title: false,
            account: true
        },
        [CHECKOUT_ACCOUNT]: {
            title: false,
            close: true
        },
        [CMS_PAGE]: {
            back: true,
            title: false
        },
        [CONTACT_US]: {
            title: false,
            back: true
        },
        [PRODUCT_COMPARE]: {
            title: false,
            back: true
        }
    };

        renderMap = {
            cancel: () => null,
            back: () => null,
            close: () => null,
            share: () => null,
            title: this.renderTitle.bind(this),
            logo: this.renderLogo.bind(this),
            compare: this.renderComparePageButton.bind(this),
            search: this.renderSearchField.bind(this),
            minicart: this.renderMinicartAndWishlist.bind(this),
            clear: () => null,
            edit: () => null,
            ok: () => null
        };

        renderLogo(isVisible = false) {
            const { isLoading } = this.props;

            if (isLoading) {
                return null;
            }

            return (
                <Link
                  to="/"
                  aria-label="Go to homepage by clicking on Znet logo"
                  aria-hidden={ !isVisible }
                  tabIndex={ isVisible ? 0 : -1 }
                  block="Header"
                  elem="LogoWrapper"
                  mods={ { isVisible } }
                  key="logo"
                  showLoader
                >
                    { this.renderLogoImage() }
                </Link>
            );
        }

        renderMobileMenu() {
            const { device } = this.props;

            if (device.isMobile) {
                return <Menu />;
            }

            return null;
        }

        renderMenu() {
            const { device, isCheckout, isCheckoutSuccess } = this.props;

            if (device.isMobile || (isCheckout && !isCheckoutSuccess)) {
                return null;
            }

            return <Menu />;
        }

        renderTitle(isVisible = false) {
            const { navigationState: { title } } = this.props;

            if (!isVisible) {
                return null;
            }

            return (
                <h1
                  key="title"
                  block="Header"
                  elem="Title"
                  mods={ { isVisible } }
                >
                    { title }
                </h1>
            );
        }

        renderSearchField() {
            const {
                searchCriteria,
                pathname,
                onSearchOutsideClick,
                onSearchBarFocus,
                onSearchBarChange,
                isCheckout,
                isCheckoutSuccess,
                onClearSearchButtonClick,
                navigationState: { name },
                device: { isMobile }
            } = this.props;

            const isVisible = !isMobile;

            if (isCheckout && !isCheckoutSuccess) {
                return null;
            }

            return (
                <SearchSpringField
                  key="search"
                  pathname={ pathname }
                  searchCriteria={ searchCriteria }
                  onSearchOutsideClick={ onSearchOutsideClick }
                  onSearchBarClick={ onSearchBarFocus }
                  onSearchBarChange={ onSearchBarChange }
                  onClearSearchButtonClick={ onClearSearchButtonClick }
                  isActive={ name === SEARCH }
                  isMobile={ isMobile }
                  isVisible={ isVisible }
                />
            );
        }

        renderMobileSearchField() {
            const {
                searchCriteria,
                pathname,
                onSearchOutsideClick,
                onSearchBarFocus,
                onSearchBarChange,
                isCheckout,
                isCheckoutSuccess,
                onClearSearchButtonClick,
                navigationState: { name },
                device: { isMobile }
            } = this.props;

            const isVisible = isMobile;

            if (isCheckout && !isCheckoutSuccess) {
                return null;
            }

            return (
                <SearchSpringField
                  key="search"
                  pathname={ pathname }
                  searchCriteria={ searchCriteria }
                  onSearchOutsideClick={ onSearchOutsideClick }
                  onSearchBarClick={ onSearchBarFocus }
                  onSearchBarChange={ onSearchBarChange }
                  onClearSearchButtonClick={ onClearSearchButtonClick }
                  isActive={ name === SEARCH }
                  isMobile={ isMobile }
                  isVisible={ isVisible }
                />
            );
        }

        renderTopMenuCloseButton() {
            const { showCloseTopMenu, closeTopMenu } = this.props;

            if (!showCloseTopMenu) {
                return null;
            }

            return (
                <BsX
                  size="20px"
                  block="Header"
                  elem="TopClose"
                  onClick={ closeTopMenu }
                />
            );
        }

        renderTopMenuItem = (item) => (
                <div block="Header" elem="TopItem">
                    <Html content={ item.content } />
                </div>
        );

        renderTopMenu() {
            const {
                activeTopMenu,
                onActiveTopMenuChange,
                topMenuRef,
                topMenuBlocks: {
                    topCmsBlocks: {
                        items = []
                    } = {}
                }
            } = this.props;

            return (
                <div
                  block="Header"
                  elem="TopMenu"
                  key="topMenu"
                  id="TopMenu"
                  ref={ topMenuRef }
                >
                    <Slider
                      activeImage={ activeTopMenu }
                      onActiveImageChange={ onActiveTopMenuChange }
                      mix={ { block: 'Header', elem: 'Slider' } }
                      isTopMenu
                    >
                        { items.map((item) => (
                            this.renderTopMenuItem(item)
                        )) }
                    </Slider>
                    { this.renderTopMenuCloseButton() }
                </div>
            );
        }

        renderMobileMyAccountLink() {
            const { device: { isMobile }, openSignInPopUp } = this.props;

            if (isMobile) {
                if (isSignedIn()) {
                    return (
                        <div block="Header" elem="SignIn">
                            <Link
                              to="/my-account/dashboard"
                              aria-label="Your Account"
                              block="Header"
                              elem="MyAccountLinkMobile"
                              key="my-account-link"
                            >
                                <strong>{ __('Your Account') }</strong>
                            </Link>
                        </div>
                    );
                }

                return (
                    <div block="Header" elem="SignIn">
                        <button
                          aria-label="Sign In"
                          block="Header"
                          elem="SignIn"
                          onClick={ openSignInPopUp }
                        >
                            <BsPerson
                              size="26px"
                              block="Header"
                              elem="PersonIcon"
                            />
                        </button>
                    </div>
                );
            }

            return null;
        }

        renderMyAccountLink() {
            const { openSignInPopUp, toggleOverlay, isMyAccountOverlayOpened } = this.props;

            if (isSignedIn()) {
                return (
                    <>
                    <Link
                      to="/my-account/dashboard"
                      aria-label="Sign In"
                      block="Header"
                      elem="MyAccountLink"
                      key="my-account-link"
                      // eslint-disable-next-line react/jsx-no-bind
                      onMouseOver={ toggleOverlay }
                      onMouseOut={ toggleOverlay }
                    >
                        <strong>
                            { __('Your Account') }
                        <b
                          block="Header"
                          elem="Caret"
                        />
                        </strong>
                    </Link>
                    <MyAccountTabListDropdown isMyAccountOverlayOpened={ isMyAccountOverlayOpened } />
                    </>
                );
            }

            return (
            <button
              aria-label="Sign In"
              block="Header"
              elem="SignIn"
              onClick={ openSignInPopUp }
            >
                <BsPerson
                  size="24px"
                  block="Header"
                  elem="PersonIcon"
                />
            </button>
            );
        }

        renderContactUsLink() {
            return (
            <Link
              to="/contact-us"
              aria-label="Contact Us"
              block="Header"
              elem="ContactUsLink"
              key="contact-us-link"
            >
                <span>{ __('Contact Us') }</span>
            </Link>
            );
        }

        renderBlogLink() {
            return (
            <Link
              to="/blog/"
              aria-label="Blog"
              elem="BlogLink"
              key="blog-link"
            >
                { __('Blog') }
            </Link>
            );
        }

        renderCallUs() {
            const { device: { isMobile }, phone } = this.props;
            const callText = isMobile ? __('Call Us: ') : __('Have a question? ');

            return (
            <a
              href={ `tel:${ phone }` }
              aria-label="Sample Sets for Installers, Contractors and Decorators"
              block="Header"
              elem="SampleLink"
              key="sample-link"
            >
                { callText }
                <strong>
                    { phone }
                </strong>
            </a>
            );
        }

        renderComparePageButton() {
            return null;
        }

        renderContacts() {
            return (
            <p
              block="Header"
              elem="FlooringSamples"
            >
                { __('Don’t forget your ') }
                <Link
                  to="/flooring-samples"
                  aria-label="Get Sample Order"
                  elem="FlooringSamplesLink"
                  key="flooring-samples-link"
                >
                    <strong>{ __('SAMPLE ORDERS!') }</strong>
                </Link>
            </p>
            );
        }

        renderSampleLink() {
            return (
            <Link
              to="/sample-sets-form"
              aria-label="Sample Sets for Installers, Contractors and Decorators"
              elem="SampleLink"
              key="sample-link"
            >
                { __('Sample Sets for Installers,\nContractors and Decorators') }
            </Link>
            );
        }

        renderAccountButton(isVisible) {
            const {
                onMyAccountButtonClick
            } = this.props;

            return (
            <button
              block="Header"
              elem="MyAccountWrapper"
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              aria-label="Open my account"
              id="myAccount"
              key="myAccountButton"
            >
                <div
                  block="Header"
                  elem="MyAccountTitle"
                  mods={ { isVisible, type: 'account' } }
                >
                    <strong>{ __('SIGN IN') }</strong>
                </div>
            </button>
            );
        }

        renderWishlistButton() {
            const wishlistLink = isSignedIn() ? '/my-account/my-wishlist' : '/account/login';

            return (
                <Link
                  block="Header"
                  elem="Link"
                  to={ wishlistLink }
                >
                    <div
                      block="Header"
                      elem="Wishlist"
                    >
                        <BiHeart size="24px" className="Icon" />
                    </div>
                </Link>
            );
        }

        renderMinicartAndWishlist(isVisible = false) {
            const { isCheckout, isCheckoutSuccess, device } = this.props;

            if ((isCheckout && !isCheckoutSuccess) || device.isMobile) {
                return null;
            }

            return (
                    <div
                      block="Header"
                      elem="Button"
                      mods={ { isVisible, type: 'minicart' } }
                      key="miniCart"
                    >
                        { this.renderMinicartButton() }
                        { this.renderMyAccountLink() }
                    </div>
            );
        }

        renderQtyIcon() {
            const {
                cartTotals: {
                    items_qty
                }
            } = this.props;

            if (!isCrawler()) {
                return (
                    <div
                      block="Header"
                      elem="MinicartQtyWrapper"
                      mods={ { isVisible: items_qty > 0 } }
                    >
                        <p
                          block="Header"
                          elem="MinicartQty"
                        >
                            { items_qty }
                        </p>
                    </div>
                );
            }

            return null;
        }

        renderMinicartButton() {
            const {
                onMinicartButtonClick
            } = this.props;

            return (
            <button
              block="Header"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              onClick={ onMinicartButtonClick }
            >
                <BsBag size="21px" block="Header" elem="BagIcon" />
                { this.renderQtyIcon() }
            </button>
            );
        }

        renderMeta() {
            return (
                <div itemScope itemType="https://schema.org/Organization">
                    <meta itemProp="name" content="Znet Flooring" />
                    <meta
                      itemProp="description"
                      content="Shop for flooring products of top brands from the online store
                      at Znet Flooring, ranging from hardwood, laminate, vinyl, area rugs &amp; more."
                    />
                    <meta itemProp="logo" content="/pub/media/favicon.jpg" />
                    <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                        <meta itemProp="streetAddress" content="10161 Park Run Dr. Ste 150" />
                        <meta itemProp="postalCode" content="89145" />
                        <meta itemProp="addressLocality" content="Las Vegas, United States" />
                    </div>
                    <meta itemProp="location" content="10161 Park Run Dr. Ste 150 Las Vegas, NV 89145" />
                    <meta itemProp="telephone" content="7252010303" />
                    <meta itemProp="url" content={ window.location.hostname } />
                </div>
            );
        }

        renderMobileLinks() {
            const {
                device: { isMobile }
            } = this.props;

            if (!isMobile) {
                return null;
            }

            return (
                <div
                  block="Header"
                  elem="MobileLinks"
                >
                    { this.renderMobileSearchField() }
                    { this.renderMinicartButton() }
                    { this.renderMobileMyAccountLink() }
                </div>
            );
        }

        renderOfflineNotice() {
            if (!isCrawler()) {
                return <OfflineNotice />;
            }

            return null;
        }

        renderCartOverlay() {
            if (!isCrawler()) {
                return <CartOverlay />;
            }

            return null;
        }

        render() {
            const { stateMap } = this;
            const {
                headerRef,
                navigationState: { name },
                isCheckout,
                device
            } = this.props;

            if (!device.isMobile) {
            // hide edit button on desktop
                stateMap[CUSTOMER_WISHLIST].edit = false;
                stateMap[CUSTOMER_WISHLIST].share = false;
                stateMap[CART_OVERLAY].edit = false;
            }

            return (
                <>
                    { this.renderTopMenu() }
                    <section block="Header" elem="Wrapper" ref={ headerRef }>
                        <header
                          block="Header"
                          mods={ { name, isCheckout } }
                          mix={ { block: 'FixedElement', elem: 'Top' } }
                          ref={ this.logoRef }
                        >
                            { this.renderMeta() }
                            <nav block="Header" elem="Nav" mods={ { isCheckout } }>
                                <div
                                  block="Header"
                                  elem="Nav-Container"
                                  mods={ { isCheckout } }
                                >
                                    { this.renderMobileMenu() }
                                    { this.renderNavigationState() }
                                    { this.renderMobileLinks() }
                                </div>
                            </nav>
                            { this.renderMenu() }
                        </header>
                        { this.renderOfflineNotice() }
                        <LoginAccountComponent />
                        { this.renderCartOverlay() }
                        <TermsPopup />
                    </section>
                </>
            );
        }
}

export default HeaderComponent;
