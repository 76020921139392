import { datadogRum } from '@datadog/browser-rum';
import { updatePrerender } from 'packages/prerender/src/store/Prerender/Prerender.action';

import CartQuery from 'Query/Cart.query';
import {
    CartDispatcher as SourceCartDispatcher
} from 'SourceStore/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { setGuestQuoteId } from 'Util/Cart';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';
import getStore from 'Util/Store';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

/**
 * Product Cart Dispatcher
 * @class CartDispatcher
 * @namespace ZnetPwa/Store/Cart/Dispatcher/CartDispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    resetGuestCart(dispatch) {
        /**
         * Used for prerender. Even though flag is switched to true
         * right away, prerender flag-setting logic has 2s delay, which
         * might help to render additional content
         */
        dispatch(updatePrerender({ resetGuestCart: false }));
        dispatch(updatePrerender({ resetGuestCart: true }));
        return this._updateCartData({}, dispatch);
    }

    async updateInitialCartData(dispatch) {
        dispatch(updatePrerender({ updateInitialCartData: false }));
        // Need to get current cart from BE, update cart
        try {
            // ! Get quote token first (local or from the backend) just to make sure it exists
            const quoteId = await this._getGuestQuoteId(dispatch);
            const { cartData = {} } = await fetchQuery(
                CartQuery.getCartQuery(
                    !isSignedIn() && quoteId
                )
            );

            dispatch(updatePrerender({ updateInitialCartData: true }));

            return this._updateCartData(cartData, dispatch);
        } catch (error) {
            const state = getStore().getState();
            const {
                datadog_enabled = false
            } = state.ConfigReducer;

            dispatch(updatePrerender({ updateInitialCartData: true }));

            if (datadog_enabled) {
                const message = error?.[0]?.debugMessage
                    || error?.[0]?.message
                    || 'Error in Cart for updateInitialCartData';
                const errorMessage = new Error(message);
                datadogRum.addError(errorMessage);
            }

            return this.createGuestEmptyCart(dispatch);
        }
    }

    async createGuestEmptyCart(dispatch) {
        dispatch(updatePrerender({ createGuestEmptyCart: false }));

        try {
            const {
                createEmptyCart: quoteId = ''
            } = await fetchMutation(CartQuery.getCreateEmptyCartMutation());

            setGuestQuoteId(quoteId);
            this._updateCartData({}, dispatch);
            dispatch(updatePrerender({ createGuestEmptyCart: true }));
            return quoteId;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
            dispatch(updatePrerender({ createGuestEmptyCart: true }));
            return null;
        }
    }

    updateCrossSellProducts(items, dispatch) {
        if (items && items.length) {
            const product_links = items.reduce((links, product) => {
                const { product: { product_links, variants = [] }, sku: variantSku } = product;

                const { product_links: childProductLinks } = variants.find(({ sku }) => sku === variantSku) || {};

                if (childProductLinks) {
                    Object.values(childProductLinks).filter(({ link_type }) => link_type === 'crosssell')
                        .map((item) => links.push(item));
                }

                if (product_links) {
                    Object.values(product_links).filter(({ link_type }) => link_type === 'crosssell')
                        .map((item) => links.push(item));
                }

                return links;
            }, []);

            if (product_links.length !== 0) {
                const deduplicatedLinks = product_links.filter((value, index, self) => index === self.findIndex((t) => (
                    t.linked_product_sku === value.linked_product_sku
                )));

                LinkedProductsDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.fetchCrossSellProducts(dispatch, deduplicatedLinks)
                );
            } else {
                LinkedProductsDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.clearCrossSellProducts(dispatch)
                );
            }
        } else {
            LinkedProductsDispatcher.then(
                ({ default: dispatcher }) => dispatcher.clearCrossSellProducts(dispatch)
            );
        }
    }
}

export default new CartDispatcher();
