import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import LoginAccount from 'Route/LoginAccount';
import { isCrawler } from 'Util/Browser';

import { LOGIN_ACCOUNT } from './LoginAccount.config';

import './LoginAccount.style.scss';

/** @namespace ZnetPwa/Component/LoginAccount/Component/LoginAccountComponent */
export class LoginAccountComponent extends PureComponent {
    render() {
        if (isCrawler()) {
            return null;
        }

        return (
                <Popup
                  id={ LOGIN_ACCOUNT }
                  clickOutside={ false }
                  mix={ { block: 'LoginAccountPopup' } }
                >
                    <LoginAccount isPopup />
                </Popup>
        );
    }
}

export default LoginAccountComponent;
