import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { STATE_FORGOT_PASSWORD_SUCCESS } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountForgotPasswordContainer as SourceMyAccountForgotPasswordContainer
} from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.container';
import { showNotification } from 'Store/Notification/Notification.action';

/** @namespace ZnetPwa/Component/MyAccountForgotPassword/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace ZnetPwa/Component/MyAccountForgotPassword/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isCaptchaEnabled: state.ConfigReducer.captcha_is_enabled
});

/** @namespace ZnetPwa/Component/MyAccountForgotPassword/Container/MyAccountForgotPasswordContainer */
export class MyAccountForgotPasswordContainer extends SourceMyAccountForgotPasswordContainer {
    static propTypes = {
        ...super.propTypes,
        showNotification: PropTypes.func.isRequired,
        isCaptchaEnabled: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isCaptchaEnabled: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        setToken: this.setToken.bind(this)
    };

    state = {
        ...this.state,
        token: false
    };

    setToken(token) {
        this.setState({ token });
    }

    onForgotPasswordAttempt() {
        const { showNotification, setLoadingState, isCaptchaEnabled } = this.props;

        if (isCaptchaEnabled) {
            const {
                token
            } = this.state;

            if (!token) {
                showNotification('info', __('Missing captcha'));
                setLoadingState(false);
                return;
            }
        }

        setLoadingState(true);
    }

    onForgotPasswordSuccess(fields) {
        const {
            forgotPassword,
            setSignInState,
            setLoadingState,
            isCaptchaEnabled
        } = this.props;

        const {
            token
        } = this.state;

        if (!token && isCaptchaEnabled) {
            return;
        }

        const fieldsWithCaptcha = {
            ...fields,
            captcha_token: token
        };

        forgotPassword(fieldsWithCaptcha).then(
            /** @namespace ZnetPwa/Component/MyAccountForgotPassword/Container/forgotPassword/then */
            () => {
                setSignInState(STATE_FORGOT_PASSWORD_SUCCESS);
                setLoadingState(false);
            },
            /** @namespace ZnetPwa/Component/MyAccountForgotPassword/Container/forgotPassword/then */
            () => setLoadingState(false)
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountForgotPasswordContainer);
