/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import Event, {
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART
} from '../../util/Event';

// TODO split
export class RemoveItemPlugin {
    handleBulkRemove = (args, callback, instance) => {
        callback(...args);
        const { totals: { items } } = instance.props;
        const itemInfo = items.map((item) => ({
            item,
            quantity: item.qty
        }));

        this.handleRemoveState = { itemInfo };
    };

    handleRemoveItem = (args, callback, instance) => {
        callback(...args);
        const { item } = instance.props;
        const { qty: quantity } = item;

        this.handleRemoveState = {
            itemInfo: [{
                item,
                quantity
            }]
        };
    };

    handleRemoveAccessory = (args, callback) => {
        callback(...args);
        const [item] = args;
        const { qty: quantity } = item;

        this.handleRemoveState = {
            itemInfo: [{
                item,
                quantity
            }]
        };
    };

    removeSample = (args, callback, instance) => {
        callback(...args);
        const { sample: item } = instance.props;
        const { qty: quantity } = item;

        this.handleRemoveState = {
            itemInfo: [{
                item,
                quantity
            }]
        };
    };

    removeSamplePDP = (args, callback, instance) => {
        callback(...args);
        const {
            cartTotals: {
                items
            }
        } = instance.props;
        const { sample: item, sample: { sku } } = instance.state;
        const { quantity = 1 } = items?.filter((item) => item.sku === sku) || {};

        this.handleRemoveState = {
            itemInfo: [{
                item,
                quantity
            }]
        };
    };

    removeProductFromCart = (args, callback) => {
        const { itemInfo } = this.handleRemoveState;

        return callback(...args)
            .then(
                (result) => {
                    itemInfo.forEach(({ item, quantity }) => {
                        Event.dispatch(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, {
                            item,
                            quantity
                        });
                    });

                    return result;
                }
            );
    };
}

const {
    handleRemoveItem,
    removeSample,
    removeSamplePDP,
    removeProductFromCart,
    handleBulkRemove,
    handleRemoveAccessory
} = new RemoveItemPlugin();

export default {
    'Route/CartPage/Container': {
        'member-function': {
            handleBulkRemove
        }
    },
    'Component/CartItem/Container': {
        'member-function': {
            handleRemoveItem,
            handleRemoveCartItem: handleRemoveAccessory
        }
    },
    'Component/ProductCard/Container': {
        'member-function': {
            removeSample,
            handleRemoveAccessory
        }
    },
    'Component/ProductActions/Container': {
        'member-function': {
            removeSample: removeSamplePDP
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            removeProductFromCart
        }
    }
};
