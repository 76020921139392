import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddToCart from 'Component/AddToCart';
import TooltipComponent from 'Component/Tooltip';
import { ProductType } from 'Type/ProductList';

import { FIVE } from './GetSampleButton.config';

import './GetSampleButton.style';

/** @namespace ZnetPwa/Component/GetSampleButton/Component/GetSampleButtonComponent */
export class GetSampleButtonComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        productOptionsData: PropTypes.object.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        sample: PropTypes.object.isRequired,
        maxSamples: PropTypes.string.isRequired,
        groupedProductQuantity: PropTypes.objectOf(PropTypes.number).isRequired,
        isQuoteFormBtn: PropTypes.bool.isRequired,
        isSampleInCart: PropTypes.func.isRequired,
        isRemovingProducts: PropTypes.bool.isRequired,
        removeSample: PropTypes.func.isRequired,
        getSampleCount: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            product: ProductType.isRequired,
            quantity: PropTypes.number.isRequired,
            totalArea: PropTypes.number.isRequired,
            coverage_area_type: PropTypes.string.isRequired,
            estimatedTotal: PropTypes.number.isRequired
        }).isRequired,
        toggleDisableSampleButton: PropTypes.func.isRequired,
        isSamplesDisabled: PropTypes.bool.isRequired,
        toggleIsAddingSample: PropTypes.func.isRequired,
        isAddingSample: PropTypes.bool.isRequired,
        isConfigurable: PropTypes.bool.isRequired
    };

    renderGetSampleButton() {
        const {
            payload,
            maxSamples,
            isQuoteFormBtn,
            isRemovingProducts,
            isSampleInCart,
            removeSample,
            sample,
            sample: {
                price_range: {
                    maximum_price: {
                        final_price: {
                            value = FIVE
                        } = {}
                    } = {}
                } = {}
            },
            getSampleCount,
            groupedProductQuantity,
            productOptionsData,
            toggleDisableSampleButton,
            isSamplesDisabled,
            toggleIsAddingSample,
            isAddingSample
        } = this.props;

        const quantityToAdd = isSampleInCart() || getSampleCount() >= maxSamples ? 0 : 1;
        const title = `Get a sample for $${ value }`;

        if (isAddingSample) {
            return (
                <span>
                    { __('Adding...') }
                </span>
            );
        }

        if (!isSampleInCart()) {
            return (
                <AddToCart
                  payload={ payload }
                  disableHandler={ isSamplesDisabled }
                  product={ sample }
                  toggleIsAddingSample={ toggleIsAddingSample }
                  toggleDisableSampleButton={ toggleDisableSampleButton }
                  isQuoteFormBtn={ isQuoteFormBtn }
                  isSample
                  mix={ {
                      block: 'Button GetSampleButton',
                      elem: 'AddToCart',
                      mods: { isDisabled: isSamplesDisabled }
                  } }
                  quantity={ quantityToAdd }
                  groupedProductQuantity={ groupedProductQuantity }
                  productOptionsData={ productOptionsData }
                  title={ title }
                />
            );
        }

        if (sample && Object.keys(sample).length !== 0 && isSampleInCart()) {
            if (isRemovingProducts) {
                return (
                    <button
                      block="GetSampleButton"
                      elem="RemoveSample"
                      mods={ { isRemovingProducts } }
                      onClick={ removeSample }
                    >
                        { __('Removing...') }
                    </button>
                );
            }

            return (
                <button
                  block="GetSampleButton"
                  elem="RemoveSample"
                  onClick={ removeSample }
                >
                    { __('Remove Sample') }
                </button>
            );
        }

        return null;
    }

    render() {
        const {
            maxSamples,
            isSamplesDisabled,
            isSampleInCart,
            isConfigurable,
            getSampleCount
        } = this.props;

        if (
            isSamplesDisabled
            && !isSampleInCart()
            && getSampleCount() === parseInt(maxSamples, 10)
            && !isConfigurable
        ) {
            return (
                <div block="GetSampleButton">
                    <TooltipComponent
                      tooltipText={ __(`${ maxSamples } is the largest number of samples you can order.
                      Please remove some samples.`) }
                    >
                    { this.renderGetSampleButton() }
                    </TooltipComponent>
                </div>
            );
        }

        return this.renderGetSampleButton();
    }
}

export default GetSampleButtonComponent;
