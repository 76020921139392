import { connect } from 'react-redux';

import {
    DEFAULT_STATE,
    mapDispatchToProps,
    mapStateToProps,
    NavigationAbstractContainer as SourceNavigationAbstractContainer
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';

export {
    DEFAULT_STATE,
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace ZnetPwa/Component/NavigationAbstract/Container/NavigationAbstractContainer */
export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    handlePageScroll() {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAbstractContainer);
