import { Field } from 'Util/Query';

/** @namespace Drip/Query/Drip/Query/DripQuery */

export class DripQuery {
    _getDripConfigurationFields = () => ([
        'enabled',
        'drip_id',
        'magento_version',
        'drip_version'
    ]);

    getDripConfiguration = () => new Field('getDrip')
        .setAlias('drip')
        .addFieldList(this._getDripConfigurationFields());
}

export default new DripQuery();
