import {
    FIVE_MINUTES_IN_SECONDS,
    ONE_MONTH_IN_SECONDS,
    QueryDispatcher as SourceQueryDispatcher
} from 'SourceUtil/Request/QueryDispatcher';

export {
    ONE_MONTH_IN_SECONDS,
    FIVE_MINUTES_IN_SECONDS
};

/** @namespace ZnetPwa/Util/Request/QueryDispatcher/QueryDispatcher */
export class QueryDispatcher extends SourceQueryDispatcher {
    /**
     * Is triggered by BroadCast updated from ServiceWorker.
     * Should dispatch some action.
     * @param  {any} data Data received from fetch of GraphQL endpoint
     * @param  {Function} dispatch Store changing function from Redux (dispatches actions)
     * @return {void}
     * @memberof QueryDispatcher
     */
    onUpdate(data, dispatch, options) {
        /**
         * Used to fix https://sepoy.atlassian.net/browse/ZFR-1308
         * Related to https://sepoy.atlassian.net/browse/ZFR-1352
         */
        if ('urlResolver' in data) {
            if (data.urlResolver === window.location.pathname) {
                this.onSuccess(data, dispatch, options);
            }
        } else {
            this.onSuccess(data, dispatch, options);
        }
    }
}

export default QueryDispatcher;
