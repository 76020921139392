import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BRAND_WARNING_POPUP } from 'Component/BrandWarningPopUp/BrandWarningPopUp.config';
import { CART_POPUP } from 'Component/CartPopup/CartPopup.config';
import { CART_OVERLAY } from 'Component/Header/Header.config';
import { CONFIGURABLE } from 'Component/ProductSwatchPopup/ProductSwatchPopup.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { TotalsType } from 'Type/MiniCart';
import { toggleScroll } from 'Util/Browser';
import { isMobile } from 'Util/Mobile';
import { MultiManufacturerCartHelper } from 'Util/MultiManufacturerCart';

import AddToCartComponent from './AddToCart.component';
import { POPUP_OPEN_TIMEOUT } from './AddToCart.config';

/** @namespace ZnetPwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    totals: state.CartReducer.cartTotals,
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace ZnetPwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: () => dispatch(showPopup(BRAND_WARNING_POPUP)),
    showCartPopup: (id, payload = {}) => dispatch(showPopup(id, payload)),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName))
});

/** @namespace ZnetPwa/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    static propTypes = {
        ...super.propTypes,
        toggleAddToCart: PropTypes.func,
        isQuoteFormBtn: PropTypes.bool,
        setNavigationState: PropTypes.func.isRequired,
        totals: TotalsType.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        showPopup: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        toggleDisableSampleButton: PropTypes.func,
        toggleIsAddingSample: PropTypes.func
    };

    static defaultProps = {
        ...super.defaultProps,
        isQuoteFormBtn: false,
        toggleAddToCart: () => {
        },
        toggleDisableSampleButton: () => {
        },
        toggleIsAddingSample: () => {
        }
    };

    buttonClick() {
        const {
            toggleAddToCart,
            product: { type_id } = {},
            onProductValidationError,
            showPopup,
            disableHandler,
            toggleDisableSampleButton,
            toggleIsAddingSample
        } = this.props;

        if (disableHandler) {
            return;
        }

        if (!this.validateAddToCart()) {
            onProductValidationError(type_id);
            return;
        }

        if (!this.isValidCart()) {
            // Prevent popup being closed right after it is opened by PriceForm
            // eslint-disable-next-line no-magic-numbers
            setTimeout(() => showPopup(), POPUP_OPEN_TIMEOUT);
            return;
        }

        toggleAddToCart(true);

        this.setState({ isLoading: true }, () => {
            // Disable sample add to cart
            toggleDisableSampleButton(true);
            toggleIsAddingSample(true);
            this.addProductToCart();
        });
    }

    resetLoading() {
        const { toggleAddToCart } = this.props;
        this.setState({ isLoading: false });
        toggleAddToCart(false);
    }

    isValidCart() {
        const {
            product,
            product: {
                type_id,
                variants = []
            } = {},
            configurableVariantIndex,
            totals: {
                items = []
            } = {}
        } = this.props;

        const currentProduct = type_id === CONFIGURABLE ? variants[configurableVariantIndex] : product;
        return MultiManufacturerCartHelper.isValidCart([{ product: currentProduct }, ...items]);
    }

    afterAddToCart() {
        const {
            toggleAddToCart,
            activeOverlay,
            toggleDisableSampleButton,
            toggleIsAddingSample,
            setQuantityToDefault,
            showCartPopup,
            showOverlay,
            setNavigationState,
            payload,
            payload: {
                product: {
                    sku = ''
                } = {}
            },
            product: {
                sku: productSku = ''
            } = {}
        } = this.props;

        // Enable sample add to cart
        toggleDisableSampleButton(false);

        toggleIsAddingSample(false);

        setQuantityToDefault();

        // Don't show cart popup for samples
        if (!sku?.includes('sample') && !productSku?.includes('sample')) {
            showCartPopup(CART_POPUP, payload);
            setNavigationState({ name: CART_OVERLAY });

            if (isMobile.any()) {
                toggleScroll(false);
            }
        } else if (activeOverlay !== CART_OVERLAY) {
            showOverlay(CART_OVERLAY);

            if (isMobile.any()) {
                toggleScroll(false);
            }
        }

        this.removeProductFromWishlist();
        this.setState({ isLoading: false });
        toggleAddToCart(false);
    }

    render() {
        return (
            <AddToCartComponent
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
