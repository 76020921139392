import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import LoaderComponent from './Loader.component';

/** @namespace ZnetPwa/Component/Loader/Container/LoaderContainer */
export class LoaderContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isScroll: PropTypes.bool,
        loaderText: PropTypes.string
    };

    static defaultProps = {
        isScroll: false,
        loaderText: ''
    };

    loaderRefOriginal = createRef();

    componentDidUpdate() {
        const {
            isScroll
        } = this.props;

        if (isScroll) {
            this.loaderRefOriginal.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }

    containerProps = () => {
        const {
            isLoading,
            loaderText
        } = this.props;

        return {
            isLoading,
            loaderText,
            loaderRefOriginal: this.loaderRefOriginal
        };
    };

    render() {
        return (
            <LoaderComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default LoaderContainer;
