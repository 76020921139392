/* eslint-disable fp/no-let */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/**
 * @description Multi-manufacturer cart helper
 * @author      C. M. de Picciotto <cmdepicciotto@sepoy.io>
 * @note        This helper is based in brand utility, but it was implemented in a way that
 *              does not allow customizations and extensions easily. Because of that, and because this class
 *              is a temporary utility class, it was considered a better approach implement this new utility
 * @see         ZnetPwa/Util/Brand/isTheSameBrandAdded
 * {@link       https://sepoy.atlassian.net/browse/ZFR-1523}
 * @todo        Improve item parameter type in class methods
 * @todo        This is a temporary helper implemented in order to solve multi-manufacturer cart requirement.
 *              It encapsulates logic related to multi-manufacturer cart validations, but these validations
 *              must be removed in the final implementation of this multi-manufacturer cart feature, because
 *              every cart configuration should be allowed.
 *              There will be validations but they will be handled in the backend
 */
import { VIRTUAL } from 'Component/CartPopup/CartPopup.config';
import { CONFIGURABLE } from 'Component/ProductSwatchPopup/ProductSwatchPopup.config';
import {
    ACCESSORIES_ATTRIBUTE_SET_ID,
    RUG_ATTRIBUTE_SET_ID,
    SAMPLE_PRODUCT_SUFFIX_SKU
} from 'Util/MultiManufacturerCart/MultiManufacturerCart.config';

/**
 * Check if the item is a configurable item
 *
 * @param   {object}  item
 * @returns {boolean}
 * @private
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const _isConfigurableItem = (item) => item?.product?.type_id === CONFIGURABLE;

/**
 * Check if the item is a virtual item
 *
 * @param   {object}  item
 * @returns {boolean}
 * @private
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const _isVirtualItem = (item) => item?.product?.type_id === VIRTUAL;

/**
 * Check if the item is a rug item
 *
 * @param   {object}  item
 * @returns {boolean}
 * @private
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const _isRugItem = (item) => item?.product?.attribute_set_id === RUG_ATTRIBUTE_SET_ID;

/**
 * Check if the item is an accessory item
 *
 * @param   {object}  item
 * @returns {boolean}
 * @private
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const _isAccessoryItem = (item) => item?.product?.attribute_set_id === ACCESSORIES_ATTRIBUTE_SET_ID;

/**
 * Check if the item is a sample item
 *
 * @param   {object}  item
 * @returns {boolean}
 * @private
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const _isSampleItem = (item) => item?.product?.sku.includes(SAMPLE_PRODUCT_SUFFIX_SKU);

/**
 * Get the manufacturer from item
 *
 * @param   {object} item
 * @returns {string}
 * @private
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const _getManufacturerFromItem = (item) => {
    let product = item?.product;
    if (_isConfigurableItem(item)) {
        product = item?.product?.variants[0];
    }

    return product?.dynamicAttributes?.manufacturer;
};

/**
 * @namespace ZnetPwa/Util/MultiManufacturerCart/MultiManufacturerCartHelper/MultiManufacturerCartHelper
 */
export class MultiManufacturerCartHelper {
    /**
     * It is validated current cart taking into consideration
     * cart items, their manufacturers and product types
     *
     * @param   {object[]} items
     * @returns {boolean}
     * @note    It is allowed mixed manufacturer items for samples, accessories and rugs.
     *          Cart with other types of products (i.e.: flooring) should not be able to
     *          mix manufacturers
     * @see     ZnetPwa/Util/Brand/isTheSameBrandAdded
     * @link    https://sepoy.atlassian.net/browse/ZFR-1523
     */
    static isValidCart(items = []) {
        /**
         * @note The validation should occur if the cart already has items
         */
        if (items.length) {
            /**
             * @note It will be required to validate if the cart is a multi-manufacturer cart,
             *       and if it has other types of products than rugs, accessories and samples
             *       (i.e., flooring products)
             */
            let hasNotRugAccessorySampleItems = false;
            const manufacturers = new Set();

            for (const item of items) {
                if (_isVirtualItem(item)) {
                    continue;
                }

                manufacturers.add(_getManufacturerFromItem(item));

                if (
                    (!_isRugItem(item))
                    && (!_isAccessoryItem(item))
                    && (!_isSampleItem(item))
                ) {
                    hasNotRugAccessorySampleItems = true;
                }
            }
            const isMultiManufacturer = manufacturers.size > 1;

            /**
             * @note If cart does not have products from different manufacturers
             *       then it is already valid
             */
            if (!isMultiManufacturer) {
                return true;
            }

            /**
             * @note At this instance, cart is a multi-manufacturer cart
             * @note Multi-manufacturer carts can only have
             *       rug, accessories and samples
             */
            return !hasNotRugAccessorySampleItems;
        }

        return true;
    }
}
