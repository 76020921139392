/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Field from 'Component/Field';
import { FieldForm as SourceFieldFormComponent } from 'SourceComponent/FieldForm/FieldForm.component';

/** @namespace ZnetPwa/Component/FieldForm/Component/FieldFormComponent */
export class FieldFormComponent extends SourceFieldFormComponent {
    renderField = (fieldEntry, isBlur = false, isSubmit = false) => (
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        <Field isBlur={ isBlur } isSubmit={ isSubmit } { ...this.getDefaultValues(fieldEntry) } />
    );

    // isSubmit is used to trigger error on empty form submit
    // isBlur is used to trigger validation as the user types and so that error won't appear on empty form
    renderFields(isBlur = false, isSubmit = false) {
        return (
            <div
              block="FieldForm"
              elem="Fields"
            >
                { Object.entries(this.fieldMap).map((field) => this.renderField(field, isBlur, isSubmit)) }
            </div>
        );
    }
}

export default FieldFormComponent;
