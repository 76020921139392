/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_CATEGORIES } from './Categories.action';

export const emptyCategories = {
    items: [],
    count: 0
};

/** @namespace Blog/Store/Categories/Reducer/getInitialState */
export const getInitialState = () => ({
    categories: emptyCategories
});

/** @namespace Blog/Store/Categories/Reducer/CategoriesReducer */
export const CategoriesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_CATEGORIES:
        const { categories: { items, total_count: count }, isLoaded } = action;

        return {
            ...state,
            categories: {
                items,
                count
            },
            isLoaded
        };
    default:
        return state;
    }
};

export default CategoriesReducer;
