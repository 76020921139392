/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_GTM_CHECKOUT } from '../../../util/Event';
import ProductHelper from '../utils';
import { NOT_APPLICABLE } from '../utils/Product';
import BaseEvent from './BaseEvent.event';

export const CHECKOUT_EVENT_DELAY = 500;
export const SPAM_PROTECTION_DELAY = 1000;

/**
 * On checkout
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export class CheckoutEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_GTM_CHECKOUT, ({ totals, step }) => {
            this.handle(totals, step);
        });
    }

    /**
     * Handle
     */
    handler(totals, step) {
        const {
            is_virtual
        } = totals;

        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        this.pushEventData({
            ecommerce: {
                checkout: {
                    actionField: this.getActionFields(step),
                    products: this.getProducts(totals)
                }
            }
        });

        if (is_virtual && step === 2) {
            this.pushGA4EventData({
                ecommerce: {
                    items: this.getGA4Products(totals)
                }
            });
        }

        if (!is_virtual && step === 2) {
            this.pushGA4EventData({
                ecommerce: {
                    items: this.getGA4Products(totals)
                }
            });
        }
    }

    /**
     * Get action field for GTM data
     *
     * @param step
     * @return {{action: string, step: *}}
     */
    getActionFields(step) {
        return {
            step,
            action: 'checkout'
        };
    }

    /**
     * Get product detail
     *
     * @param paymentTotals
     *
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, category: string, brand: string, url: string}[]}
     */
    getProducts({ items }) {
        const products = Object.values(items).reduce((acc, item) => (
            [
                ...acc,
                {
                    ...ProductHelper.getItemData(item),
                    quantity: ProductHelper.getQuantity(item),
                    availability: true
                }
            ]), []);

        const groupedProducts = this.getGroupedProducts();
        Object.values(groupedProducts || {}).forEach(({ data }) => products.push(data));

        return products;
    }

    /**
     * Get GA4 product detail
     * Ref: https://sepoy.atlassian.net/browse/ZFR-1319
     * @param totals
     *
     * @return {{ quantity: number, price: number, item_name: string, item_variant: string, item_id: string, item_category: string, item_brand: string }[]}
     * @param cartData
     */
    getGA4Products({ items }) {
        const products = items.reduce((acc, item) => (
            [
                ...acc,
                {
                    ...ProductHelper.getGA4ItemData(item),
                    quantity: ProductHelper.getQuantity(item)
                }
            ]
        ), []);

        const groupedProducts = this.getGroupedProducts();
        Object.values(groupedProducts || {}).forEach(({ data }) => {
            const {
                price,
                name: item_name,
                variant: item_variant,
                id,
                category: item_category,
                brand: item_brand
            } = data;

            const ecommerceProduct = {
                price,
                item_name,
                item_variant,
                item_id: id,
                item_category,
                item_brand,
                id, // Google Ads additional variable. The same as item_id
                google_business_vertical: 'retail' // Google Ads additional constant

            };

            if (item_variant !== NOT_APPLICABLE) {
                ecommerceProduct.item_variant = item_variant;
            }

            return products.push(ecommerceProduct);
        });

        return products;
    }
}

export default CheckoutEvent;
