/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    CROSS_SELL,
    RELATED,
    UPSELL
} from 'SourceStore/LinkedProducts/LinkedProducts.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_LINKED_PRODUCTS, UPDATE_LINKED_PRODUCTS_LOADING_STATUS } from './LinkedProducts.action';
import { LINKED_PRODUCTS } from './LinkedProducts.dispatcher';

export {
    CROSS_SELL,
    RELATED,
    UPSELL
} from 'SourceStore/LinkedProducts/LinkedProducts.reducer';

/** @namespace ZnetPwa/Store/LinkedProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: false,
    linkedProducts: BrowserDatabase.getItem(LINKED_PRODUCTS) || {
        upsell: {},
        related: {},
        crosssell: {}
    }
});

/** @namespace ZnetPwa/Store/LinkedProducts/Reducer/LinkedProductsReducer */
export const LinkedProductsReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_LINKED_PRODUCTS:
        const {
            linkedProducts: {
                [UPSELL]: upsell,
                [RELATED]: related,
                [CROSS_SELL]: crosssell,
                updateCrossSell = false
            }
        } = action;

        const {
            linkedProducts: {
                [CROSS_SELL]: prevCrossSell
            }
        } = state;

        if (updateCrossSell) {
            return {
                ...state,
                linkedProducts: {
                    [UPSELL]: upsell,
                    [RELATED]: related,
                    [CROSS_SELL]: crosssell
                }
            };
        }

        return {
            ...state,
            linkedProducts: {
                [UPSELL]: upsell,
                [RELATED]: related,
                [CROSS_SELL]: {
                    ...prevCrossSell,
                    ...related,
                    items: Object.values({
                        ...prevCrossSell.items,
                        ...crosssell.items
                    })
                }
            }
        };
    case UPDATE_LINKED_PRODUCTS_LOADING_STATUS:
        const {
            loadingValue
        } = action;

        return {
            ...state,
            loadingValue
        };
    default:
        return state;
    }
};

export default LinkedProductsReducer;
