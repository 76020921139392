import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { TotalsType } from 'Type/MiniCart';
import { ProductType } from 'Type/ProductList';

import GetSampleButtonComponent from './GetSampleButton.component';

/** @namespace ZnetPwa/Component/GetSampleButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    maxSamples: state.ConfigReducer.max_samples_in_cart,
    cartTotals: state.CartReducer.cartTotals
});

/** @namespace ZnetPwa/Component/GetSampleButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace ZnetPwa/Component/GetSampleButton/Container/GetSampleButtonContainer */
export class GetSampleButtonContainer extends PureComponent {
    static propTypes = {
        isConfigurable: PropTypes.bool,
        showNotification: PropTypes.func.isRequired,
        isRemovingProducts: PropTypes.bool.isRequired,
        groupedProductQuantity: PropTypes.objectOf(PropTypes.number).isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        sample: PropTypes.object.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        productOptionsData: PropTypes.object.isRequired,
        cartTotals: TotalsType.isRequired,
        removeSample: PropTypes.func.isRequired,
        isQuoteFormBtn: PropTypes.bool,
        isSampleInCart: PropTypes.func.isRequired,
        toggleDisableSampleButton: PropTypes.func,
        isSamplesDisabled: PropTypes.bool,
        maxSamples: PropTypes.string.isRequired,
        payload: PropTypes.shape({
            product: ProductType.isRequired,
            quantity: PropTypes.number.isRequired,
            totalArea: PropTypes.number.isRequired,
            coverage_area_type: PropTypes.string.isRequired,
            estimatedTotal: PropTypes.number.isRequired
        })
    };

    static defaultProps = {
        payload: {},
        toggleDisableSampleButton: () => {},
        isQuoteFormBtn: false,
        isConfigurable: false,
        isSamplesDisabled: false
    };

    state = {
        isAddingSample: false
    };

    containerFunctions = {
        getSampleCount: this.getSampleCount.bind(this),
        toggleDisableSampleButton: this.toggleDisableSampleButton.bind(this),
        toggleIsAddingSample: this.toggleIsAddingSample.bind(this)
    };

    containerProps = () => {
        const {
            groupedProductQuantity,
            productOptionsData,
            cartTotals,
            sample,
            removeSample,
            isQuoteFormBtn,
            isConfigurable,
            isSampleInCart,
            isRemovingProducts,
            payload,
            isSamplesDisabled,
            maxSamples
        } = this.props;
        const {
            isAddingSample
        } = this.state;

        return {
            sample,
            maxSamples,
            groupedProductQuantity,
            productOptionsData,
            cartTotals,
            removeSample,
            isQuoteFormBtn,
            isConfigurable,
            isAddingSample,
            isSampleInCart,
            isRemovingProducts,
            payload,
            isSamplesDisabled: isSamplesDisabled || this.isSamplesDisabled()
        };
    };

    isSamplesDisabled() {
        const {
            sample,
            isSampleInCart,
            maxSamples
        } = this.props;

        return !sample
            || !(Object.keys(sample).length !== 0 && !isSampleInCart() && this.getSampleCount() < maxSamples);
    }

    getSampleCount() {
        const {
            cartTotals: {
                items = []
            } = {}
        } = this.props;
        const samplesInCart = items?.map((product) => product.sku);
        return samplesInCart?.filter((sku) => sku.endsWith('sample'))?.length;
    }

    toggleDisableSampleButton(isDisabled) {
        const {
            toggleDisableSampleButton
        } = this.props;

        toggleDisableSampleButton(isDisabled);
    }

    toggleIsAddingSample(isAdding) {
        const { isQuoteFormBtn, showNotification } = this.props;

        if (isQuoteFormBtn && isAdding) {
            showNotification('success', __('Adding sample'));
        }

        this.setState({ isAddingSample: isAdding });
    }

    render() {
        return (
            <GetSampleButtonComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetSampleButtonContainer);
