import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    LinkContainer as SourceLinkContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/Link/Link.container';
import { history } from 'Util/History';

import Link from './Link.component';

export {
    mapStateToProps
};

export const NoMatchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
);

/** @namespace ZnetPwa/Component/Link/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateNoMatch: (options) => NoMatchDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateNoMatch(dispatch, options)
    )
});

/** @namespace ZnetPwa/Component/Link/Container/LinkContainer */
export class LinkContainer extends SourceLinkContainer {
    static propTypes = {
        ...this.propTypes,
        showLoader: PropTypes.bool,
        updateNoMatch: PropTypes.func.isRequired,
        onClick: PropTypes.func
    };

    static defaultProps = {
        showLoader: false,
        onClick: () => {}
    };

    state = {
        isLoaderActive: false
    };

    containerFunctions = {
        handleLinkClick: this.handleLinkClick.bind(this),
        onClick: this.onClick.bind(this)
    };

    containerProps = () => {
        const {
            block,
            elem,
            mods,
            mix,
            // eslint-disable-next-line no-unused-vars
            baseLinkUrl, // remove this prop
            // eslint-disable-next-line no-unused-vars
            dispatch, // remove this prop
            showLoader,
            ...restProps
        } = this.props;
        const {
            isLoaderActive
        } = this.state;

        return {
            ...restProps,
            showLoader,
            isLoaderActive,
            to: this.getTo(),
            bemProps: {
                block,
                elem,
                mods,
                mix
            }
        };
    };

    // Resets no match state on redirect
    onClick(e) {
        const { updateNoMatch, onClick } = this.props;
        updateNoMatch({ noMatch: false });

        if (onClick) {
            onClick(e);
        }
    }

    handleLinkClick() {
        const {
            to,
            onClickBeforeLoader
        } = this.props;

        if (onClickBeforeLoader) {
            onClickBeforeLoader();
        }

        this.setState({ isLoaderActive: true });

        setTimeout(() => {
            const link = to;
            history.push(link);
            this.setState({ isLoaderActive: false });
        }, 0);
    }

    render() {
        return (
            <Link
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
