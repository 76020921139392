import Link from 'Component/Link';
import { MenuItem as SourceMenuItem } from 'SourceComponent/MenuItem/MenuItem.component';

/** @namespace ZnetPwa/Component/MenuItem/Component/MenuItemComponent */
export class MenuItemComponent extends SourceMenuItem {
    renderItemContent(item, itemMods = {}) {
        const {
            children,
            item_id,
            title,
            icon,
            url
        } = item;
        const { isBanner } = itemMods;
        const {
            activeMenuItemsStack,
            handleCategoryHover,
            handleLinkLeave,
            onItemClick
        } = this.props;
        const hasChild = !Object.keys(children).length;

        const isOpened = activeMenuItemsStack?.includes(item_id);
        const updatedItemMods = itemMods;
        updatedItemMods.isOpened = isOpened;
        return (
            <figure
              block="Menu"
              elem="ItemFigure"
              mods={ itemMods }
            >
                { this.renderItemContentImage(icon, itemMods) }
                <figcaption
                  block="Menu"
                  elem="ItemCaption"
                  mods={ itemMods }
                >
                    <Link
                      to={ url }
                      block="Menu"
                      elem="Link"
                      id={ item_id }
                      onMouseEnter={ handleCategoryHover }
                      onMouseLeave={ handleLinkLeave }
                      onClick={ onItemClick }
                    >
                        { this.renderItemContentTitle(isBanner, title) }
                    </Link>
                </figcaption>
                <div
                  block="Menu"
                  elem="ItemArrow"
                  mods={ { ...updatedItemMods, noArrow: hasChild } }
                />
            </figure>
        );
    }
}

export default MenuItemComponent;
