import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';

export const PrerenderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Prerender/Prerender.dispatcher'
);

/** @namespace ZnetPwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    urlRewriteType: state.UrlRewritesReducer.urlRewrite.type
});

/** @namespace ZnetPwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updatePrerender: (status) => PrerenderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updatePrerender(status, dispatch)
    )
});

/** @namespace ZnetPwa/Component/Router/Container/RouterContainer */
export class RouterContainer extends SourceRouterContainer {
    static propTypes = {
        ...this.props,
        urlRewriteType: PropTypes.string.isRequired,
        updatePrerender: PropTypes.func.isRequired
    };

    componentDidUpdate(prevProps) {
        const {
            isLoading,
            updateMeta,
            urlRewriteType,
            updatePrerender
        } = this.props;
        const { isLoading: prevIsLoading, urlRewriteType: prevUrlRewriteType } = prevProps;

        if (urlRewriteType !== prevUrlRewriteType) {
            updatePrerender({ [`pageRender${ urlRewriteType }`]: false });
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code
            } = this.props;

            updateMeta({
                default_title,
                title: meta_title || '',
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
