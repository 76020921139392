import PropTypes from 'prop-types';
import { FaHeart } from 'react-icons/fa';

import { ProductWishlistButton as SourceProductWishlistButton }
from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.style';

/** @namespace ZnetPwa/Component/ProductWishlistButton/Component/ProductWishlistButtonComponent */
export class ProductWishlistButtonComponent extends SourceProductWishlistButton {
    static propTypes = {
        ...this.propTypes,
        type: PropTypes.string
    };

    static defaultProps = {
        ...this.defaultProps,
        type: ''
    };

    renderSVG() {
        const {
            type,
            isInWishlist
        } = this.props;

        const className = isInWishlist ? 'Icon-InWishlist' : 'Icon';

        return (
            <div
              block="ProductWishlistButton"
              elem="Wrapper"
              mods={ { type } }
            >
                <FaHeart size="16px" className={ className } />
            </div>
        );
    }

    renderButton() {
        const {
            isInWishlist,
            isDisabled,
            mix
        } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button-Wishlist"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mods: { isHollow: !isInWishlist }, mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                { this.renderSVG() }
            </button>
        );
    }
}

export default ProductWishlistButtonComponent;
