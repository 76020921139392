/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/braintree-graphql
 * @link https://github.com/scandipwa/braintree-graphql
 */

import { datadogRum } from '@datadog/browser-rum';

import { Field } from 'Util/Query';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

/** @namespace GclidSpwa/Query/Gclid/Query/GclidQuery */
export class GclidQuery {
    async assignGclidToCart(gclid, cartId = null) {
        const state = getStore().getState();
        const {
            datadog_enabled = false
        } = state.ConfigReducer;
        const query = cartId ? new Field('assignGclidToCart')
            .addArgument('guestCartId', 'String', cartId)
            .addArgument('gclid', 'String!', gclid)
            .addFieldList(['success'])
            : new Field('assignGclidToCart')
                .addArgument('gclid', 'String!', gclid)
                .addFieldList(['success']);

        return fetchMutation(query).then(
            /** @namespace GclidSpwa/Query/Gclid/Query/fetchMutation/then */
            ({ assignGclidToCart: { success } }) => {
                if (!success) {
                    if (datadog_enabled) {
                        const message = 'Error assigning Gclid';
                        const errorMessage = new Error(message);
                        datadogRum.addError(errorMessage);
                    }
                }

                return success;
            },
            /** @namespace GclidSpwa/Query/Gclid/Query/fetchMutation/then */
            (err) => {
                if (datadog_enabled) {
                    const message = err?.[0]?.debugMessage
                        || err?.[0]?.message
                        || 'Error assigning Gclid';
                    const errorMessage = new Error(message);
                    datadogRum.addError(errorMessage);
                }
            }
        );
    }
}

export default new GclidQuery();
