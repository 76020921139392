/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import {
    CURBSIDE_DISCLAIMER, DELIVERY_DISCLAIMER, PICKUP_ACKNOWLEDGE
} from 'Component/CheckoutDeliveryOptions/CheckoutDeliveryOptions.config';
import FieldInput from 'Component/FieldInput';
import Link from 'Component/Link';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';

import './Field.override.style';

/** @namespace ZnetPwa/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceField {
    static propTypes = {
        ...this.propTypes,
        isBlur: PropTypes.bool.isRequired,
        updateValidationStatus: PropTypes.func.isRequired,
        isSearchSpringFacet: PropTypes.bool.isRequired,
        count: PropTypes.number.isRequired,
        additionalDescription: PropTypes.string.isRequired,
        handleShowPopup: PropTypes.func.isRequired
    };

    renderRadioButton() {
        const {
            id,
            label,
            onClick,
            additionalDescription,
            checked
        } = this.props;

        if (additionalDescription && additionalDescription !== '') {
            return (
                <label htmlFor={ id } block="Field" elem="Label" mods={ { isChecked: checked } }>
                    <FieldInput
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...this.props }
                      type="radio"
                      onChange={ onClick }
                    />
                    <div block="input-control" />
                    { label }
                    <br />
                    <div
                      block="Field"
                      elem="AdditionalDescription"
                    >
                        { additionalDescription }
                    </div>
                </label>
            );
        }

        return (
            <label htmlFor={ id }>
                <FieldInput
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...this.props }
                  type="radio"
                  onChange={ onClick }
                />
                <div block="input-control" />
                { label }
                { additionalDescription }
            </label>
        );
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange,
            isLoading = false
        } = this.props;

        return (
            <>
                <FieldInput
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...this.props }
                  type="number"
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value) }
                  onKeyDown={ onKeyEnterDown }
                  aria-label={ __('Value') }
                />
                <button
                  block="Field"
                  elem="AddBtn"
                  disabled={ +value === max || isLoading }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + 1) }
                  aria-label={ __('Add') }
                />
                <button
                  block="Field"
                  elem="SubtractBtn"
                  disabled={ +value === min || isLoading }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - 1) }
                  aria-label={ __('Subtract') }
                />
            </>
        );
    }

    renderCheckbox() {
        const {
            id,
            count,
            isSearchSpringFacet,
            onChangeCheckbox,
            label,
            additionalDescription,
            handleShowPopup
        } = this.props;

        if (id === PICKUP_ACKNOWLEDGE) {
            return (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label htmlFor={ id }>
                    <FieldInput
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...this.props }
                      type="checkbox"
                      onChange={ onChangeCheckbox }
                    />
                    <div block="input-control" />
                    <p
                      block="Field"
                      elem="CheckboxLabel"
                    >
                        { __('I acknowledge and agree to the following ') }
                        <button
                          block="CheckoutBilling"
                          elem="TACLink"
                          onClick={ handleShowPopup }
                        >
                            { __('terms and conditions') }
                        </button>
                        { __(` regarding liability: I understand that picking up orders requires a
                            proper truck, and all orders will be loaded exclusively by a forklift
                            (no cars, SUVs, or minivans will be loaded). I will not receive
                            assistance for hand-loading, and it is my responsibility to secure the
                            load myself. If I arrive unprepared, I understand that I may be turned away.`) }
                        <Link
                          block="Field"
                          elem="Link"
                          target="blank"
                          to="/pub/media/help/pick-up-image.jpg"
                        >
                            { __(' See what vehicles are accepted at pick-up.') }
                        </Link>
                    </p>
                </label>
            );
        }

        if (id === CURBSIDE_DISCLAIMER) {
            return (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label htmlFor={ id }>
                    <FieldInput
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...this.props }
                      type="checkbox"
                      onChange={ onChangeCheckbox }
                    />
                    <div block="input-control" />
                    <p
                      block="Field"
                      elem="CheckboxLabel"
                    >
                        { __(`I acknowledge that delivery will be curbside using a semi-truck with a trailer.
                            The truck will not drive or park on my driveway. If my job site is not accessible
                            by the delivery truck, I agree to meet the driver at the nearest accessible location.
                            I understand that the driver cannot assist with bringing the product indoors.`) }
                    </p>
                </label>
            );
        }

        if (id === DELIVERY_DISCLAIMER) {
            return (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label htmlFor={ id }>
                    <FieldInput
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...this.props }
                      type="checkbox"
                      onChange={ onChangeCheckbox }
                    />
                    <div block="input-control" />
                    <p
                      block="Field"
                      elem="CheckboxLabel"
                    >
                        { __(`I understand that delivery dates and time windows are estimates and cannot be guaranteed.
                            If I require a specific delivery timeframe, I must contact Znet Flooring after placing my
                            order. I acknowledge that carriers may charge storage fees of up to $150 per day, per
                            pallet, for delayed deliveries. Freight carriers do not issue refunds for missed delivery
                            windows. If my delivery date is time-sensitive, I am advised to consider a pick-up
                            instead.`) }
                    </p>
                </label>
            );
        }

        if (additionalDescription && additionalDescription !== '') {
            return (
                <label htmlFor={ id }>
                    <FieldInput
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...this.props }
                      type="checkbox"
                      onChange={ onChangeCheckbox }
                    />
                    <div block="input-control" />
                    { label }
                    <br />
                    <div
                      block="Field"
                      elem="AdditionalDescription"
                    >
                        { additionalDescription }
                    </div>
                </label>
            );
        }

        if (isSearchSpringFacet) {
            return (
                <label htmlFor={ id }>
                    { label }
                    <FieldInput
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...this.props }
                      type="checkbox"
                      onChange={ onChangeCheckbox }
                    />
                    <div block="input-control" />
                    <div
                      block="ProductCount"
                    >
                      { count }
                    </div>
                </label>
            );
        }

        return (
            <label htmlFor={ id }>
                <FieldInput
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...this.props }
                  type="checkbox"
                  onChange={ onChangeCheckbox }
                />
                <div block="input-control" />
                { label }
            </label>
        );
    }

    renderTypeText() {
        const { updateValidationStatus } = this.props;

        return (
            <FieldInput
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              // eslint-disable-next-line react/jsx-no-bind
              onBlur={ updateValidationStatus }
              type="text"
            />
        );
    }
}

export default FieldComponent;
